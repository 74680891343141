import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "../Stonepeople/stone-people.css";
import "./microlab.css";
const MicroLabenquiry = () => {
    const [mailtype, setType] = useState("");
    const [clientid, setClientId] = useState("");
    const mailtypefetchurl = useParams();
    const [Rotatecaptcha, setRotatecaptcha] = useState(false);
    // state for loader
    const [Loader, setLoader] = useState(false);
    const [Enquiry, setEnquiry] = useState({
        name: "",
        email: "",
        mobile: "",
        message: "",
        captcha: "",
        EmailRegex:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        NumRegex: /^[0-9]*$/,
    });
    function MicroLabenquiry_MailFormate() {
        return (
            '<html><head><meta charset="utf-8"><title>Microlab Animal Health Care | ENQUIRY FORM</title></head>' +
            '<body style="font-family: sans-serif;border: 1px solid #0000ff3b;border-radius: 5px;background-color: #8080800d">' +
            '<div style="width: 100%; margin: auto">' +
            '<div style="text-align: left; margin-bottom: 0px; padding: 10px 0px 0px 20px;">' +
            '<img style="width: 210px;padding-top:20px" src="https://globaltrendz.online/microlabs/images/logo.png"/>' +
            "</div>" +
            '<div style="padding: 20px">' +
            '<h2 style="text-align: left;font-size:14px;color: #000;">Dear Sir/Ma’am, </h2>' +
            "<p style='font-size:14px;color: #000;'>I hope this email finds you well. This email is to inform you that we have received an enquiry on the website.</p>" +
            "<p style='font-size:14px;color: #000;'>Here are the details of the client:</p>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%;display:flex"><b style="color:#000">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.name +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%;display:flex"><b style="color:#000">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.mobile +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:#000">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%;color: #000">' +
            Enquiry.email +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:#000">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.message +
            "</div>" +
            "</div>" +
            "<br>" +
            "<p style='font-size:14px;color: #000;'>We have acknowledged the client's enquiry. You can follow up with the client.</p>" +
            "<p style='font-size:14px;color: #000;'>We request you to kindly get in touch with the client and assist them with any further queries or concerns that they may have.</p>" +
            "<h2 style='text-align: left;font-size:14px;color: #000;margin-top:'30px'>Thank you</h2>" +
            "</div></div>" +
            "</body>" +
            "</html>"
        );
    }
    useEffect(() => {
        if (Rotatecaptcha == "") {
            setRotatecaptcha("iorotatevig");
        }
    }, [Rotatecaptcha]);
    function Genratecaptcha() {
        var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
            .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
        setcaptcha_number(reloadcapcha);
        setRotatecaptcha(!Rotatecaptcha);
    }
    const [captcha_number, setcaptcha_number] = useState("");
    useEffect(() => {
        setcaptcha_number(
            Math.floor(100000 + Math.random() * 90000000)
                .toString(36)
            [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
        );
    }, []);
    // notiflix message position
    useEffect(() => {
        Notiflix.Notify.Init({
            width: "280px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
            clickToClose: true,
            showOnlyTheLastOne: true,
            fontSize: "15px",
            timeout: 4000,
        });
    }, []);
    useEffect(() => {
        setType(mailtypefetchurl.mailtype);
        setClientId(mailtypefetchurl.clientid);
    }, []);
    return (
        <div className="container-fluid form-border p-sm-5 p-2 ptstopmain">
            <form class="row">
                <div class="col-md-6 mb-4 col-lg-6 col-12">
                    <label for="Name" class="form-label">
                        Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control stoneinput" id="Name" name="name" value={Enquiry.name} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                </div>
                <div class="col-md-6 mb-4 col-lg-6 col-12">
                    <label for="Name" class="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        class="form-control stoneinput"
                        id="email"
                        name="email"
                        value={Enquiry.email}
                        onChange={(e) =>
                            setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                        }
                    />
                </div>
                <div class="col-md-6 mb-4 col-lg-6 col-12">
                    <label for="contact_Person" class="form-label">
                        Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                        required
                        type="text"
                        class="form-control stoneinput"
                        id="MobileNumber"
                        name="mobile"
                        value={Enquiry.mobile}
                        onChange={(e) => {
                            if (e.target.value.length <= 10)
                                setEnquiry({
                                    ...Enquiry,
                                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                                });
                        }}
                    />
                </div>
                <div class="mb-4 col-md-6 mb-4 col-lg-6 col-12">
                    <label for="Name" class="form-label">
                        Message
                    </label>
                    <textarea
                        class="form-control"
                        style={{ borderRadius: '0.25rem' }}
                        id="Message"
                        name="message"
                        value={Enquiry.message}
                        onChange={(e) =>
                            setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                        }
                    ></textarea>
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                    <label for="carcapacity" class="form-label">
                        Captcha Code <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                        <p
                            className="me-2 cngcaptchsize d-flex justify-content-between captcha-copy-disable"
                            style={{
                                color: "#000000",
                                borderRadius: "0.25rem",
                                userSelect: "none",
                                border: '1px solid #ced4da',
                                padding: '10px 20px '
                            }}
                        >
                            {captcha_number}
                            <span
                                onClick={() => {
                                    setRotatecaptcha("iorotatevig");
                                    Genratecaptcha();
                                }}
                                className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
                            >
                                <IoSync className="aButtonvig rotate" />
                            </span>
                        </p>
                        <input type="captcha" className="form-control stoneinput" name="captcha" value={Enquiry.captcha} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                    </div>
                </div>
                <div class="col-md-4 col-lg-4" style={{ marginTop: '35px' }}>
                    <button
                        class="micro-lab-btn btn-hover-fill"
                        style={{ fontSize: "15px" }}
                        type="button"
                        onClick={() => {
                            if (Enquiry.name != "") {
                                if (
                                    Enquiry.email == "" ||
                                    Enquiry.EmailRegex.test(Enquiry.email)
                                ) {
                                    if (Enquiry.mobile != "") {
                                        if (Enquiry.mobile.length == 10) {
                                            if (
                                                Enquiry.captcha.toString() ==
                                                captcha_number.toString()
                                            ) {
                                                setLoader(true);
                                                const htmlMail = MicroLabenquiry_MailFormate();
                                                PostApiCall.postRequest(
                                                    {
                                                        mailtype: mailtype,
                                                        mail: htmlMail,
                                                        clientid: clientid,
                                                        name: Enquiry.name,
                                                        mobile: Enquiry.mobile,
                                                        email: Enquiry.email,
                                                        message: Enquiry.message
                                                    },
                                                    "NewEnquiryMailerApi"
                                                ).then((results2) =>
                                                    results2.json().then((obj2) => {
                                                        if (
                                                            results2.status == 200 ||
                                                            results2.status == 201
                                                        ) {
                                                            setLoader(false);
                                                            Notiflix.Notify.Success(
                                                                "Thank you, our team will contact you shortly!"
                                                            );
                                                            setTimeout(() => {
                                                                window.location.reload();
                                                            }, 3000);
                                                        }
                                                    })
                                                );
                                            } else {
                                                Notiflix.Notify.Failure("Please enter valid captcha");
                                            }
                                        }
                                        else {
                                            Notiflix.Notify.Failure(
                                                "Please enter valid mobile number"
                                            );
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("Please enter mobile number");
                                    }
                                } else {
                                    Notiflix.Notify.Failure("Please enter valid email");
                                }
                            } else {
                                Notiflix.Notify.Failure("Please enter name");
                            }
                        }}
                    >
                        {Loader == true ? (
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status" style={{ width: "1.4rem", height: "1.4rem" }}>
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            "Send Message"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};
export default MicroLabenquiry;
