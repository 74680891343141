import React, { useState, useEffect } from "react";

export default function GstInfo({
  setActiveTab,
  gstBasicInfo,
  handleUpdateGstBasicInfo,
  onFileChangeGst,
  selectedFileGst,
}) {
  // const { gstCertificateFile } = gstBasicInfo

  const handleCheckboxChange = (option) => {
    handleUpdateGstBasicInfo({
      ...gstBasicInfo,
      isCheckedYes: option === "yes",
      // isCheckedNo: option === "no",
    });
  };

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  const handleFieldChange = (field, value) => {
    handleUpdateGstBasicInfo({
      ...gstBasicInfo,
      [field]: value,
    });
  };

  return (
    <section>
      <div className="container-fluid mt-3">
        <div className="row">
          {/* Left side - GST Registration checkboxes */}
          <h2 className="text-center mb-4">GST Registration</h2>
          <div className="col-12 mb-3">
            <label>
              GST Registration
              <span className="text-danger">*</span>
            </label>
            <div className="d-flex">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input rounded-circle"
                  id="radioYes"
                  checked={gstBasicInfo.isCheckedYes}
                  onChange={() => handleCheckboxChange("yes")}
                />
                <label className="form-check-label" htmlFor="radioYes">
                  Yes
                </label>
              </div>

              <div className="form-check ms-3">
                <input
                  type="radio"
                  className="form-check-input rounded-circle"
                  id="radioNo"
                  checked={!gstBasicInfo.isCheckedYes}
                  onChange={() => handleCheckboxChange("no")}
                />
                <label className="form-check-label" htmlFor="radioNo">
                  No
                </label>
              </div>
            </div>
          </div>

          {/* Right side - GST Registration Number */}
          {gstBasicInfo.isCheckedYes && (
            <div className="col-md-6">
              <label>GST Registration Number</label>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control poel-vendor-input"
                  id="gstRegistrationNumber"
                  placeholder="Enter GST Registration Number"
                  value={gstBasicInfo.gstRegistrationNumber}
                  onChange={(e) =>
                    handleFieldChange("gstRegistrationNumber", e.target.value)
                  }
                />
              </div>
            </div>
          )}

          {/* Legal Name (as per GST) - Visible only when GST Registration checkbox is checked */}
          {gstBasicInfo.isCheckedYes && (
            <div className="col-md-6">
              <label>Legal Name (as per GST)</label>
              {/* </div>

            <div className="col-md-6"> */}
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control poel-vendor-input"
                  id="legalName"
                  placeholder="Enter Legal Name"
                  value={gstBasicInfo.legalName}
                  onChange={(e) =>
                    handleFieldChange("legalName", e.target.value)
                  }
                />
              </div>
            </div>
          )}

          {/* GST Certificate - Visible only when GST Registration checkbox is checked */}
          {gstBasicInfo.isCheckedYes && (
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="gstCertificateFile" className="form-label">
                  GST Certificate
                </label>
                <div className="input-group">
                  <input
                    type="file"
                    className="form-control h-100 d-block poel-vendor-input poel-file"
                    placeholder="Choose File*"
                    aria-label="File"
                    id="file"
                    name="File"
                    // onChange={(e) => handleFieldChange("gstCertificateFile", e.target.files[0])}
                    onChange={onFileChangeGst}
                  />
                </div>
                <p>File name : {selectedFileGst[0]?.name}</p>
              </div>
            </div>
          )}

          {/* Tax Payer Type - Visible only when GST Registration checkbox is checked */}
          {gstBasicInfo.isCheckedYes && (
            // <div className="row mt-3">
            <div className="col-md-6">
              <label>Tax Payer Type</label>
              {/* </div>

              <div className="col-md-6"> */}
              <div className="mb-3">
                <select
                  className="form-select bg-white select reason-option inputselect poel-vendor-input"
                  id="taxPayerType"
                  value={gstBasicInfo.taxPayerType}
                  onChange={(e) =>
                    handleFieldChange("taxPayerType", e.target.value)
                  }
                >
                  <option value="">Please select</option>
                  <option value="normal">Normal Taxpayer</option>
                  <option value="composition">Composition Dealer</option>
                  {/* <option value="importer">Importer</option> */}

                  <option value="exporter">Exporter</option>
                  <option value="sezunit">SEZ Unit</option>
                  <option value="sezdeveloper">SEZ Developer</option>
                </select>
              </div>
            </div>
            // </div>
          )}

        
        </div>
        <div className="row mt-4">
          <div className="col-12 mt-4 d-flex justify-content-center">
            <button
              className="enquirybtnvendor text-white fs-6"
              type="button"
              onClick={(e) => {
                setActiveTab("basicInfo");
              }}
            >
              Back
            </button>
            <button
              className="enquirybtnvendor text-white fs-6 ms-3"
              onClick={(e) => {
                setActiveTab("tdsDetails");
              }}
              type="button"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
