import React, { useState } from "react";
import DeclarationPdf from "../../../assets/PoelVendorPdf/Declaration under Section 206.pdf";
import VendorsTcs from "../../../assets/PoelVendorPdf/Letter to Vendors on TCS.pdf";
import CustomerDeclaration from '../../../assets/PoelVendorPdf/CustomerConfirmation.docx'
import { IoDocumentText } from "react-icons/io5";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

export default function TdsDetails({
  setActiveTab,
  handleUpdateTdsBasicInfo,
  tdsBasicInfo,
  handleSubmit,
  onFileChange,
  selectedFile,
  onFileChangeCustomerTDS,
  selectedFileCustomerTDS,
}) {
  const handleFieldChange = (field, value) => {
    handleUpdateTdsBasicInfo({
      ...tdsBasicInfo,
      [field]: value,
    });
  };

  // window.scrollTo({
  //   top: 0,
  //   behavior: "smooth",
  // });

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <h2 className="text-center mb-4">TDS Details</h2>
        <div className="col-12 col-md-6 mb-3">
          <label>
            PAN <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control poel-vendor-input"
            id="PanNumber"
            name="panno"
            maxLength={10}
            value={tdsBasicInfo.panNo}
            onChange={(e) => handleFieldChange("panNo", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label>PAN Type</label> <span className="text-danger">*</span>
          <select
            class="form-select bg-white select reason-option inputselect poel-vendor-input"
            aria-label="Default select example"
            value={tdsBasicInfo.panType}
            onChange={(e) => {
              handleFieldChange("panType", e.target.value);
            }}
          >
            <option value="">Please select</option>
            <option selected value="individual">
              Individual
            </option>
            <option value="company">Company</option>
            <option value="firm">Firm</option>
            <option value="huf">HUF</option>
          </select>
        </div>
        {/* Pan card file */}
        <div className="col-12 col-md-6 mb-3">
          <label>PAN Card</label> <span className="text-danger">*</span>
          <div>
            <div className="input-group mb-2">
              <input
                type="file"
                className="form-control p-3 d-block poel-vendor-input poel-file tds-file-input-heigth"
                placeholder="Choose File*"
                aria-label="File"
                id="PanCard"
                name="pancard"
                onChange={onFileChange}
              />
            </div>
            <p className="mb-0">File name : {selectedFile[0]?.name}</p>
          </div>
        </div>

        <div className="col-12 col-md-6 mb-3">
          <label>
            TAN Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control poel-customer-input"
            id="PanNumber"
            name="panno"
            maxLength={10}
            value={tdsBasicInfo.tanNo}
            onChange={(e) => handleFieldChange("tanNo", e.target.value)}
          />
        </div>

          {/* newly checkbox */}
          <div className="col-lg-6 mb-3">
          <label>Applicability of Section 194Q</label> <span className="text-danger">*</span>
          <div className="d-md-flex">

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value={tdsBasicInfo.sectionQ}
                id="defaultCheck1"
                checked={tdsBasicInfo.sectionQ == "Yes"
                }
                onChange={(e) => handleFieldChange("sectionQ", "Yes")}
              />
              <label class="form-check-label" for="defaultCheck1">
                Yes
              </label>
            </div>
            <div class="form-check ms-md-3">
              <input
                class="form-check-input"
                type="checkbox"
                value={tdsBasicInfo.sectionQ}
                id="defaultCheck1"
                checked={tdsBasicInfo.sectionQ == "No"}
                onChange={() => handleFieldChange("sectionQ", "No")}
              />

              <label class="form-check-label" for="defaultCheck1">
                No
              </label>
            </div>
          </div>
          {tdsBasicInfo.sectionQ == "No" && <p className="text-danger">Turnover is Less than 10 Crores in Previous Financial Year*</p>}
          {tdsBasicInfo.sectionQ == "Yes" && <p className="text-danger">Turnover is more than 10 Crores in Previous Financial Year</p>}
        </div>

        {/* <div className="col-lg-6 d-block d-md-flex">
          <div className="mb-3">
            <label>Customer TDS-TCS Confirmation <IoDocumentText />
            </label><br />
            <a className="btn me-3 tdf-pdf-box" href={CustomerDeclaration} target="blank">
              <p>Click here to Download</p>
            </a>
          </div>
        </div> */}

        <div className="col-12 col-md-6 d-block d-md-flex mb-3">
          <div className="mb-3 me-md-3">
            <label>Declaration under sec 206 <BsFillFileEarmarkPdfFill /> </label><br />
            <a className="btn me-3 tdf-pdf-box w-100" href={DeclarationPdf} target="blank">
              <p>Click here to Download</p>
            </a>
          </div>
          <div>
            <label>Customer TDS-TCS Declaration <IoDocumentText /></label><br />
            <a className="btn tdf-pdf-box w-100" href={CustomerDeclaration} target="blank">
              <p>Click here to Download</p>
            </a><br/>
            <label className="mt-2">Download / Update / Sign / Seal / Upload</label>
          </div>
        </div>

        {/* For mandetory TDS-TCS confirmation file upload */}
        <div className="col-12 col-md-6 mb-3">
          <label>Signed copy of Customer TDS-TCS Declaration</label> <span className="text-danger">*</span>
          <div>
            <div className="input-group mb-2">
              <input
                type="file"
                className="form-control p-3 d-block poel-vendor-input poel-file tds-file-input-heigth"
                placeholder="Choose File*"
                aria-label="File"
                id="customertds"
                name="customertds"
                onChange={onFileChangeCustomerTDS}
              />
            </div>
            <p className="mb-0">File name : {selectedFileCustomerTDS[0]?.name}</p>
          </div>
        </div>


      
      </div>

      {/* <label>Click here to Download</label><br/>
          <a className="btn tdf-pdf-box" href={VendorsTcs} target="blank">
            <p>Letter to Vendors on TCS</p>
          </a> */}
      <div className="row">
        <div className="col-12 d-flex justify-content-center mt-4">
          <button
            className="enquirybtnvendor text-white fs-6"
            onClick={(e) => {
              setActiveTab("gst");
            }}
            type="button"
          >
            Back
          </button>
          <button
            className="enquirybtnvendor text-white fs-6 ms-3"
            type="button"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
