import React from "react";
import { Redirect } from "react-router-dom";
// ********** arunjems enquiry forms
import ArunjemsEnquiry from "../components/Client/ArunJems/ArunjemsEnquiry";

// ********** Avtravels Form
import Avtravels from "../components/Client/Avtravels/Avtravels";
import BangkokTrip from "../components/Client/BangkokTrip/BangkokTrip";

// ********** Cleanroomkart Enquiry from
import CleanroomEnquiry from "../components/Client/Cleanroomkart/CleanroomEnquiry";

// ********** Deltech Form
import Deltech from "../components/Client/Deltech/Contact";
import Quote from "../components/Client/Deltech/Quote";

// ********** Emci forms
import EmciEnquiry from "../components/Client/EMCI/EmciEnquiry";
import EmciService from "../components/Client/EMCI/EmciService";

// ********** EmiratesEnquiry Form
import EmiratesEnquiry from "../components/Client/Emirates/EmiratesEnquiry";

// ********** Goodrich forms
import GoodrichEnquiry from "../components/Client/Goodrich/GoodrichEnquiry";

// ********** Kaizen
import Kaizen from "../components/Client/Kaizen/KaizenEnquiry";

// ********** Manichetinad
import Manichetinad from "../components/Client/ManiChetinadHotel/ManiChetinadEnquiry";

// ********** Manzil Form
import ManzilEnquiry from "../components/Client/Manzil/ManzilEnquiry";
import ManzilEnroll from "../components/Client/Manzil/ManzilEnroll";

// ********** MidtownEnquiry Form
import MidtownEnquiry from "../components/Client/Midtown/MidtownEnquiry";

// ********** pvr enquiry from
import PvrEnquiry from "../components/Client/Pvr/PvrEnquiry";

// ********** Rayyanhotel Form
import Rayyanhotel from "../components/Client/Rayyanhotel/Rayyanhotel";

// ********** Redrock
import Redrock from "../components/Client/Redrock-marble/Redrock-marbleEnquiry";

// ********** roobyfood carrer form
import RoobyFoodCareer from "../components/Client/RoobyFood/RoobyFoodCareer";

// ********** Shivnadi Form
import Shivnadi from "../components/Client/Shivnadi/Shivnadi";
import ShivnadiAppointment from "../components/Client/Shivnadi/ShivnadiAppoinment";

// ********** VigneshCabs Form
import VigneshCabs from "../components/Client/VigneshCabs/VigneshCabs";

// ********** Zero gravity enquiry form
import EnquiryZerogravity from "../components/Client/Zerogravity/EnquiryZerogravity";

// ********** Cng enquiry form
import EnquiryCng from "../components/Client/Cng/CngEnquiry";

// ********** Sunbeam enquiry form
import EnquirySunbeam from "../components/Client/Sunbeam/SunbeamEnquiry";

// ********** Pitstop enquiry form
import Pitstop from "../components/Client/Pitstop/Enquiry";

// ********** Stone People enquiry form ********************
import Stonepeople from "../components/Client/Stonepeople/Contact";

// ********** Templeton enquiry form ***********************
import Templeton from "../components/Client/Templeton/TempletonEnquiry";

// ********** Micro Lab enquiry form ***********************
import MicrolabCareer from "../components/Client/Microlab/MicroLabCareer";
import Microlab from "../components/Client/Microlab/MicroLabEnquiry";

// ********** Templeton enquiry form ***********************
import Otp from "../components/Client/OTP/OtpEnquiry";

//  ********** Abhirami Enquiry form ***********************
import Abhirami from "../components/Client/Abhirami/AbhiramiTravels";

//  ********** GN Crop Enquiry form ***********************
import Gncrop from "../components/Client/Gncorp/GncorpContact";

// *********** ATC Enquiry Form *********
import ATC from "../components/Client/ATC/AtcContact";

// *********** Xtreame Enquiry Form *********
import Xtreme from "../components/Client/Xtreme/Xtreme";

//  ********** Blessing Enquiry form ***********************
import Blessing from "../components/Client/Blessing/BlessingEnquiry";

// ****** Megamart Home Page Popup Enquiry Form **********
import MegaMart from "../components/Client/MegaMart/MegaMart";

// ****** Global Trendz Enquiry Form **********
import GlobalTrendzUs from "../components/Client/Globaltrendz/GlobaltrendzUs";
import GlobaltrendzIndia from "../components/Client/Globaltrendz/GlobaltrendzIndia";

// ****** Olympic Enquiry Form **********
import Olympic from "../components/Client/Olympic/Olympic";

// ***** Pushtak Foundation ENquiry Form *****
import PushtakFoundationEnquiry from "../components/Client/PustakFoundation/Enquiry";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Jobapplications from
import Jobapplications from "../pages/Dashboard/Jobapplications";
import Enquiries from "../pages/Enquiries/Enquiries";

// ****** Passage Enquiry Form **********
import Passage from "../components/Client/Passage/PassageEnquiry";
import PassageBooking from "../components/Client/Passage/PassageBooking";

// ****** On D Roof Enquiry Form **********
import OnDRoof from "../components/Client/OnDRoof/OnDRoofEnquiry";

//  ************ AJM Enquiry ********************
import AjmEnquiry from "../components/Client/Ajm/AjmEnquiry";

//  ****************** Adore Art ****************
import AdoreArtEnquiry from "../components/Client/AdorArt/AdorArt";

// NewBlog Section Add
import AddBlog from "../pages/Blogs/AddBlog";
import BlogList from "../pages/Blogs/BlogList";

// Goodrich 5 statics website
import RingJointEnquiry from "../components/Client/GoodrichStatic/RingJointEnquiry";
import FlangeInsulatingGasket from "../components/Client/GoodrichStatic/FlangeInsulatingGasket";
import KammprofileGasket from "../components/Client/GoodrichStatic/KammprofileGasket";
import MonolithicGasket from "../components/Client/GoodrichStatic/MonolithicInsulatingGasket";
import SpiralWoundGasket from "../components/Client/GoodrichStatic/SpiralWoundGasket";

// POEL vendor registration
import CommonRouting from "../components/Client/POEL/CommonRouting";

// POEL customer registration
import CommonRoutingCustomer from "../components/Client/POELCustomer/CommonRoutingCustomer";

// POEL MSME
import Msme from "../components/Client/POELMsme/Msme";

//  Sresan
import Sresan from "../components/Client/Sresan/Sresan";

// Meditek
import MeditekEnquiry from "../components/Client/Meditek/MeditekEnquiry";

// Visitor Registration Mailer for Jivesh Times of India Presents South India Property Expo in Abu Dhabi
import VisitorRegistrationForm from "../components/Client/JiveshTimesPresent/VisitorRegistration";

//mailer
import Mailer from "../pages/Mailer/MailerForm";
import MailerBooking from "../pages/Mailer/MailerFormBooking";

// ******* Goodrich Distributor Form ***********
// import GoodrichDistributor from "../components/Client/Goodrich/GoodrichDistributor";

// ************* OuskiOt Form **************
import OuskiotAppointment from "../components/Client/OuskiOt/OuskiotAppointment";
// import OuskiotContact from "../components/Client/OuskiOt/OuskiotContact";

// ********** Flosil static form *********
import FlosilEnquiry from "../components/Client/FlosilStatic/FlosilContact";
import FlosilInfraEnquiry from "../components/Client/FlosilStatic/FlosilInfra";

// ********** Bokariya Contact Form *********
import BokariaContact from "../components/Client/Bokaria/BokariaContact";

// ********* Canaanpress Contact Form **********
import Canaanpress from "../components/Client/Canaanpress/CanaanpressEnquiry";


// ********* Superkirana Contact Form **********
import Superkirana from "../components/Client/Superkirana/SuperkiranaContact";
import SuperkiranaContactHome from "../components/Client/Superkirana/SuperkiranaContactHome";
import TummyTemptation from "../components/TummyTemptation/TummyTemptation";


// ================02/04/2021========================
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  //profile
  { path: "/profile", component: UserProfile },
  // Tables
  //  User Management
  //================14-04-2021=====================
  { path: "/enquiries", component: Enquiries },

  //Blog New Section
  { path: "/blogs", component: AddBlog },
  { path: "/blog-list", component: BlogList },

  // job applicatons path
  { path: "/Jobapplications", component: Jobapplications },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // Authentication Inner
  { path: "/pages-login", component: Login }, //Login1
  // Authentication Inner
  { path: "/mailer/:clientid/:mailtype", component: Mailer }, //Login1
  // Authentication Inner
  { path: "/bookingmailer/:clientid/:mailtype", component: MailerBooking }, //Login1

  // Emci enquiry form
  { path: "/EmciEnquiry/:clientid/:mailtype", component: EmciEnquiry }, //EMCI ENquiry
  { path: "/EmciService/:clientid/:mailtype", component: EmciService }, //EMCI service

  // Goodrich job enquiry form
  { path: "/Goodrich/:clientid/:mailtype", component: GoodrichEnquiry }, //Goodrich ENquiry

  // Arunjems enquiry form
  { path: "/ArunJems/:clientid/:mailtype", component: ArunjemsEnquiry }, //Arungemss ENquiry

  // Pvr enquiry form
  { path: "/PvrEnquiry/:clientid/:mailtype", component: PvrEnquiry }, //pvr ENquiry

  // rooby food carrer form
  { path: "/RoobyFoodCareer/:clientid/:mailtype", component: RoobyFoodCareer }, //rubyfood career

  // Emirates Enquiry form
  { path: "/EmiratesEnquiry/:clientid/:mailtype", component: EmiratesEnquiry }, //Emirates Enquiry

  // Midtown Enquiry Form
  { path: "/MidtownEnquiry/:clientid/:mailtype", component: MidtownEnquiry }, //Midtown Enquiry

  // Rayyanhotel Enquiry Form
  { path: "/Rayyanhotel/:clientid/:mailtype", component: Rayyanhotel }, //Rayyanhotel Enquiry

  // VigneshCabs Enquiry Form
  { path: "/VigneshCabs/:clientid/:mailtype", component: VigneshCabs }, //VigneshCabs Enquiry

  // Shivnadi Enquiry Form
  { path: "/ShivnadiEnquiry/:clientid/:mailtype", component: Shivnadi }, //Shivnadi Enquiry
  {
    path: "/ShivnadiAppointment/:clientid/:mailtype",
    component: ShivnadiAppointment,
  }, //Shivnadi Appoinment

  // Manzil Enquiry form
  { path: "/ManzilEnquiry/:clientid/:mailtype", component: ManzilEnquiry }, //Manzil Enquiry
  { path: "/ManzilEnroll/:clientid/:mailtype", component: ManzilEnroll }, //Manzil Enquiry

  // Bangkok trip Enquiry form
  { path: "/mail/:clientid/:mailtype", component: BangkokTrip }, //Bangkok Enquiry

  // Avtravels Enquiry Form
  { path: "/mailav/:clientid/:mailtype", component: Avtravels }, //Avtravels Enquiry

  // Deltech Enquiry Form
  { path: "/maildel/:clientid/:mailtype", component: Deltech }, //Deltech Enquiry
  { path: "/maildelq/:clientid/:mailtype", component: Quote }, //Deltech Quote

  // Cleanroom Enquiry Form
  { path: "/cleankart/:clientid/:mailtype", component: CleanroomEnquiry },

  // Zero Gravity
  { path: "/zerogravity/:clientid/:mailtype", component: EnquiryZerogravity },

  // Manichetinad enquiry form
  { path: "/manichetinad/:clientid/:mailtype", component: Manichetinad },

  // Redrock enquiry form
  { path: "/redrock/:clientid/:mailtype", component: Redrock },

  // Kaizen enquiry form
  { path: "/kaizen/:clientid/:mailtype", component: Kaizen },

  // Cng enquiry form
  { path: "/Cng/:clientid/:mailtype", component: EnquiryCng },

  // Sunbeam enquiry form
  { path: "/sunbeam/:clientid/:mailtype", component: EnquirySunbeam },

  // Pitstop enquiry form
  { path: "/pitstop/:clientid/:mailtype", component: Pitstop },

  // Stonepeople enquiry form
  { path: "/stonepeople/:clientid/:mailtype", component: Stonepeople },

  // TempletonEnquiry enquiry form
  { path: "/templeton/:clientid/:mailtype", component: Templeton },

  // Microlab enquiry form
  { path: "/microlab/:clientid/:mailtype", component: Microlab },
  { path: "/microlab-career/:clientid/:mailtype", component: MicrolabCareer },

  // OtpEnquiry form
  { path: "/otp/:clientid/:mailtype", component: Otp },

  // AbhiramiEnquiry form
  { path: "/abhirami/:clientid/:mailtype", component: Abhirami },

  // Gn crop form
  { path: "/gncropenquiry/:clientid/:mailtype", component: Gncrop },

  // ATC form
  { path: "/atcenquiry/:clientid/:mailtype", component: ATC },

  //Xtreme Form
  { path: "/xtreme/:clientid/:mailtype", component: Xtreme },

  //MegaMart
  { path: "/megamart/:clientid/:mailtype", component: MegaMart },

  // Global Trendz
  { path: "/enquirymailer/:clientid/:mailtype", component: GlobalTrendzUs },
  {
    path: "/enquirygtmailer/:clientid/:mailtype",
    component: GlobaltrendzIndia,
  },

  // BlessingEnquiry form
  { path: "/blessingenquiry/:clientid/:mailtype", component: Blessing },

  // Olympic Form
  { path: "/olympicmailer/:clientid/:mailtype", component: Olympic },

  // Pushtak Foundation Enquiry Form
  {
    path: "/enquirymail/:clientid/:mailtype",
    component: PushtakFoundationEnquiry,
  },

  // Passage Enquiry Form
  { path: "/passage/:clientid/:mailtype", component: Passage },
  { path: "/passagebooking/:clientid/:mailtype", component: PassageBooking },

  // OnDRoof Enquiry Form
  { path: "/ondroof/:clientid/:mailtype", component: OnDRoof },

  // AJM Enquiry Form
  { path: "/ajm/:clientid/:mailtype", component: AjmEnquiry },

  // Goodrich Statics Forms
  { path: "/ringjoint/:clientid/:mailtype", component: RingJointEnquiry },
  { path: "/flange/:clientid/:mailtype", component: FlangeInsulatingGasket },
  { path: "/kammprofile/:clientid/:mailtype", component: KammprofileGasket },
  { path: "/monolithic/:clientid/:mailtype", component: MonolithicGasket },
  { path: "/spiral/:clientid/:mailtype", component: SpiralWoundGasket },

  // POEL forms
  { path: "/vendorregistration/:clientid/:mailtype", component: CommonRouting },
  {
    path: "/customerregistration/:clientid/:mailtype",
    component: CommonRoutingCustomer,
  },
  { path: "/msme/:clientid/:mailtype", component: Msme },

  // Adore Art Forms
  { path: "/adoreart/:clientid/:mailtype", component: AdoreArtEnquiry },

  // Sresan Forms
  { path: "/sresan/:clientid/:mailtype", component: Sresan },

  // Meditek Forms
  { path: "/meditek/:clientid/:mailtype", component: MeditekEnquiry },

  // Visitor Registration Mailer for Jivesh Times of India Presents South India Property Expo in Abu Dhabi
  { path: "/visitorRegistration/:clientid/:mailtype", component: VisitorRegistrationForm },

  // *********** Goodrich Distributor Form ***********************
  // { path: "/goodrichdistributor/:clientid/:mailtype", component: GoodrichDistributor },

  // *********** OuskiOt Form ***********************
  { path: "/ouskiOtappointment/:clientid/:mailtype", component: OuskiotAppointment },
  // { path: "/Ouskiotenquiry/:clientid/:mailtype", component: OuskiotContact },

  // *********** Flosil conatct form ****************
  { path: "/flosilenquiry/:clientid/:mailtype", component: FlosilEnquiry },

  // *********** Flosil Infra conatct form ****************
  { path: "/flosilinfra/:clientid/:mailtype", component: FlosilInfraEnquiry },

  // ********** BokariaContact Contact form *********
  { path: "/bokariyaenquiry/:clientid/:mailtype", component: BokariaContact },

  // Canaanpress enquiry form
  { path: "/canaanpressenquiry/:clientid/:mailtype", component: Canaanpress },

  // Super Kirana
  { path: "/superkiranaenquiry/:clientid/:mailtype", component: Superkirana },
  { path: "/superkiranahenquiry/:clientid/:mailtype", component: SuperkiranaContactHome },

  // Tummy Temptation
  { path: "/tummytemptaion/:clientid/:mailtype", component: TummyTemptation },
];
export { authProtectedRoutes, publicRoutes };
