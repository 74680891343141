import Notiflix from "notiflix";
import PostApiCall from "../../../Api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Emcistyle.css";
import { IoSync } from "react-icons/io5";
function Service() {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  const [EmciService, setEmciService] = useState({
    name: "",
    AddressService: "",
    TelephoneService: "",
    mobile: "",
    email: "",
    ModelService: "",
    SerialService: "",
    captcha: "",
    PurchaseDateService: "",
    NatureFaultService: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function EmciService_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>EMCI PLUS</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: 100%; margin: auto">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 20%" src="https://emciplus.com/assets/img/demos/it-services/logo.png"/>' +
      "</div>" +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h2 style="text-align: left;font-size: 14px;color: black;">Dear EMCI PLUS,</h2>' +
      "<p style='font-size: 14px;color: black;'>Received the following Service Enquiry</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color: black">Full Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color: black">Address</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.AddressService +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Telephone Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.TelephoneService +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Model Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.ModelService +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Serial Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.SerialService +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Date of Purchase</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.PurchaseDateService +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Nature of Fault</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      EmciService.NatureFaultService +
      "</div>" +
      "</div>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  return (
    <div className="container form-border my-5">
      <form className="row g-3 needs-validation">
        <div className="col-md-12">
          <label for="full-name" className="form-label">
            Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="full-name"
            value={EmciService.name}
            name="name"
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="address" className="form-label">
            Address <span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control emciborderinput"
            rows="3"
            id="address"
            value={EmciService.AddressService}
            name="AddressService"
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
          ></textarea>
        </div>
        <div className="col-md-6">
          <label for="telephone_number" className="form-label">
            Telephone Number
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="telephone_number"
            value={EmciService.TelephoneService}
            name="TelephoneService"
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="mobile_number" className="form-label">
            Mobile Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="mobilenumber"
            value={EmciService.mobile}
            name="mobile"
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEmciService({
                  ...EmciService,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-md-12">
          <label for="email" className="form-label">
            Enter your email <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            className="form-control emciborderinput"
            id="email"
            value={EmciService.email}
            name="email"
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="model-number" className="form-label">
            Model Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="modelnumber"
            value={EmciService.ModelService}
            name="ModelService"
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="serial-number" className="form-label">
            Serial Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="serialnumber"
            value={EmciService.SerialService}
            name="SerialService"
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-6 dateemci">
          <label for="date-of-purchase" className="form-label">
            Date of Purchase
          </label>
          <input
            type="date"
            className="form-control emciborderinput"
            id="date_of_purchase"
            value={EmciService.PurchaseDateService}
            name="PurchaseDateService"
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="nature-of-fault" className="form-label">
            Nature of Fault <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="nature_of_fault"
            value={EmciService.NatureFaultService}
            onChange={(e) =>
              setEmciService({
                ...EmciService,
                [e.target.name]: e.target.value,
              })
            }
            name="NatureFaultService"
          />
        </div>

        <div className="col-md-12">
          <label for="nature-of-fault" className="form-label">
            Enter Captcha Code <span className="text-danger">*</span>
          </label>
          <div className="row">
            <div className="col-6">
              <p
                className="mb-2 d-flex catchborderemci captcha-copy-disable justify-content-between"
              >
                {captcha_number}
                <span
                  onClick={() => {
                    setRotatecaptcha("iorotatevig");
                    Genratecaptcha();
                  }}
                  className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
                >
                  <IoSync className="aButtonvig rotate" />
                </span>
              </p>
            </div>
            <div className="col-6">
              <input
                type="captcha"
                className="form-control emciborderinput"
                placeholder="Enter Captcha"
                name="captcha"
                value={EmciService.captcha}
                onChange={(e) =>
                  setEmciService({ ...EmciService, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="col-md-6 my-lg-5 my-md-5">
          <p>
            Fields marked with <span>*</span> are mandatory
          </p>
        </div>
        <div className="col-md-6 my-md-5 my-0">
          <button
            className="btn w-100"
            style={{ background: "#0b813b", color: "white", fontSize: "18px" }}
            type="button"
            onClick={() => {
              if (EmciService.name != "") {
                if (EmciService.AddressService != "") {
                  if (EmciService.mobile != "") {
                    if (EmciService.mobile.length == 10) {
                      if (EmciService.email != "") {
                        if (EmciService.EmailRegex.test(EmciService.email)) {
                          if (EmciService.ModelService != "") {
                            if (EmciService.SerialService != "") {
                              if (EmciService.NatureFaultService != "") {
                                if (
                                  EmciService.captcha.toString() == captcha_number.toString()
                                ) {
                                  const htmlMail = EmciService_formate();
                                  Notiflix.Loading.Arrows("Please wait...");
                                  PostApiCall.postRequest(
                                    {
                                      // mailtype: mailType,
                                      // mail: htmlMail,
                                      // clientId: clientId,
                                      mailtype: mailtype,
                                      mail: htmlMail,
                                      clientid: clientid,
                                      name: EmciService.name,
                                      mobile: EmciService.mobile,
                                      email: EmciService.email,
                                    },
                                    "NewEnquiryMailerApi"
                                  ).then((results2) =>
                                    results2.json().then((obj2) => {
                                      if (
                                        results2.status == 200 ||
                                        results2.status == 201
                                      ) {
                                        Notiflix.Notify.Success(
                                          "Thank you, our team will contact you shortly!"
                                        );
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 3000);
                                        // window.location.reload();
                                      } else Notiflix.Loading.Remove();
                                    })
                                  );
                                }
                                else {
                                  Notiflix.Notify.Failure("Please enter valid captcha");
                                }

                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter Nature of Fault"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure(
                                "Please enter serial number"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter model number"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter valid email");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter email");
                      }
                    } else {
                      Notiflix.Notify.Failure(
                        "Please enter valid mobile number"
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter mobile number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter address");
                }
              } else {
                Notiflix.Notify.Failure("Please enter full name");
              }
            }}
          >
            SEND REQUEST
          </button>
        </div>
      </form>
    </div>
  );
}
export default Service;
