import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import './arun.css';
import { IoSync } from "react-icons/io5";
const ArunjemsEnquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  const [Enquiry, setEnquiry] = useState({
    name: "",
    Gender: "",
    MaritalStatus: "",
    BirthTime: "",
    DateofBirth: "",
    PlaceOfBirth: "",
    mobile: "",
    email: "",
    Qution: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function ArunjemsEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Arun Gemss | ENQUIRY</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%; margin: auto">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 30%" src="http://globaltrendz.online/arungems/assets/img/logoarungem.webp"/>' +
      "</div>" +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h3 style="text-align: left;font-size: 14px">Dear K.R. Arul Saravanan,</h3>' +
      "<p style='font-size: 14px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color: black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color: black">Gender</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.Gender +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Marital Status</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.MaritalStatus +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Date of Birth</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.DateofBirth +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Birth Time</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.BirthTime +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Place Of Birth</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.PlaceOfBirth +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">ph. Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  return (
    <div className="container form-border my-3">
      <form className="row py-3 needs-validation">
        <div className="col-xl-4 col-md-6">
          <div className="mb-3">
            <label for="Name" className="form-label">
              Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={Enquiry.name}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mb-3">
            <label for="gender" className="form-label">
              Gender <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="gender"
              name="Gender"
              value={Enquiry.Gender}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mb-3">
            <label for="materialstatus" className="form-label">
              Marital Status
            </label>
            <input
              type="text"
              className="form-control"
              id="MaritalStatus"
              name="MaritalStatus"
              value={Enquiry.MaritalStatus}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mb-3 dateemci">
            <label for="birthdate" className="form-label">
              Date of Birth <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control dobarun"
              id="DateofBirth"
              name="DateofBirth"
              value={Enquiry.DateofBirth}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
              style={{ border: "1px solid #ced4da" }}
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mb-3">
            <label for="Birthtime" className="form-label">
              Birth Time <span className="text-danger">*</span>
            </label>
            <input
              type="time"
              className="form-control"
              id="Birthtime"
              name="BirthTime"
              value={Enquiry.BirthTime}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mb-3">
            <label for="birthplace" className="form-label">
              Place Of Birth <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="PlaceOfBirth"
              name="PlaceOfBirth"
              value={Enquiry.PlaceOfBirth}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mb-3">
            <label for="mobile_number" className="form-label">
              Mobile Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="mobile_number"
              name="mobile"
              value={Enquiry.mobile}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setEnquiry({
                    ...Enquiry,
                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                  });
              }}
            />
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="mb-3">
            <label for="email_id" className="form-label">
              Email Id
            </label>
            <input
              type="email"
              className="form-control"
              id="email_id"
              name="email"
              value={Enquiry.email}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="mb-3">
            <label for="web_address" className="form-label">
              Ask Your Question
            </label>
            <textarea
              type="Website_address"
              className="form-control"
              id="Qution"
              name="Qution"
              rows="3"
              value={Enquiry.Qution}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-12">
          <div className="mb-3">
            <label for="email_id" className="form-label">
              Enter Captcha Code <span className="text-danger">*</span>
            </label>
            <div className="row">
              <div className="col-6">
                <p
                  className="mb-2 d-flex aruncatchaborder captcha-copy-disable justify-content-between"
                >
                  {captcha_number}
                  <span
                    onClick={() => {
                      setRotatecaptcha("iorotatevig");
                      Genratecaptcha();
                    }}
                    className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
                  >
                    <IoSync className="aButtonvig rotate" />
                  </span>
                </p>
              </div>
              <div className="col-6">
                <input
                  type="captcha"
                  className="form-control emciborderinput"
                  placeholder="Enter Captcha"
                  name="captcha"
                  value={Enquiry.captcha}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center pe-0 pe-lg-0">
          <div className="col-lg-4 col-12 pe-0 pe-lg-0">
            <div className="mb-3">
              <button
                className="btn btn-warning btn-block py-2 text-light mx-auto"
                type="button"
                onClick={() => {
                  if (Enquiry.name != "") {
                    if (
                      Enquiry.email == "" ||
                      Enquiry.EmailRegex.test(Enquiry.email)
                    ) {
                      if (Enquiry.Gender != "") {
                        if (Enquiry.DateofBirth != "") {
                          if (Enquiry.BirthTime != "") {
                            if (Enquiry.PlaceOfBirth != "") {
                              if (Enquiry.mobile != "") {
                                if (Enquiry.mobile.length == 10) {
                                  if (
                                    Enquiry.captcha.toString() == captcha_number.toString()
                                  ) {
                                    const htmlMail = ArunjemsEnquiry_formate();
                                    Notiflix.Loading.Arrows("Please wait...");
                                    PostApiCall.postRequest(
                                      {
                                        mailtype: mailtype,
                                        mail: htmlMail,
                                        clientid: clientid,
                                        name: Enquiry.name,
                                        mobile: Enquiry.mobile,
                                        email: Enquiry.email
                                      },
                                      "NewEnquiryMailerApi"
                                    ).then((results2) =>
                                      results2.json().then((obj2) => {
                                        if (
                                          results2.status == 200 ||
                                          results2.status == 201
                                        ) {
                                          Notiflix.Notify.Success(
                                            "Thank you, our team will contact you shortly!"
                                          );
                                          setTimeout(() => {
                                            window.location.reload();
                                          }, 3000);
                                          // window.location.reload();
                                        }
                                        else (
                                          Notiflix.Loading.Remove()
                                        )
                                      })
                                    );
                                  }
                                  else {
                                    Notiflix.Notify.Failure("Please enter valid captcha");
                                  }
                                }
                                else {
                                  Notiflix.Notify.Failure(
                                    "Please enter valid mobile number"
                                  );
                                }
                              }
                              else {
                                Notiflix.Notify.Failure(
                                  "Please enter mobile number"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure(
                                "Please enter place of birth"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure("Please enter birth time");
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter date of birth");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter gender");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter valid email");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter name");
                  }
                }}
              >
                Submit Enquiry
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ArunjemsEnquiry;
