import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./Midtownstyle.css";
import { IoSync } from "react-icons/io5";
const MidtownEnquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [Service, setService] = useState("");
  const [serviceData, setServiceData] = useState([
    { Key: 1, Value: "-----Select Service-----" },
    { Key: 2, Value: "Dental Implant" },
    { Key: 3, Value: "Teeth cleaning" },
    { Key: 4, Value: "Kids dentistry" },
    { Key: 5, Value: "Dental extraction" },
    { Key: 6, Value: "Crowns & bridges" },
    { Key: 7, Value: "Conscious sedation" },
    { Key: 8, Value: "Gum surgery" },
    { Key: 9, Value: "Braces and aligners" },
  ]);
  const [Appointment, setAppointment] = useState({
    name: "",
    mobile: "",
    email: "",
    date: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function Midtownappionment_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Midtown Appointment</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: auto; margin: auto;border: 1px solid grey;background-color: #8080800d;padding: 0px 0px 15px 0px;">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 30%" src="http://midtowndentalstudiochennai.com/assets/images/logo.png"/>' +
      "</div>" +
      '<div style="padding: 20px">' +
      '<h4 style="text-align: left">Hello Sir/Madam, </h4>' +
      '<h4 style="font-weight:100">We got an appointment request. Patient needs ' +
      " " +
      Service +
      " " +
      " services. Further information is given below :</h4>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Appointment.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Appointment.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Appointment.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Appointment Date</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Appointment.date +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Service</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Service +
      "</div>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  return (
    <div className="container-fluid form-border px-5 pt-5">
      <form
        id="appointment-form"
        class="appointmentmidtown panel panel-body marbot40 panel-grey"
      >
        <h3>Fix an appointment</h3>
        <div className="row clearfix">
          <div className="col-md-6 mt-3">
            <label className="labelfont">
              Name <span className="text-danger">*</span>
            </label>
            <input
              name="name"
              type="text"
              className="textbox1 midcaptchainputfocus"
              id="Name"
              value={Appointment.name}
              onChange={(e) =>
                setAppointment({
                  ...Appointment,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-6 mt-3">
            <label className="labelfont" for="phone-number">
              Mobile Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="textbox1 midcaptchainputfocus"
              id="MobileNumber"
              name="mobile"
              value={Appointment.mobile}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setAppointment({
                    ...Appointment,
                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                  });
              }}
            />
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label className="labelfont" for="emailid">
                Email
              </label>
              <input
                type="email"
                className="textbox1 midcaptchainputfocus"
                id="email_id"
                name="email"
                value={Appointment.email}
                onChange={(e) =>
                  setAppointment({
                    ...Appointment,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <div className="clearfix marbot20">
                <label className="labelfont" for="date-time">
                  Select Date
                </label>
                <div id="datetimepicker" className="input-group date form_datetime">
                  <input
                    type="date"
                    className="midcaptchainputfocus"
                    id="date-time"
                    name="date"
                    value={Appointment.date}
                    style={{ paddingRight: "10px" }}
                    onChange={(e) =>
                      setAppointment({
                        ...Appointment,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mt-3">
            <div className="form-group">
              <label className="labelfont" for="message">
                Service
              </label>
              <select
                name="consulting"
                className="select reason-option minimal midtownservicefocos "
                value={Service}
                onChange={(e) => {
                  setService(e.target.value);
                }}
              >
                {serviceData.map((data, index) => {
                  return (
                    <option key={data.Key} value={data.Value}>
                      {data.Value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6 col-12 mt-3">
            <div className="form-group mb-0">
              <label className="labelfont" for="message">
                Enter Captcha Code <span className="text-danger">*</span>
              </label>
            </div>
            <div className="d-flex">
              <p className="px-2 mr-2 textbox1 midtowncaptcha mb-2 me-2 border-dotted captchvaluesize captcha-copy-disable d-flex justify-content-between">
                {captcha_number}
                <span
                  onClick={() => {
                    setRotatecaptcha("iorotatevig");
                    Genratecaptcha();
                  }}
                  className={
                    Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""
                  }
                >
                  <IoSync className="aButtonvig rotate" />
                </span>
              </p>
              <input
                type="captcha"
                className="form-control textbox1 midtowncaptcha midcaptchainput midcaptchainputfocus"
                name="captcha"
                value={Appointment.captcha}
                onChange={(e) =>
                  setAppointment({
                    ...Appointment,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-type1-reverse"
              onClick={() => {
                if (Appointment.name != "") {
                  if (
                    Appointment.email == "" ||
                    Appointment.EmailRegex.test(Appointment.email)
                  ) {
                    if (Appointment.mobile != "") {
                      if (Appointment.mobile.length == 10) {
                        if (
                          Appointment.captcha.toString() ==
                          captcha_number.toString()
                        ) {
                          const htmlMail = Midtownappionment_formate();
                          Notiflix.Loading.Arrows("Please wait...");
                          PostApiCall.postRequest(
                            {
                              // mailtype: mailType,
                              mail: htmlMail,
                              // clientId: clientId,
                              mailtype: mailtype,
                              clientid: clientid,
                              name: Appointment.name,
                              mobile: Appointment.mobile,
                              email: Appointment.email,
                            },
                            "NewEnquiryMailerApi"
                          ).then((results2) =>
                            results2.json().then((obj2) => {
                              if (
                                results2.status == 200 ||
                                results2.status == 201
                              ) {
                                Notiflix.Notify.Success(
                                  "Thank you, our team will contact you shortly!"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 3000);
                                // window.location.reload();
                              } else Notiflix.Loading.Remove();
                            })
                          );
                        } else {
                          Notiflix.Notify.Failure("Please enter valid captcha");
                        }
                      } else {
                        Notiflix.Notify.Failure(
                          "Please enter valid mobile number"
                        );
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter mobile number");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid email");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter name");
                }
              }}
            >
              {" "}
              {Loader == true ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                "Book Appointment"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default MidtownEnquiry;
