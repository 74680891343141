import Notiflix from "notiflix";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IoDocumentText } from "react-icons/io5";
import PostApiCall from "../../../Api";
import MsmeDeclaration from "../../../assets/msme/MSMEDeclarationFormat.docx";
import Msmelogo from "../../../assets/msme/msmelogo.png";

const MsmeSingleForm = (onHandleUpdate) => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const [msmeDetails, setMsmeDetails] = useState({
    isMSMERegistered: false,
    msmeType: "",
    udyamRegistrationNumber: "",
  });

  const handleFieldChange = (field, value) => {
    handleUpdateMsmeInfo({
      ...msmeDetails,
      [field]: value,
    });
  };

  const handleUpdateMsmeInfo = (updatedDetails) => {
    setMsmeDetails(updatedDetails);
  };

  // Handler for radio button change
  const handleCheckboxChange = (option) => {
    setMsmeDetails({
      ...msmeDetails,
      isMSMERegistered: option === "yes",
    });
  };

  const [Enquiry, setEnquiry] = useState({
    companyname: "",
    panno: "",
    selectedCustomertypes: [],
    registernumber: "",
    udyamRegistrationNumber: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  // MSME
  const [selectedFileMsme, setSelectedFileMsme] = useState([]);
  const [fileBase64StringMsme, setfileBase64StringMsme] = useState("");
  const [msmeFileName, setMsmeFileName] = useState("");

  // MSME declaration
  const [selectedFileMsmeDeclaration, setSelectedFileMsmeDeclaration] =
    useState([]);
  const [fileBase64StringMsmeDeclaration, setfileBase64StringMsmeDeclaration] =
    useState("");
  const [msmedeclarationFileName, setmsmedeclarationFileName] = useState("");

  // Msme
  const onFileChangeMsme = (e) => {
    setSelectedFileMsme(e.target.files);
  };

  // MSME
  const encodeFileBase64Msme = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringMsme(attachment);
        setMsmeFileName(file.name);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64Msme(selectedFileMsme[0]);

  // MSME Form Declaration
  const onFileChangeMsmeDeclaration = (e) => {
    setSelectedFileMsmeDeclaration(e.target.files);
  };

  // MSME
  const encodeFileBase64MsmeDeclaration = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringMsmeDeclaration(attachment);
        setmsmedeclarationFileName(file.name); // Set the file name
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64MsmeDeclaration(selectedFileMsmeDeclaration[0]);

  // regex based matching
  const isPanValid = (pan) => {
    const panUpperCase = pan.toUpperCase();
    return panRegex.test(panUpperCase);
  };

  // function for clientid
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);

  const validateMsmeForm = () => {
    if (Enquiry.companyname == "") {
      Notiflix.Notify.Failure("Company name is required");
      return;
    }
    if (Enquiry.panno === "") {
      Notiflix.Notify.Failure("PAN is required");
      return;
    } else if (!isPanValid(Enquiry.panno)) {
      Notiflix.Notify.Failure("PAN is invalid");
      return;
    }
    // when user is registered with MSME
    if (msmeDetails.isMSMERegistered == true) {
      if (msmeDetails.msmeType == "") {
        Notiflix.Notify.Failure("MSME type is required");
        return;
      } else if (msmeDetails.udyamRegistrationNumber == "") {
        Notiflix.Notify.Failure("Udyam registration number is required");
        return;
      } else if (fileBase64StringMsme === "") {
        Notiflix.Notify.Failure("Udyam certificate is required ");
        return;
      }
    }
    if (fileBase64StringMsmeDeclaration == "") {
      Notiflix.Notify.Failure("MSME declaration upload file is required");
      return;
    }
    onMsmeSubmit(); // final submission
  };

  const onMsmeSubmit = () => {
    Notiflix.Loading.Dots("Please Wait...");
    let htmlMail;
    if (msmeDetails.isMSMERegistered == true) {
      htmlMail = Msme_formate_Yes();
    } else {
      htmlMail = Msme_formate();
    }

    PostApiCall.postRequest(
      {
        mailtype: "from Poel MSME Registration",
        mail: htmlMail,
        clientid: clientid,
        msmeAttachment: fileBase64StringMsme,
        msmeFileName: msmeFileName,
        msmeDeclaration: fileBase64StringMsmeDeclaration,
        msmedeclarationFileName: msmedeclarationFileName,
      },
      "PoelVendorMailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(
            "Thank you, our team will contact you shortly!"
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
    );
  };

  function Msme_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>POEL MSME Registration</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width:100%;height:100%;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left;margin: 0px 10px 0px 20px;">' +
      '<img style="width: 200px" src="http://poel.in/images/logo.png"/>' +
      "</div>" +
      '<div style="padding:5px 20px">' +
      '<h4 style="text-align: left;font-size:15px;color:#000">Dear Sir/Mam, </h4>' +
      '<h4 style="font-size:15px;color:#000">Greetings! You have received an registration for MSME via the website. The details are as follows:</h4>' +
      '<table style="border-collapse: collapse;width:100%">' +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Company Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      Enquiry.companyname +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>PAN Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      Enquiry.panno +
      "</td>" +
      "</tr>" +
      "</table>" +
      "<p style='font-size:15px;color:#000'><b>Thank You</b></p>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }

  // if Msme register option is Yes
  function Msme_formate_Yes() {
    return (
      '<html><head><meta charset="utf-8"><title>POEL MSME Registration</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width:100%;height:100%;margin: auto;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left;margin: 0px 10px 0px 20px;">' +
      '<img style="width: 200px" src="http://poel.in/images/logo.png"/>' +
      "</div>" +
      '<div style="padding:5px 20px">' +
      '<h4 style="text-align: left;font-size:15px;color:#000">Dear Sir/Mam, </h4>' +
      '<h4 style="font-size:15px;color:#000">Greetings! You have received an registration for MSME via the website. The details are as follows:</h4>' +
      '<table style="border-collapse: collapse;width:100%">' +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Company Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      Enquiry.companyname +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>PAN Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      Enquiry.panno +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>MSME Type</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      msmeDetails.msmeType +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Udyam Registration Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      msmeDetails.udyamRegistrationNumber +
      "</td>" +
      "</tr>" +
      "</table>" +
      "<p style='font-size:15px;color:#000'><b>Thank You</b></p>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }

  return (
    <>
      <div
        className="container-fluid form-border passagebg"
        style={{
          // height: "100vh",
          overflow: "hidden",
        }}
      >
        <form className="row">
          <div className="col-12">
            <img src={Msmelogo} />
          </div>
          <h2 className="text-center my-4">MSME Registration</h2>
          <div className="col-12 col-md-6 mb-3">
            <label htmlFor="msmeType" className="form-label">
              Company Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control poel-vendor-input"
              id="companyname"
              name="companyname"
              value={Enquiry.companyname}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>

          <div className="col-12 col-md-6 mb-3">
            <label htmlFor="msmeType" className="form-label">
              PAN <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control poel-vendor-input"
              id="panno"
              name="panno"
              value={Enquiry.panno}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>

          <div className="col-12 mb-4">
            <label>
              Are you registered under MSME(Udyam Registration){" "}
              <span className="text-danger">*</span>
            </label>
            <div className="d-flex">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input rounded-circle"
                  id="msmeRadioYes"
                  checked={msmeDetails.isMSMERegistered}
                  onChange={() => handleCheckboxChange("yes")}
                />
                <label className="form-check-label" htmlFor="msmeRadioYes">
                  Yes
                </label>
              </div>

              <div className="form-check ms-3">
                <input
                  type="radio"
                  className="form-check-input rounded-circle"
                  id="msmeRadioNo"
                  checked={!msmeDetails.isMSMERegistered}
                  onChange={() => handleCheckboxChange("no")}
                />
                <label className="form-check-label" htmlFor="msmeRadioNo">
                  No
                </label>
              </div>
            </div>
          </div>

          {msmeDetails.isMSMERegistered && (
            <div className="col-md-4 col-12">
              <div className="mb-3">
                <label htmlFor="msmeType" className="form-label">
                  MSME Type
                </label>
                <select
                  className="form-select bg-white select reason-option inputselect poel-vendor-input"
                  id="msmeType"
                  value={msmeDetails.msmeType}
                  onChange={(e) =>
                    handleFieldChange("msmeType", e.target.value)
                  }
                >
                  <option value="">Please select</option>
                  <option value="Manufacturer - Micro">
                    Manufacturer - Micro
                  </option>
                  <option value="Manufacturer - Small">
                    Manufacturer - Small
                  </option>
                  <option value="Manufacturer - Medium">
                    Manufacturer - Medium{" "}
                  </option>
                  <option value="Service Provider - Micro">
                    Service Provider - Micro{" "}
                  </option>
                  <option value="Service Provider - Small">
                    Service Provider - Small{" "}
                  </option>
                  <option value="Service Provider - Medium">
                    Service Provider - Medium{" "}
                  </option>
                </select>
              </div>
            </div>
          )}

          {msmeDetails.isMSMERegistered && (
            <div className="col-md-4 col-12">
              <label>Udyam Registration Number</label>{" "}
              <span className="text-danger">*</span>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control poel-vendor-input"
                  id="udyamRegistrationNumber"
                  placeholder="Enter Udyam Registration Number"
                  value={msmeDetails.udyamRegistrationNumber}
                  onChange={(e) =>
                    handleFieldChange("udyamRegistrationNumber", e.target.value)
                  }
                />
              </div>
            </div>
          )}
          {msmeDetails.isMSMERegistered && (
            <div className="col-md-4 col-12">
              <label> Udyam Certificate</label>{" "}
              <span className="text-danger">*</span>
              <div className="mb-3">
                <div className="mb-3">
                  <input
                    type="file"
                    className="form-control vendor-tds-file-input-heigth d-block poel-vendor-input poel-file"
                    placeholder="Choose File*"
                    aria-label="File"
                    id="udyamCertificateFile"
                    onChange={onFileChangeMsme}
                  />
                </div>
                <p>File name : {selectedFileMsme[0]?.name}</p>
              </div>
            </div>
          )}

          <div className="col-12 col-md-6 d-block d-md-flex mb-3">
            <div>
              <label>
                MSME Declaration Form <IoDocumentText />
              </label>
              <br />
              <a
                className="btn tdf-pdf-box w-100"
                href={MsmeDeclaration}
                target="blank"
              >
                <p>Click here to Download</p>
              </a>
              <br />
              <label className="mt-2">
                (Download / Print in Letterhead / Sign / Seal)
              </label>
            </div>
          </div>

          {/* For mandetory TDS-TCS confirmation file upload */}
          <div className="col-12 col-md-6 mb-3">
            <label>Signed copy of Declaration</label>{" "}
            <span className="text-danger">*</span>
            <div>
              <div className="input-group mb-2">
                <input
                  type="file"
                  className="form-control msme-input-file d-block poel-vendor-input poel-file"
                  placeholder="Choose File*"
                  aria-label="File"
                  id="customertds"
                  name="customertds"
                  onChange={onFileChangeMsmeDeclaration}
                />
              </div>
              <p className="mb-0">
                File name : {selectedFileMsmeDeclaration[0]?.name}
              </p>
            </div>
          </div>
          <div className="col-12 text-end">
            <button
              className="px-5 py-2 enquirybtnvendor fs-6"
              type="button"
              onClick={validateMsmeForm}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default MsmeSingleForm;
