import React, { useState, useEffect } from "react";

import VendorsTcs from "../../../assets/PoelVendorPdf/Letter to Vendors on TCS.pdf";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { IoSync } from "react-icons/io5";

export default function TdsDetails({
  setActiveTab,
  handleUpdateTdsBasicInfo,
  tsdBasicInfo,
  handleSubmit,
  onFileChange,
  selectedFile,
}) {
  const handleFieldChange = (field, value) => {
    handleUpdateTdsBasicInfo({
      ...tsdBasicInfo,
      [field]: value,
    });
  };

  const [Rotatecaptcha, setRotatecaptcha] = useState(false);

  const [Enquiry, setEnquiry] = useState({
    captcha: "",
  })

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  // Get a new captcha
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);

  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <h2 className="text-center mb-4">TDS Details</h2>
        <div className="col-12 col-md-4 mb-1">
          <label>
            PAN <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control poel-vendor-input"
            id="PanNumber"
            name="panno"
            maxLength={10}
            value={tsdBasicInfo.panNo}
            onChange={(e) => handleFieldChange("panNo", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-4 mb-1">
          <label>PAN Type</label> <span className="text-danger">*</span>
          <select
            class="form-select bg-white select reason-option inputselect poel-vendor-input"
            aria-label="Default select example"
            value={tsdBasicInfo.panType}
            onChange={(e) => {
              handleFieldChange("panType", e.target.value);
            }}
          >
            {/* <option selected>PLease Select</option> */}
            <option value="">Please select</option>
            <option selected value="individual">
              Individual
            </option>
            <option value="company">Company</option>
            <option value="firm">Firm</option>
            <option value="huf">HUF</option>
          </select>
        </div>
        <div className="col-md-4 mb-1">
          <label>PAN Card</label> <span className="text-danger">*</span>
          <div className="mb-3">
            <div className="input-group mb-2">
              <input
                type="file"
                className="form-control d-block poel-vendor-input poel-file vendor-tds-file-input-height"
                placeholder="Choose File*"
                aria-label="File"
                id="PanCard"
                name="pancard"
                onChange={onFileChange}
              />
            </div>
            <p>File name : {selectedFile[0]?.name}</p>
          </div>
        </div>

        <div className="col-12 col-md-8 d-block d-md-flex">
          {/* <div className="mb-3">
            <label>Declaration under sec 206 <BsFillFileEarmarkPdfFill /> </label><br />
            <a className="btn me-3 tdf-pdf-box" href={DeclarationPdf} target="blank">
              <p>Click here to Download</p>
            </a>
          </div> */}
          <div>
            <label>Letter to Vendors on TCS <BsFillFileEarmarkPdfFill /></label><br />
            <a className="btn tdf-pdf-box" href={VendorsTcs} target="blank">
              <p>Click here to Download</p>
            </a>
          </div>
        </div>

        {/* <div className="col-md-4 mb-0 mt-2 mt-md-4 pt-0 px-3 col-12 captchdisplay">
          <p
            className="me-2 mb-2 d-flex justify-content-between captchvaluesize captcha-copy-disable captcha-number-size-otp"
            style={{ height: '53px' }}
          >
            {captcha_number}
            <span
              onClick={() => {
                setRotatecaptcha("iorotate");
                Genratecaptcha();
              }}
              className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
            >
              <IoSync className="aButtonotp rotatemani clean-room-kart-captcharotate-icon" />
            </span>
          </p>
          <input
            type="captcha"
            className="form-control captchvaluesize captchafont captchabg-otp"
            placeholder="Enter Captcha Code *"
            name="captcha"
            value={Enquiry.captcha}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div> */}

      </div>

      {/* <label>Click here to Download</label><br/>
          <a className="btn tdf-pdf-box" href={VendorsTcs} target="blank">
            <p>Letter to Vendors on TCS</p>
          </a> */}
      <div className="row">
        <div className="col-12 d-flex justify-content-center mt-4">
          <button
            className="enquirybtnvendor text-white fs-6"
            onClick={(e) => {
              setActiveTab("bankDetails");
            }}
            type="button"
          >
            Back
          </button>
          <button
            className="enquirybtnvendor text-white fs-6 ms-3"
            type="button"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
