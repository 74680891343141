import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./Bangkok.css";
const BangkokTrip = () => {
    const [mailtype, setType] = useState("");
    const [clientid, setClientId] = useState("");
    const mailtypefetchurl = useParams();
    const [Hotelselect, setHotelselect] = useState("");
    const [hotel, setHotel] = useState([
        { Key: 1, Value: "3 Star" },
        { Key: 2, Value: "4 Star" },
        { Key: 3, Value: "5 Star" },
    ]);
    const [Mealselect, setMealselect] = useState("");
    const [Meal, setMeal] = useState([
        { Key: 1, Value: "Breakfast Only" },
        { Key: 2, Value: "Breakfast & Dinner" },
        { Key: 3, Value: "All Meals" },
    ]);
    const [Tourselect, setTour] = useState("");
    const [Touroption, setTouroption] = useState([
        { Key: 1, Value: "Full day tour for All days" },
        { Key: 2, Value: "Limited site seeigns" },
    ]);
    const [Vehicleselect, setVehicle] = useState("");
    const [VehicleOption, setVehicleoption] = useState([
        { Key: 1, Value: "Private Transfer - Separate vehicle for your Family" },
        { Key: 2, Value: "SIC (Seat in Coach - Sharing with Other Guest)" },
    ]);
    // Adults
    const [Adultselect, setAdultselect] = useState("");
    const [Adultoption, setAdultoption] = useState([
        { Key: 1, Value: "1" },
        { Key: 2, Value: "2" },
        { Key: 3, Value: "3" },
        { Key: 4, Value: "4" },
        { Key: 5, Value: "5" },
        { Key: 6, Value: "6" },
        { Key: 7, Value: "7" },
        { Key: 8, Value: "8" },
        { Key: 9, Value: "9" },
        { Key: 10, Value: "10" },
        { Key: 11, Value: "11" },
        { Key: 12, Value: "12" },
        { Key: 13, Value: "13" },
        { Key: 14, Value: "14" },
        { Key: 15, Value: "15" },
        { Key: 16, Value: "16" },
        { Key: 17, Value: "17" },
        { Key: 18, Value: "18" },
        { Key: 19, Value: "19" },
        { Key: 20, Value: "20" },
    ]);
    // Children
    const [Childrenselect, setChildrenselect] = useState("0");
    const [Childrenoption, setChildrenoption] = useState([
        { Key: 1, Value: "1" },
        { Key: 2, Value: "2" },
        { Key: 3, Value: "3" },
        { Key: 4, Value: "4" },
        { Key: 5, Value: "5" },
        { Key: 6, Value: "6" },
        { Key: 7, Value: "7" },
        { Key: 8, Value: "8" },
        { Key: 9, Value: "9" },
        { Key: 10, Value: "10" },
    ]);
    // infants
    const [Infantsselect, setInfantsselect] = useState("0");
    const [Infantsoption, setInfantsoption] = useState([
        { Key: 1, Value: "1" },
        { Key: 2, Value: "2" },
        { Key: 3, Value: "3" },
        { Key: 4, Value: "4" },
        { Key: 5, Value: "5" },
        { Key: 6, Value: "6" },
        { Key: 7, Value: "7" },
        { Key: 8, Value: "8" },
        { Key: 9, Value: "9" },
        { Key: 10, Value: "10" },
    ]);
    // state for loader
    const [Loader, setLoader] = useState(false);
    const [Rotatecaptcha, setRotatecaptcha] = useState(false);
    const [Enquiry, setEnquiry] = useState({
        name: "",
        email: "",
        mobile: "",
        countrytravel: "",
        nigthstay: "",
        personnum: "",
        room: "",
        message: "",
        captcha: "",
        checkbox: false,
        EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        NumRegex: /^[0-9]*$/,
    });
    function BangkokTripenquiry_formate() {
        return (
            '<html><head><meta charset="utf-8"><title>Elite India | ENQUIRY</title></head>' +
            ' <body style="font-family: sans-serif">' +
            ' <div style="width: 100%; margin: auto">' +
            '<div style="text-align: left; margin-bottom: -25px">' +
            '<img style="width: 30%" src="https://globaltrendz.online/bangkoktrip/assets/images/logo-light.png"/>' +
            "</div>" +
            '<div style="background-color: #8080800d; padding: 20px">' +
            '<h2 style="text-align: left;font-size:14px;color: black;">Dear Elite India, </h2>' +
            "<p style='font-size:14px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.name +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.mobile +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.email +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Country of Travel</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.countrytravel +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Number of Person</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Adultselect +
            " " +
            "Adults," +
            " " +
            Childrenselect +
            " " +
            "Children," +
            " " +
            Infantsselect +
            " " +
            "Infants" +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Number of Nights</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.nigthstay +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Hotel</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Hotelselect +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Number of rooms</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.room +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Meals</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Mealselect +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Tour</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Tourselect +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Vehicle</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Vehicleselect +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.message +
            "</div>" +
            "</div>" +
            "<br>" +
            "</div></div>" +
            "</body>" +
            "</html>"
        );
    }
    useEffect(() => {
        if (Rotatecaptcha == "") {
            setRotatecaptcha("iorotatevig");
        }
    }, [Rotatecaptcha]);
    const [captcha_number, setcaptcha_number] = useState("");
    useEffect(() => {
        setcaptcha_number(
            Math.floor(100000 + Math.random() * 900000)
                .toString(36)
            [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
        );
    }, []);
    function Genratecaptcha() {
        var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
            .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
        setcaptcha_number(reloadcapcha);
        setRotatecaptcha(!Rotatecaptcha);
    }
    // notiflix message position
    useEffect(() => {
        Notiflix.Notify.Init({
            width: "280px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
            clickToClose: true,
            showOnlyTheLastOne: true,
            useFontAwesome: true,
            fontSize: "15px",
            timeout: 4000,
        });
    }, []);
    useEffect(() => {
        setType(mailtypefetchurl.mailtype);
        setClientId(mailtypefetchurl.clientid);
    }, []);
    return (
        <div className="container-fluid form-border" style={{ height: "100vh", background: "#F4F5F8" }}>
            <form className="row py-3">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Name<span className="text-danger classmandatory">*</span>
                    </label>
                    <input type="text" className="form-control inputborderradius inputborderradiusbangkok" id="Name" name="name" value={Enquiry.name} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Email
                    </label>
                    <input type="email" className="form-control inputborderradius inputborderradiusbangkok" id="email_id" name="email" value={Enquiry.email} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Mobile Number<span className="text-danger classmandatory">*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control inputborderradius inputborderradiusbangkok"
                        id="MobileNumber"
                        name="mobile"
                        value={Enquiry.mobile}
                        onChange={(e) => {
                            if (e.target.value.length <= 10)
                                setEnquiry({
                                    ...Enquiry,
                                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                                });
                        }}
                    />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Country of Travel<span className="text-danger classmandatory">*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control inputborderradius inputborderradiusbangkok"
                        id="countrytravel"
                        name="countrytravel"
                        value={Enquiry.countrytravel}
                        onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })}
                    />
                </div>
                <div className="col-lg-4 col-md-8 col-12 mb-3">
                    <div className="row">
                        <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                            <label className="labelfontbang" for="message">
                                Adult
                                <span className="adultage ms-1" style={{ fontSize: "9px" }}>
                                    (11+ Years)
                                    <span className="text-danger classmandatory">*</span>
                                </span>
                            </label>
                            <select
                                name="consulting"
                                className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
                                style={{ borderRadius: "0px !important" }}
                                value={Adultselect}
                                onChange={(e) => {
                                    setAdultselect(e.target.value);
                                }}
                            >
                                <option key="" value="">
                                    0
                                </option>
                                {Adultoption.map((data, index) => {
                                    return (
                                        <option key={data.Key} value={data.Value}>
                                            {data.Value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {/* childern */}
                        <div className="col-lg-4 col-sm-4 col-md-4 col-4 childrenpadding childrenphnmargin">
                            <label className="labelfontbang" for="message">
                                Children
                                <span className="adultage ms-1" style={{ fontSize: "9px" }}>
                                    (3-11 Years)
                                </span>
                            </label>
                            <select
                                name="consulting"
                                className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
                                style={{
                                    borderRadius: "0px !important",
                                }}
                                value={Childrenselect}
                                onChange={(e) => {
                                    setChildrenselect(e.target.value);
                                }}
                            >
                                <option key="0" value="0">
                                    0
                                </option>
                                {Childrenoption.map((data, index) => {
                                    return (
                                        <option key={data.Key} value={data.Value}>
                                            {data.Value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {/* Infants */}
                        <div className="col-lg-4 col-sm-4 col-4 col-md-4 childrenphnmargin">
                            <label className="labelfontbang" for="message">
                                Infants
                                <span className="adultage ms-1" style={{ fontSize: "9px" }}>
                                    (Below 2Years)
                                </span>
                            </label>
                            <select
                                name="consulting"
                                className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
                                style={{
                                    borderRadius: "0px !important",
                                }}
                                value={Infantsselect}
                                onChange={(e) => {
                                    setInfantsselect(e.target.value);
                                }}
                            >
                                <option key="0" value="0">
                                    0
                                </option>
                                {Infantsoption.map((data, index) => {
                                    return (
                                        <option key={data.Key} value={data.Value}>
                                            {data.Value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Number of Nights<span className="text-danger classmandatory">*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control inputborderradius inputborderradiusbangkok"
                        id="nigthstay"
                        name="nigthstay"
                        value={Enquiry.nigthstay}
                        onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })}
                    />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Hotel<span className="text-danger classmandatory">*</span>
                    </label>
                    <select
                        name="consulting"
                        className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
                        style={{
                            borderRadius: "0px !important",
                        }}
                        value={Hotelselect}
                        onChange={(e) => {
                            setHotelselect(e.target.value);
                        }}
                    >
                        <option key="" value="">
                            Select Hotel Type
                        </option>
                        {hotel.map((data, index) => {
                            return (
                                <option key={data.Key} value={data.Value}>
                                    {data.Value}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Number of Room<span className="text-danger classmandatory">*</span>
                    </label>
                    <input type="text" className="form-control inputborderradius inputborderradiusbangkok" id="room" name="room" value={Enquiry.room} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Meals<span className="text-danger classmandatory">*</span>
                    </label>
                    <select
                        name="consulting"
                        className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
                        style={{
                            borderRadius: "0px !important",
                        }}
                        value={Mealselect}
                        onChange={(e) => {
                            setMealselect(e.target.value);
                        }}
                    >
                        <option key="" value="">
                            Select Meal Type
                        </option>
                        {Meal.map((data, index) => {
                            return (
                                <option key={data.Key} value={data.Value}>
                                    {data.Value}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Tour<span className="text-danger classmandatory">*</span>
                    </label>
                    <select
                        name="consulting"
                        className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
                        style={{
                            borderRadius: "0px !important",
                        }}
                        value={Tourselect}
                        onChange={(e) => {
                            setTour(e.target.value);
                        }}
                    >
                        <option key="" value="">
                            Select Tour Type
                        </option>
                        {Touroption.map((data, index) => {
                            return (
                                <option key={data.Key} value={data.Value}>
                                    {data.Value}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Vehicle
                    </label>
                    <select
                        name="consulting"
                        className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
                        style={{
                            borderRadius: "0px !important",
                        }}
                        value={Vehicleselect}
                        onChange={(e) => {
                            setVehicle(e.target.value);
                        }}
                    >
                        <option key="" value="">
                            Select Vehicle Type
                        </option>
                        {VehicleOption.map((data, index) => {
                            return (
                                <option key={data.Key} value={data.Value}>
                                    {data.Value}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-lg-4 col-12 mb-3">
                    <label className="labelfontbang" for="message">
                        Message<span className="text-danger classmandatory">*</span>
                    </label>
                    <textarea className="form-control inputborderradius inputborderradiusbangkok" id="Message" name="message" value={Enquiry.message} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })}></textarea>
                </div>
                <div className="col-12 mb-3 captchdisplay">
                    <p
                        className="px-2 py-2 mb-2 me-2 border-dotted captcha-copy-disable captchvaluesize inputborderradius d-flex justify-content-between inputborderradiusbangkok"
                        style={{
                            color: "#495057",
                            background: "#ffffffe0",
                        }}
                    >
                        {captcha_number}
                        <span
                            onClick={() => {
                                setRotatecaptcha("iorotatevig");
                                Genratecaptcha();
                            }}
                            className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
                        >
                            <IoSync className="aButtonvig rotate" />
                        </span>
                    </p>
                    <input
                        type="captcha"
                        className="form-control captchvaluesize inputborderradius inputborderradiusbangkok classmandatory"
                        placeholder="Enter Captcha*"
                        style={{
                            paddingTop: "17px",
                            paddingBottom: "18px",
                        }}
                        name="captcha"
                        value={Enquiry.captcha}
                        onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })}
                    />
                </div>
                <div className="col-md-3 col-12 col-sm-4">
                    <button
                        className="btn w-100 text-white inputborderradius inputborderradiusbangkok"
                        style={{
                            fontSize: "15px",
                            color: "white",
                            background: "#2095AE",
                            // border: "#5791e3",
                            padding: "9px 25px",
                        }}
                        type="button"
                        onClick={() => {
                            if (Enquiry.name != "") {
                                if (Enquiry.email == "" || Enquiry.EmailRegex.test(Enquiry.email)) {
                                    if (Enquiry.mobile != "") {
                                        if (Enquiry.mobile.length == 10) {
                                            if (Enquiry.countrytravel != "") {
                                                if (Adultselect != "") {
                                                    if (Enquiry.nigthstay !== "") {
                                                        if (Hotelselect != "") {
                                                            if (Enquiry.room != "") {
                                                                if (Mealselect != "") {
                                                                    if (Tourselect != "") {
                                                                        if (Enquiry.message != "") {
                                                                            if (Enquiry.captcha.toString() == captcha_number.toString()) {
                                                                                setLoader(true);
                                                                                const htmlMail = BangkokTripenquiry_formate();
                                                                                PostApiCall.postRequest(
                                                                                    {
                                                                                        mailtype: mailtype,
                                                                                        mail: htmlMail,
                                                                                        clientid: clientid,
                                                                                        name: Enquiry.name,
                                                                                        mobile: Enquiry.mobile,
                                                                                        email: Enquiry.email,
                                                                                        message: Enquiry.message,
                                                                                    },
                                                                                    "NewEnquiryMailerApi"
                                                                                ).then((results2) =>
                                                                                    results2.json().then((obj2) => {
                                                                                        if (results2.status == 200 || results2.status == 201) {
                                                                                            setLoader(false);
                                                                                            Notiflix.Notify.Success("Thank you, our team will contact you shortly!");
                                                                                            setTimeout(() => {
                                                                                                window.location.reload();
                                                                                            }, 3000);
                                                                                            // window.location.reload();
                                                                                        }
                                                                                    })
                                                                                );
                                                                            } else {
                                                                                Notiflix.Notify.Failure("Please enter valid captcha");
                                                                            }
                                                                        } else {
                                                                            Notiflix.Notify.Failure("Please enter message");
                                                                        }
                                                                    } else {
                                                                        Notiflix.Notify.Failure("Please select tour");
                                                                    }
                                                                } else {
                                                                    Notiflix.Notify.Failure("Please select meals type");
                                                                }
                                                            } else {
                                                                Notiflix.Notify.Failure("Please select number of rooms");
                                                            }
                                                        } else {
                                                            Notiflix.Notify.Failure("Please select hotel type");
                                                        }
                                                    } else {
                                                        Notiflix.Notify.Failure("Please enter number of nigths ?");
                                                    }
                                                } else {
                                                    Notiflix.Notify.Failure("Please enter number of Adults ?");
                                                }
                                            } else {
                                                Notiflix.Notify.Failure("Please enter the country name");
                                            }
                                        } else {
                                            Notiflix.Notify.Failure("Please enter valid mobile number");
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("Please enter mobile number");
                                    }
                                } else {
                                    Notiflix.Notify.Failure("Please enter valid email");
                                }
                            } else {
                                Notiflix.Notify.Failure("Please enter name");
                            }
                        }}
                    >
                        {Loader == true ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status" style={{ width: "1.4rem", height: "1.4rem" }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            "Send Message"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};
export default BangkokTrip;
