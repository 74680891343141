import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./globaltrendz.css";
import { IoSync } from "react-icons/io5";
const GlobalTrendzEnquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    captcha: "",
    companyname: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  function GlobalTrendzEnquiry_format() {
    return (
      '<html><head><meta charset="utf-8"><title>Global Trendz | Enquiry Form</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="background-color: #8080800d;">' +
      '<div style="padding: 20px">' +
      '<div style=width:"100%">' +
      '<img src="https://globaltrendz.us/assets/img/logo/logo.png" style="width: 100px;margin-bottom: 10px;"/>' +
      '<p style="text-align: left;font-size: 15px;color:#000; padding: 0;margin: 5px 0;">Dear Sir/Mam,</p>' +
      '<p style="font-size: 15px;color: black;padding: 0;margin: 5px 0;">Greetings! you have received an enquiry via the website. The details are as follows:</p>' +
      `<p style="width: 100%;font-size: 15px;color: black;text-transform: capitalize;padding: 0;
      margin: 5px 0;"><b style="color: black">Name: </b>
      ${Enquiry.name}</p>` +
      `<p style="width: 100%;font-size: 15px;color: black; padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Company Name: </b> ${Enquiry.companyname} </p>` +
      `<p style="width: 100%;font-size: 15px;color: black; padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Mobile No. : </b>
      ${Enquiry.mobile} </p>` +
      `<p style="width: 100%;font-size: 15px;color: black; padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Email: </b> ${Enquiry.email} </p>` +
      `<p style="width: 100%; font-size: 15px;color: black;padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Message: </b>
      ${Enquiry.message}` +
      "<p style='font-size: 15px;color: black'><strong>Thank You</strong></p>" +
      "</div>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 900000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  // console.log(Rotatecaptcha);
  return (
    <>
      <div className="container-fluid form-border gnparent">
        <form className="row g-3">
          <div className="col-12">
            <input
              type="text"
              className="form-control gt-form-control-input redrock_captcha py-4 "
              id="name"
              placeholder="Your Name *"
              name="name"
              value={Enquiry.name}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>

          <div className="col-12">
            <input
              type="text"
              className="form-control gt-form-control-input redrock_captcha py-4"
              id="companyname"
              placeholder="Company Name"
              name="companyname"
              value={Enquiry.companyname}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>

          <div className="col-12 col-lg-6">
            <input
              type="email"
              className="form-control gt-form-control-input redrock_captcha py-4"
              id="email"
              placeholder="Email Address *"
              name="email"
              value={Enquiry.email}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-lg-6">
            <input
              type="text"
              className="form-control gt-form-control-input redrock_captcha py-4"
              id="mobile"
              placeholder="Phone Number *"
              name="mobile"
              value={Enquiry.mobile}
              onChange={(e) => {
                if (e.target.value.length <= 30)
                  setEnquiry({
                    ...Enquiry,
                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                  });
              }}
            />
          </div>
          <div className="col-12">
            <textarea
              class="form-control message-field gt-textarea gn-text-area"
              type="text"
              rows="5"
              id="message"
              placeholder="Message *"
              name="message"
              value={Enquiry.message}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6 captchdisplay">
            <p
              className="me-2 d-flex justify-content-between captchvaluesize xtreme-captcha-bg captcha-copy-disable gt-captcha-value"
              style={{
                color: "#000000",
                background: "#ffffffe0",
                border: "2px dotted #ced4da",
                padding: "14px 15px",
              }}
            >
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotate");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
              >
                <IoSync className="aButtonred rotate" />
              </span>
            </p>
            <input
              type="captcha"
              className="form-control gt-form-control-input captchvaluesize inputzeroborder-radius redrockinput redrock_captcha"
              placeholder="Enter Captcha Code *"
              name="captcha"
              value={Enquiry.captcha}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <button
              className="py-2 px-4 fs-6 gt-enquiry-btn w-100 border-0 text-white"
              type="button"
              onClick={() => {
                if (Enquiry.name != "") {
                  if (Enquiry.email != "") {
                    if (Enquiry.EmailRegex.test(Enquiry.email)) {
                      if (Enquiry.mobile != "") {
                        if (Enquiry.message != "") {
                          if (
                            Enquiry.captcha.toString() ==
                            captcha_number.toString()
                          ) {
                            setLoader(true);
                            const htmlMail = GlobalTrendzEnquiry_format();
                            PostApiCall.postRequest(
                              {
                                mailtype: "Website",
                                mail: htmlMail,
                                clientid: clientid,
                                name: Enquiry.name,
                                mobile: Enquiry.mobile,
                                email: Enquiry.email,
                                message: Enquiry.message,
                              },
                              "NewEnquiryMailerApi"
                            ).then((results2) =>
                              results2.json().then((obj2) => {
                                if (
                                  results2.status == 200 ||
                                  results2.status == 201
                                ) {
                                  setLoader(false);
                                  Notiflix.Notify.Success(
                                    "Thank you, our team will contact you shortly!"
                                  );
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 3000);
                                }
                              })
                            );
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter valid captcha"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter message");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter phone number");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter valid email");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter email");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter name");
                }
              }}
            >
              {Loader == true ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                "SEND ENQUIRY"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default GlobalTrendzEnquiry;
