 var urlString = "https://globaltrendz.info/gt-api/";
// var urlString = "http://localhost:8061/";
// var urlString = "http://localhost:8088/gt-api/";
// var urlString = "http://192.168.1.25:8088/gt-api/";
const PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then(response => {
        return response
      })
      .catch(error => {
        console.log('request failed', error);
        return error;
      });
  },
};
export default PostApiCall;