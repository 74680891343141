import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./form.css";
import { IoSync } from "react-icons/io5";
const EmiratesEnquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Career, setCareer] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function RoobyFoodEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Emirates Residency Enquiry</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: auto; margin: auto;border: 1px solid grey;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left; border-bottom: 1px dotted #ef1a24;padding: 10px 0px 10px 0px;margin: 0px 10px 0px 10px;">' +
      '<img style="width: 130px" src="http://emiratesresidency.in/assets/img/logo.png"/>' +
      "</div>" +
      '<div style="padding: 20px">' +
      '<h4 style="text-align: left">Dear Sir/Mam, </h4>' +
      '<h4 style="font-weight:100">Greetings! you have received an enquiry via the website. The details are as follows:</h4>' +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Full Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.message +
      "</div>" +
      "</div>" +
      "<br>" +
      "<br>" +
      '<div style="width:100%;display:flex;border-top: 1px solid red;padding-top: 20px;padding-bottom: 22px;">' +
      '<b>Design by <a href="http://globaltrendz.com/">Global Trendz</a> </b>' +
      "</div>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div
      className="container-fluid form-border"
      style={{ background: "rgba(255, 255, 255, 1)" }}
    >
      <form id="contact-form" className="padding-md-topbottom-null">
        <div className="row">
          <div className="col-md-12">
            <input
              className="form-field"
              type="text"
              placeholder="Name*"
              id="Name"
              name="name"
              value={Career.name}
              onChange={(e) =>
                setCareer({ ...Career, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-md-12">
            <input
              className="form-field"
              type="text"
              placeholder="Email"
              id="email_id"
              name="email"
              value={Career.email}
              onChange={(e) =>
                setCareer({ ...Career, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-md-12">
            <input
              className="form-field"
              type="text"
              placeholder="Mobile Number*"
              id="MobileNumber"
              name="mobile"
              value={Career.mobile}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setCareer({
                    ...Career,
                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                  });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <textarea
              className="form-field"
              rows="6"
              placeholder="Your Message"
              id="message"
              name="message"
              value={Career.message}
              onChange={(e) =>
                setCareer({ ...Career, [e.target.name]: e.target.value })
              }
            ></textarea>
          </div>
          <div className="col-md-12 d-flex mb-3">
            <p
              className="px-2 py-2 mb-2 me-2 border-dotted captchvaluesize emiratescaptcha inputborderradius captcha-copy-disable d-flex justify-content-between"
            >
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotatevig");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
              >
                <IoSync className="aButtonvig rotate" />
              </span>
            </p>
            <input
              type="captcha"
              className="form-control border captchvaluesize inputborderradius emiratescaptcha"
              placeholder="Enter Captcha*"
              name="captcha"
              value={Career.captcha}
              onChange={(e) =>
                setCareer({ ...Career, [e.target.name]: e.target.value })
              } />
          </div>
          <div className="submit-area padding-onlytop-sm col-md-12">
            <input
              type="button"
              id="submit-contact"
              className="btn-alt"
              value="Send Message"
              onClick={() => {
                if (Career.name != "") {
                  if (
                    Career.email == "" ||
                    Career.EmailRegex.test(Career.email)
                  ) {
                    if (Career.mobile != "") {
                      if (Career.mobile.length == 10) {
                        if (
                          Career.captcha.toString() == captcha_number.toString()
                        ) {
                          const htmlMail = RoobyFoodEnquiry_formate();
                          Notiflix.Loading.Arrows("Please wait...");
                          PostApiCall.postRequest(
                            {
                              mailtype: mailtype,
                              mail: htmlMail,
                              clientid: clientid,
                              name: Career.name,
                              mobile: Career.mobile,
                              email: Career.email,
                              message: Career.message,
                            },
                            "NewEnquiryMailerApi"
                          ).then((results2) =>
                            results2.json().then((obj2) => {
                              if (
                                results2.status == 200 ||
                                results2.status == 201
                              ) {
                                Notiflix.Notify.Success(
                                  "Thank you, our team will contact you shortly!"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 3000);
                              } else Notiflix.Loading.Remove();
                            })
                          );
                        }
                        else {
                          Notiflix.Notify.Failure("Please enter valid captcha");
                        }
                      }
                      else {
                        Notiflix.Notify.Failure(
                          "Please enter valid mobile number"
                        );
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter mobile number");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid email");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter name");
                }
              }}
            />
            <div id="msg" class="message"></div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EmiratesEnquiry;
