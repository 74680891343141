import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./OnDRoof.css";

const OnDRoof = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();

  // Select Branch
  const [Branch, setBranch] = useState("");
  const [branchoption, setbranchoption] = useState([
    { Key: 1, Value: "Chennai" },
    { Key: 2, Value: "Bangalore" },
  ]);

  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  function OnDRoof_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>On D Roof | ENQUIRY</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%; margin: auto">' +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<div style="text-align: left; margin-bottom: 10px;">' +
      '<img style="width: 150px;background: #000;padding: 10px 2px;border-radius: 10px;" src="https://odrrestaurant.com/img/odr-logo.png"/>' +
      "</div>" +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Madam, </h2>' +
      "<p style='font-size:14px;color: black;'>Greetings! you have received an enquiry via the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Branch</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Branch +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.message +
      "</div>" +
      "</div>" +
      "<br>" +"<p><b>Thank You</b></p>"+
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 900000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <>
      <div
        className="container-fluid form-border passagebg"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <form className="row">
          <div className="col-12 col-md-6 mb-4">
            <input
              type="text"
              className="form-control ondroofinput"
              id="Name"
              name="name"
              placeholder="Name *"
              value={Enquiry.name}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6 mb-4">
            <input
              type="text"
              className="form-control ondroofinput"
              id="MobileNumber"
              name="mobile"
              placeholder="Mobile Number *"
              value={Enquiry.mobile}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setEnquiry({
                    ...Enquiry,
                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                  });
              }}
            />
          </div>
          <div className="col-12 col-md-6 mb-4">
            <input
              type="email"
              className="form-control ondroofinput"
              placeholder="Email"
              id="email_id"
              name="email"
              value={Enquiry.email}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>

          <div className="col-12 col-md-6 mb-4">
            <select
              name="consulting"
              className="select ondroofinputdropdown reason-option minimal bg-white form-control inputselect"
              value={Branch}
              onChange={(e) => {
                setBranch(e.target.value);
              }}
            >
              <option key="" value="">
                Select Branch *
              </option>
              {branchoption.map((data, index) => {
                return (
                  <option key={data.Key} value={data.Value}>
                    {data.Value}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-12 mb-4">
            <textarea
              className="form-control ondrooftextarea"
              rows="5"
              id="Message"
              placeholder="Message"
              name="message"
              value={Enquiry.message}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            ></textarea>
          </div>
          <div className="col-md-12 mb-4 captchdisplay">
            <p className="mb-2 me-2 d-flex justify-content-between captchvaluesize inputborderradius inputzeroborder-radius inputbgzero inputzero ondroofcaptcha passagecaptcafont ondroof-captch-heigth">
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotate");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
              >
                <IoSync className="passagerotatebtn rotate" />
              </span>
            </p>
            <input
              type="captcha"
              className="form-control ondroof-captch-heigth ondroofcaptchainput captchvaluesize inputborderradius inputbgzero inputzero mt-4 mt-sm-0"
              placeholder="Enter Captcha Code *"
              name="captcha"
              value={Enquiry.captcha}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 text-end">
            <button
              className="ondroofbtn px-5 py-2 ondroof-captch-heigth"
              type="button"
              onClick={() => {
                if (Enquiry.name != "") {
                  if (
                    Enquiry.email == "" ||
                    Enquiry.EmailRegex.test(Enquiry.email)
                  ) {
                    if (Enquiry.mobile != "") {
                      if (Enquiry.mobile.length == 10) {
                        if (Branch != "") {
                          if (
                            Enquiry.captcha.toString() ==
                            captcha_number.toString()
                          ) {
                            setLoader(true);
                            const htmlMail = OnDRoof_formate();
                            PostApiCall.postRequest(
                              {
                                mailtype: mailtype,
                                mail: htmlMail,
                                clientid: clientid,
                                name: Enquiry.name,
                                mobile: Enquiry.mobile,
                                email: Enquiry.email,
                                message: Enquiry.message,
                              },
                              "NewEnquiryMailerApi"
                            ).then((results2) =>
                              results2.json().then((obj2) => {
                                if (
                                  results2.status == 200 ||
                                  results2.status == 201
                                ) {
                                  setLoader(false);
                                  Notiflix.Notify.Success(
                                    "Thank you, our team will contact you shortly!"
                                  );
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 3000);
                                }
                              })
                            );
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter valid captcha"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure("Please select branch");
                        }
                      } else {
                        Notiflix.Notify.Failure(
                          "Please enter valid mobile number"
                        );
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter mobile number");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid email");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter name");
                }
              }}
            >
              {Loader == true ? (
                <div className="d-flex justify-content-center px-3">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default OnDRoof;
