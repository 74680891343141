import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// pdf image
import pdf from "../../assets/images/pdf.png";

class Jobapplication extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      EnquiryListData: [],
      imgurl: "",
      fileData: [],
      startDate: null,
      endDate: null,
      searchInput: "",
      loading: false,
      ResumeId: null,
    };
  }
  imageClick = (app_id) => {
    PostApiCall.postRequest(
      {
        // WhereClause:"where fld_application_id=3"
        WhereClause: `where fld_application_id=${app_id}`,
      },
      "getjobapplicationpdf"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data[0].fld_attachment);
        document.getElementById("pdfelement").href = obj.data[0].fld_attachment;
        document.getElementById("pdfelement").click();
        this.setState({
          loading: false,
        });
      })
    );
  };

  componentDidMount() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));

    PostApiCall.postRequest(
      {
        // WhereClause: `where FLD_CLIENT_ID=${loginDetails[0].fld_userid}`,
        WhereClause: `where FLD_CLIENT_ID=${loginDetails[0].fld_client_id}`,
        // WhereClause: "where FLD_CLIENT_ID=1020",
      },
      // "getEnquiryData"
      "getjobapplicationdata"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          EnquiryListData: obj.data,
          // console.log(EnquiryListData);
        });
      })
    );
  }
  onPost() {
    Notiflix.Loading.Dots("Please Wait...");
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_client_id=${loginDetails[0].fld_client_id
          // 1020
          } AND cast (fld_created_on as date) BETWEEN cast('${moment(
            this.state.startDate == null ? null : this.state.startDate
          ).format("MM-DD-YYYY")}' as date)
    AND cast('${moment(
            this.state.endDate == null ? null : this.state.endDate
          ).format("MM-DD-YYYY")}' as date)
    `,
      },
      // "getEnquiryData"
      "getjobapplicationdata"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({
            EnquiryListData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  //   Search bar
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  // Date handler

  dateHandler = (e) => {
    e.preventDefault();
    Notiflix.Loading.Dots("Please Wait...");
    PostApiCall.postRequest(
      {
        RecordCount: "*",
        WhereClause: `where cast (FLD_CREATED_ON as date) BETWEEN cast('${moment(
          this.state.startDate == null ? null : this.state.startDate
        ).format("YYYY-MM-DD")}' as date)
        AND cast('${moment(
          this.state.endDate == null ? null : this.state.endDate
        ).format("YYYY-MM-DD")}' as date)
        `,
      },
      "GET_DATA_BY_DATE"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({
            EnquiryListData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  // download

  DownloadCSV = () => {
    this.setState(
      {
        fileData: this.state.EnquiryListData,
      },
      () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      }
    );
  };

  //upload image end
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content pb-0">
          <Container fluid>
            <Breadcrumbs
              title={"Global Trendz"}
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              )
                .replace("-", " ")
                .replace("_", " ")}
              urlPath={"/customer-management"}
            />
            <div className="row">
              <div className="col-12">
                <Card className="mini-stats-wid">
                  <CardBody className="pt-0 mt-4">
                    <Media>
                      <Media body className="w-100">
                        <Row>
                          <Col xl="12">
                            <Card className="overflow-hidden">
                              <div className="gt-enquiry-bg-soft-pink">
                                <Row>
                                  <Col xs="6">
                                    <div className="gt-enquiry-text p-3">
                                      <h5 className="gt-enquiry-text text-white">
                                        Job Applications
                                      </h5>
                                    </div>
                                  </Col>

                                  <Col xs="6">
                                    <div className="gt-enquiry-text p-3">
                                      <Link
                                        style={{
                                          float: "right",
                                          marginTop: "-5px",
                                        }}
                                        className="btn align-items-center btn gt-enquiry-btn2 "
                                        onClick={this.DownloadCSV.bind(this)}
                                      >
                                        Download(.csv)
                                      </Link>
                                      <CSVLink
                                        data={this.state.fileData}
                                        filename="EnquiryData.csv"
                                        className="hidden"
                                        ref={this.csvLink}
                                        target="_blank"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>

                              <Row className="my-3 px-2">
                                <div className="col-2 d-flex align-item-center pl-3 enquiry-start-date-size">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    onChange={(e) => this.seachBarHandler(e)}
                                    value={this.state.searchInput}
                                  />
                                </div>
                                <div className="col-4 d-flex enquiry-start-date-size">
                                  <h5 className="display-inline pb-0 mb-0">
                                    Start Date
                                  </h5>
                                  <input
                                    className="form-control left-margin-negative"
                                    type="date"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={this.state.startDate}
                                    onChange={(e) => {
                                      this.setState({
                                        startDate: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-4 d-flex enquiry-start-date-size">
                                  <h5 className="display-inline pb-0 mb-0">
                                    End Date
                                  </h5>
                                  <input
                                    className="form-control left-margin-negative"
                                    type="date"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={this.state.endDate}
                                    onChange={(e) => {
                                      this.setState({
                                        endDate: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-2 text-end pr-3 d-flex enquiry-filter-size">
                                  <a
                                    className="btn align-items-center btn gt-enquiry-btn px-4 bg-dark text-white"
                                    style={{ marginLeft: "10px" }}
                                    onClick={(e) => {
                                      if (
                                        this.state.startDate != null &&
                                        this.state.endDate != null
                                      ) {
                                        this.onPost();
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Please Enter Start Date and end Date"
                                        );
                                      }
                                    }}
                                  >
                                    Filter
                                  </a>
                                </div>
                              </Row>
                            </Card>
                          </Col>
                        </Row>

                        <div
                          class="table-responsive my-custom-scrollbar"
                          style={{
                            maxHeight: "530px",
                            overflowX: "scroll",
                          }}
                        >
                          <table
                            class="table table-bordered mb-0"
                            style={{ width: "210vw" }}
                          >
                            <thead>
                              <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Email</th>
                                <th>Age</th>
                                <th>Location</th>
                                <th>Country</th>
                                <th>Post Applied</th>
                                <th>Qualification</th>
                                <th>Carrer in a Nutshell</th>
                                <th>Strong Suits</th>
                                <th>Professional Achievements</th>
                                <th>Reason Leaving Current Job</th>
                                <th>Other Information</th>
                                <th>Requested on</th>
                                <th>Attachment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.EnquiryListData != null
                                ? this.state.EnquiryListData.filter((data) => {
                                  if (this.state.searchInput == "") {
                                    return data;
                                  }
                                  if (
                                    (this.state.searchInput != "" &&
                                      `${data.fld_name
                                          ? data.fld_name.toLowerCase()
                                          : ""
                                        }`.includes(
                                          this.state.searchInput.toLowerCase()
                                        )) ||
                                    `${data.fld_city
                                        ? data.fld_city.toLowerCase()
                                        : ""
                                      }`.includes(
                                        this.state.searchInput.toLowerCase()
                                      ) ||
                                    `${data.fld_mobile_no
                                        ? data.fld_mobile_no.toLowerCase()
                                        : ""
                                      }`.includes(
                                        this.state.searchInput.toLowerCase()
                                      )
                                  ) {
                                    return data;
                                  }
                                }).map((data, i) => (
                                  <tr>
                                    <td className="centered">{i + 1}</td>
                                    <td className="leftAlign">
                                      {data.fld_name}
                                    </td>
                                    <td className="centered">
                                      {data.fld_mobile}
                                    </td>
                                    <td className="centered">
                                      {data.fld_email}
                                    </td>
                                    <td className="centered">
                                      {data.fld_age}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_location}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_country}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_post_applied}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_qualification}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_career_nutshell}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_strong_suit}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_achievements}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_job_leaving_reason}
                                    </td>
                                    <td className="rightAlign">
                                      {data.fld_other_info}
                                    </td>
                                    <td className="rightAlign">
                                      {moment(data.fld_created_on).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>

                                    {this.state.loading == true &&
                                      this.state.ResumeId ==
                                      data.fld_application_id ? (
                                      <div class="d-flex justify-content-center">
                                        <div
                                          class="spinner-border"
                                          role="status"
                                          style={{
                                            width: "1.4rem",
                                            height: "1.4rem",
                                          }}
                                        >
                                          <span class="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <td className="rightAlign">
                                        {data.fld_pdf_status === "1" ? (
                                          <img
                                            src={pdf}
                                            style={{
                                              width: "40px",
                                              cursor: "pointer",
                                            }}
                                            // onClick={imageClick(data.fld_application_id)}
                                            onClick={(event) => {
                                              this.setState(
                                                {
                                                  ResumeId:
                                                    data.fld_application_id,
                                                  loading: true,
                                                },
                                                () => {
                                                  this.imageClick(
                                                    data.fld_application_id
                                                  );
                                                }
                                              );
                                            }}
                                          />
                                        ) : (
                                          "No resume available"
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                ))
                                : ""}
                            </tbody>
                          </table>

                          <a
                            href=""
                            id="pdfelement"
                            style={{ visibility: "hidden" }}
                            download="Resume.pdf"
                          >
                            Check
                          </a>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Jobapplication;
