import React, { useEffect, useState, memo } from "react";
import GetApiCall from "../../../GETAPI";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";

const BasicInformation = memo(
  ({
    setActiveTab,
    basicInfoData,
    onHandleUpdate,
    onFileChangeTradelicense,
    selectedTradeLicense,
    onSubmit
  }) => {
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [countryId, setCountryId] = useState(101);
    const [stateId, setStateId] = useState(null);
    const [cityId, setCityId] = useState(null);

    const handleFieldChange = (field, value) => {
      onHandleUpdate({
        ...basicInfoData,
        [field]: value,
      });
    };

    const getCountry = () => {
      Notiflix.Loading.Dots();
      GetApiCall.getRequest("Get_Country").then((results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setCountryList(obj.data);
            Notiflix.Loading.Remove();
            const storedCountryId = localStorage.getItem("countryId");
            if (storedCountryId) {
              setCountryId(storedCountryId);
              getState(storedCountryId);
            } else {
              getState(obj.data[0]?.value);
              Notiflix.Loading.Remove();
            }
          } else {
            Notiflix.Loading.Remove(
              "Something went wrong, please try again later."
            );
          }
        })
      );
    };

    const getState = (countryId) => {
      PostApiCall.postRequest({ countryid: countryId }, "Get_State").then(
        (resultcategory) =>
          resultcategory.json().then((obj) => {
            if (
              resultcategory.status === 200 ||
              resultcategory.status === 201
            ) {
              setStateList(obj.data);
              const storedStateId = localStorage.getItem("stateId");
              if (storedStateId) {
                setStateId(storedStateId);
                getCity(storedStateId);
              } else if (basicInfoData.vendorState) {
                getCity(basicInfoData.vendorState);
              }
            } else {
              console.log("Failed to fetch states");
            }
          })
      );
    };

    const getCity = (stateId) => {
      PostApiCall.postRequest({ stateid: stateId }, "Get_City").then(
        (resultcategory) =>
          resultcategory.json().then((obj) => {
            if (
              resultcategory.status === 200 ||
              resultcategory.status === 201
            ) {
              setCityList(obj.data);
              const storedCityId = localStorage.getItem("cityId");
              if (storedCityId) {
                setCityId(storedCityId);
              }
            } else {
              console.log("Failed to fetch city data");
            }
          })
      );
    };

    // for vendor type
    const handleVendorTypeChange = (e) => {
      const value = e.target.value;
      const selectedCustomertypes = [...basicInfoData.selectedCustomertypes]; // empty array

      if (selectedCustomertypes.includes(value)) {
        // If already selected, remove it
        const updatedVendorTypes = selectedCustomertypes.filter(
          (type) => type !== value
        ); // array after removal
        onHandleUpdate({
          ...basicInfoData,
          selectedCustomertypes: updatedVendorTypes,
        });
      } else {
        // If not selected, add it
        selectedCustomertypes.push(value);
        onHandleUpdate({ ...basicInfoData, selectedCustomertypes });
      }
    };

    // fetch countries for the first time
    useEffect(() => {
      getCountry();
    }, []);

    useEffect(() => {
      if (countryId) {
        getState(countryId);
      }
    }, [countryId]);

    useEffect(() => {
      if (stateId) {
        getCity(stateId);
      }
    }, [stateId]);

    useEffect(() => {
      if (countryId) {
        localStorage.setItem("countryId", countryId);
      }
    }, [countryId]);

    useEffect(() => {
      if (stateId) {
        localStorage.setItem("stateId", stateId);
      }
    }, [stateId]);

    useEffect(() => {
      if (cityId) {
        localStorage.setItem("cityId", cityId);
      }
    }, [cityId]);

    return (
      <section>
        {/* Basic Information */}
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col-12">
              <h2 className="text-center mb-4">
                {/* Basic Information */}
                Basic Information
              </h2>
            </div>
            <div className="col-12 col-md-6">
              <label className="mb-2">
                Organisation Name (as per Company / Trade License / GST
                Registration) <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="Name"
                name="name"
                value={basicInfoData.orgName}
                onChange={(e) => handleFieldChange("orgName", e.target.value)}
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="mb-2">
                Company Registration / Trade License Number
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="Name"
                name="name"
                value={basicInfoData.companyregistration}
                onChange={(e) =>
                  handleFieldChange("companyregistration", e.target.value)
                }
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="mb-3">
                <label htmlFor="gstCertificateFile" className="form-label">
                  Company Registration / Trade License file upload{" "}
                  <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="file"
                    className="form-control h-100 d-block poel-vendor-input poel-file"
                    placeholder="Choose File*"
                    aria-label="File"
                    id="file"
                    name="File"
                    onChange={onFileChangeTradelicense}
                  />
                </div>
                <p>File name : {selectedTradeLicense[0]?.name}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <label className="mb-2">Importer / Exporter Code</label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="Name"
                name="name"
                value={basicInfoData.exportCode}
                onChange={(e) =>
                  handleFieldChange("exportCode", e.target.value)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label className="mb-0 pb-0">
                <h4>
                  Address <span className="text-danger">*</span>
                </h4>
              </label>
            </div>
            <div className="col-lg-6 mb-3">
              <label className="mb-2">Street Address</label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="Address"
                name="address"
                value={basicInfoData.streetAddress}
                onChange={(e) =>
                  handleFieldChange("streetAddress", e.target.value)
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="mb-2">Street Address Line 2</label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="Addresstwo"
                name="addresstwo"
                value={basicInfoData.streetAddress2}
                onChange={(e) =>
                  handleFieldChange("streetAddress2", e.target.value)
                }
              />
            </div>

            <div className="col-12 col-md-6 mb-3">
              <label className="mb-2">Country</label>
              <div class="input-group">
                <select
                  className="form-select bg-white select reason-option inputselect poel-vendor-input"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  value={basicInfoData.vendorCountryName}
                  key={basicInfoData.vendorCountryName}
                  onChange={(e) => {
                    setCountryId(e.target.options[e.target.selectedIndex].id);
                    // handleFieldChange("vendorCountry", (e.target.options[e.target.selectedIndex]).value);
                    handleFieldChange("vendorCountryName", e.target.value);
                    getState(e.target.options[e.target.selectedIndex].id);
                  }}
                >
                  {/* <option>Please Select...</option> */}
                  {countryList?.map((country) => (
                    <option
                      id={country.value}
                      key={country.value}
                      value={country.label}
                    >
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <label className="mb-2">State</label>
              <div class="input-group">
                <select
                  className="form-select bg-white select reason-option inputselect poel-vendor-input"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  value={basicInfoData.vendorStateName}
                  onChange={(e) => {
                    setStateId(e.target.options[e.target.selectedIndex].id);
                    // handleFieldChange("vendorState", (e.target.options[e.target.selectedIndex]).value);
                    handleFieldChange("vendorStateName", e.target.value);
                    getCity(e.target.options[e.target.selectedIndex].id);
                  }}
                >
                  <option>Please Select...</option>
                  {stateList?.map((state) => (
                    <option
                      key={state.value}
                      id={state.value}
                      value={state.label}
                    >
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label className="mb-2">City</label>
              <div class="input-group">
                <select
                  className="form-select bg-white select reason-option inputselect poel-vendor-input"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  value={basicInfoData.vendorCity}
                  onChange={(e) =>
                    handleFieldChange("vendorCity", e.target.value)
                  }
                >
                  <option>Please Select...</option>
                  {cityList?.map((city) => (
                    <option key={city.value} value={city.label}>
                      {city.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <label className="mb-2">Zip Code</label>
              <input
                type="text"
                className="form-control poel-vendor-input poel-vendor-input"
                id="Zip"
                name="zip"
                value={basicInfoData.zipCode}
                onChange={(e) => handleFieldChange("zipCode", e.target.value)}
              />
            </div>

            {/* vendor types checkboxes */}
            <div className="col-12 mb-3">
              <label>Customer Type</label> <span className="text-danger">*</span>
              <div className="d-md-flex">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Goods"
                    id="defaultCheck1"
                    checked={basicInfoData.selectedCustomertypes.includes(
                      "Goods"
                    )}
                    onChange={handleVendorTypeChange}
                  />
                  <label class="form-check-label" for="defaultCheck1">
                    Goods
                  </label>
                </div>
                <div class="form-check ms-md-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Service"
                    id="defaultCheck1"
                    checked={basicInfoData.selectedCustomertypes.includes(
                      "Service"
                    )}
                    onChange={handleVendorTypeChange}
                  />

                  <label class="form-check-label" for="defaultCheck1">
                    Services
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <label className="mb-2">
                Purchase Contact Person Name{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="ContactPerson"
                name="contactperson"
                value={basicInfoData.contactPerson}
                onChange={(e) =>
                  handleFieldChange("contactPerson", e.target.value)
                }
              />
            </div>
            <div className="col-12 col-md-4 mb-3">
              <label>
                Purchase Contact Person Phone No{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="ContactPhone"
                name="contactphone"
                // maxLength={10}
                value={basicInfoData.contactPhone}
                required
                onChange={(e) =>
                  handleFieldChange("contactPhone", e.target.value.replace(/\D/g, ""))
                }
              />
              <span className="mt-2">Please enter a valid phone number.</span>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <label>
                Purchase Contact Person Email{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control poel-vendor-input"
                id="ContactEmail"
                name="contactemail"
                value={basicInfoData.contactEmail}
                required
                onChange={(e) =>
                  handleFieldChange("contactEmail", e.target.value)
                }
              />
              <span className="mt-2">example@example.com</span>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-lg-4">
              <label className="mb-2">
                Accounts Contact Person Name{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="AccountPerson"
                name="accountperson"
                value={basicInfoData.accountPerson}
                onChange={(e) =>
                  handleFieldChange("accountPerson", e.target.value)
                }
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <label className="mb-2">
                Accounts Contact Person Phone No{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input mb-2"
                id="AccountPhone"
                name="accountphone"
                value={basicInfoData.accountPhone}
                onChange={(e) =>
                  handleFieldChange("accountPhone", e.target.value.replace(/\D/g, ""))
                }
              />
              <span>Please enter a valid phone number</span>
            </div>

            <div className="col-md-6 col-lg-4">
              <label className="mb-2">
                Accounts Contact Person Email{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control poel-vendor-input mb-2"
                id="AccountEmail"
                name="accountemail"
                value={basicInfoData.accountEmail}
                onChange={(e) =>
                  handleFieldChange("accountEmail", e.target.value)
                }
              />
              <span>example@example.com</span>
            </div>
            <div className="col-12 mb-5 text-end mt-4">
              {basicInfoData.vendorCountryName == "India" ?

                <button
                  className="enquirybtnvendor text-white fs-6"
                  type="button"
                  onClick={() => {
                    setActiveTab(
                      basicInfoData.vendorCountryName == "India"
                        ? "gst"
                        : "bankDetails"
                    );
                  }}
                >
                  Next
                </button> :
                <button
                  className="enquirybtnvendor text-white fs-6"
                  type="button"
                  onClick={() => { onSubmit() }}>
                  Submit
                </button>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
);
export default BasicInformation;
