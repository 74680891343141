import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearch: false };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }
  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }
  /**
   * Toggles the sidebar
   */
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <header id="page-topbar" className='gtrendz-header'>
          <div className="navbar-header">
            <div className="d-flex">
              <h3 class="CompanyName">Enquiry Management System</h3>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light "
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <div
                  className={this.state.isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t('Search') + "..."}
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};
export default connect(mapStatetoProps, null)(withNamespaces()(Header));
