import imageCompression from "browser-image-compression";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { MdDelete } from "react-icons/md";
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
import ImageUploadIcon from "../../assets/images/upload.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload-blog w-100 p-0 m-0 fas">
    <div className="img-wrap-blog img-upload">
      <img
        htmlFor="photo-upload"
        className="custom-file-upload-image-blog"
        src={src}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

class AddBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Photo: "",
      BlogTitle: "",
      BlogCreatedBy: "",
      ShortDescription: "",
      LongDescription: "",
      BlogID: "",
      Status: "Active",
      outputFileName: "",
      imagePreviewUrl: ImageUploadIcon,
      ImageApiUrl: "https://globaltrendz.info/gt-api/" + "AddImage",
      // ImageApiUrl: "http://localhost:8088/gt-api/" + "AddImage",
      originalImage: "",
      RandomNumber: Math.floor(100000 + Math.random() * 900000),
      BlogComments: [], // to be mapped for comment list
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    this.setState({
      BlogID:
        this.props.location.state === undefined
          ? 0
          : this.props.location.state.blogid,
    });
    if (this.props.sProductId != "") {
      this.getBlogdetails();
    }
  }

  // route back handler
  handleNavigate = () => {
    this.props.history.push("/blog-list");
  };

  // get a specific blog for a user
  getBlogdetails = () => {
    Notiflix.Loading.Dots()
    PostApiCall.postRequest(
      {
        whereClause: `WHERE fld_id=${this.props.location.state === undefined
          ? 0
          : this.props.location.state.blogid
          }`,
        recordCount: "*",
      },
      "GetBlogArticleById"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        // Notiflix.Loading.Dots("Please Wait...");
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            this.setState({
              BlogTitle: obj.data[0].fld_title,
              BlogCreatedBy: obj.data[0].fld_blog_by,
              ShortDescription: obj.data[0].fld_short_description,
              LongDescription: obj.data[0].fld_content,
              Photo: obj.data[0].fld_blogimage,
              Status: obj.data[0].fld_status,
            });
            Notiflix.Loading.Remove();
          } else {
            this.setState({
              Photo: ImageUploadIcon,
            });
          }
        }
        Notiflix.Loading.Remove();
      })
    );
  };

  //upload image start
  onUploadImage = () => {
    Notiflix.Loading.Dots("Uploading Image");
    let response;
    if (this.state.outputFileName != undefined || this.state.outputFileName != "") {
      var options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(this.state.originalImage, options).then(
        (compressedFile) => {
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "Blogs");
          form.append(
            "filename",
            this.state.RandomNumber + "-" + compressedFile.name
          );


          response = fetch(this.state.ImageApiUrl, {
            method: "POST",
            body: form,
          })
            .then((response) => response.json())
            .then((data) => {
              Notiflix.Loading.Remove();
              this.getBlogdetails();
              Notiflix.Notify.Success("Blog image is successfully added");
            });
        }
      );
    } else {
      Notiflix.Loading.Remove();
      Notiflix.Notify.Failure("Please Upload Blog Image");
    }
  };
  //upload image end

  // save blog handler
  onPostBlogData = () => {
    // Notiflix.Loading.Dots("Please Wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    Notiflix.Loading.Dots();
    PostApiCall.postRequest(
      {
        blogid: this.state.BlogID,
        blogtitle: this.state.BlogTitle,
        blogcreatedby: this.state.BlogCreatedBy,
        shortdescription: this.state.ShortDescription,
        longdescription: this.state.LongDescription,
        stockInHand: this.state.StockInHand,
        status: this.state.Status,
        // blogimage: "https://globaltrendz.info/Images/Blogs/" +   this.state.RandomNumber + "-" +
        //   this.state.outputFileName.split(".").slice(0, -1).join(".") + ".webp",
        blogimage: this.state.outputFileName == "" ? this.state.Photo : "https://globaltrendz.info/Images/Blogs/" + this.state.RandomNumber + "-" +
          this.state.outputFileName.split(".").slice(0, -1).join(".") + ".webp",
      },
      "AddBlog"
    ).then((blogresponse) =>
      blogresponse.json().then((obj1) => {
        if (blogresponse.status == 200 || blogresponse.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Blog Details Successfully Added");
          this.handleNavigate(); // route back to the list
          if (this.state.outputFileName != "") {
            this.onUploadImage(); // upload image after details are saved
          }
          Notiflix.Loading.Remove();
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj1.data);
        }
      })
    );
  };


  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content pb-0">
          <Container fluid>
            <Breadcrumbs
              title={"Global Trendz"}
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              )
                .replace("-", " ")
                .replace("_", " ")}
              urlPath={"/customer-management"}
            />
            <div className="row">
              <div className="col-12">
                <Card>
                  <CardBody className="pt-0 mt-4">
                    <Media>
                      <Media body className="w-100">
                        <Row>
                          <Col xl="12">
                            <Card className="overflow-hidden">
                              <div className="gt-enquiry-bg-soft-pink">
                                <Row>
                                  <Col xs="6">
                                    <div className="gt-enquiry-text p-3">
                                      <h5 className="gt-enquiry-text text-white">
                                        {this.state.BlogID != ""
                                          ? "Update Blog"
                                          : "Add New Blog"}
                                      </h5>
                                    </div>
                                  </Col>
                                  <Col
                                    sm="6"
                                    className="px-0 mb-0 check-box-blod-add text-right"
                                  >
                                    <h5 className="gt-enquiry-text text-white mr-4 mb-0 pb-0">
                                      <Form>
                                        <Form.Check
                                          type="switch"
                                          checked={
                                            this.state.Status === "Active"
                                              ? true
                                              : false
                                          }
                                          id="custom-switch"
                                          label="Active"
                                          value={this.state.Status}
                                          onChange={(e) => {
                                            this.state.Status === "Active"
                                              ? this.setState({
                                                Status: "InActive",
                                              })
                                              : this.setState({
                                                Status: "Active",
                                              });
                                          }}
                                        />
                                      </Form>
                                    </h5>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <Container fluid>
                              <Row>
                                <Col xl="12">
                                  <Row>
                                    <Col xs="12">
                                      <div className="form my-0">
                                        <div className="row">
                                          <div className="col-12 col-sm-5">
                                            <div className="form-group">
                                              <label htmlFor="sw-arrows-first-name">
                                                Blog Image
                                                <span className="mandatory">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            (1372px*300px)
                                            <div className="best-products position-relative my-3 p-0 mx-0">
                                              <div className="img-container add-blog-border">
                                                <ImgUpload
                                                  onChange={(e) => {
                                                    e.preventDefault();
                                                    const imageFile =
                                                      e.target.files[0];
                                                    this.setState({
                                                      Photo:
                                                        URL.createObjectURL(
                                                          imageFile
                                                        ),
                                                      originalImage: imageFile,
                                                      outputFileName:
                                                        imageFile.name,
                                                    });
                                                  }}
                                                  src={this.state.Photo}
                                                />
                                                <span
                                                  className="blog-delete-icon"
                                                  onClick={() => {
                                                    this.setState(
                                                      {
                                                        ImageUrl:
                                                          ImageUploadIcon,
                                                      },
                                                      () => {
                                                        confirmAlert({
                                                          title:
                                                            "Confirm to Delete",
                                                          message:
                                                            "Are you sure you want to delete the image?",
                                                          buttons: [
                                                            {
                                                              label: "Yes",
                                                              onClick: () => {
                                                                // Notiflix.Loading.Dots("");
                                                                PostApiCall.postRequest(
                                                                  {
                                                                    blogid:
                                                                      this.state
                                                                        .BlogID,
                                                                  },
                                                                  "DeleteBlogImage"
                                                                ).then(
                                                                  (results) =>
                                                                    // const objs = JSON.parse(result._bodyText)
                                                                    results
                                                                      .json()
                                                                      .then(
                                                                        (
                                                                          obj
                                                                        ) => {
                                                                          if (
                                                                            results.status ==
                                                                            200 ||
                                                                            results.status ==
                                                                            201
                                                                          ) {
                                                                            Notiflix.Loading.Remove();
                                                                            Notiflix.Notify.Success(
                                                                              "Image successfully deleted."
                                                                            );
                                                                          } else {
                                                                            Notiflix.Loading.Remove();
                                                                            Notiflix.Notify.Failure(
                                                                              "Something went wrong, try again later."
                                                                            );
                                                                          }
                                                                        }
                                                                      )
                                                                );
                                                              },
                                                            },
                                                            {
                                                              label: "No",
                                                              // onClick: () => alert('Click No')
                                                            },
                                                          ],
                                                        });
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <span className="material-symbols-outlined">
                                                    {/* conditionally show the delete icon */}
                                                    {this.state
                                                      .imagePreviewUrl !==
                                                      ImageUploadIcon &&
                                                      <MdDelete className="fs-4" />
                                                    }
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            <div className="row">
                                              <div className="col-12">
                                                <div className={"form-group "}>
                                                  <label htmlFor="BlogTitle">
                                                    Blog Title
                                                  </label>
                                                  <input
                                                    type="text"
                                                    id="BlogTitle"
                                                    className="form-control"
                                                    value={this.state.BlogTitle}
                                                    onChange={(text) => {
                                                      this.setState({
                                                        BlogTitle:
                                                          text.target.value,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label htmlFor="BlogCreatedBy">
                                                    Blog Written By
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    id="BlogCreatedBy"
                                                    className="form-control"
                                                    value={
                                                      this.state.BlogCreatedBy
                                                    }
                                                    onChange={(text) => {
                                                      this.setState({
                                                        BlogCreatedBy:
                                                          text.target.value,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col col-12">
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label htmlFor="sw-arrows-first-name">
                                                    Short Description
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <div className="niceeditors">
                                                    <CKEditor
                                                      dataProcessor={(data) =>
                                                        data.replace(
                                                          /<p><\/p>/g,
                                                          ""
                                                        )
                                                      }
                                                      config={{
                                                        extraPlugins:
                                                          "justify,font,colorbutton",
                                                        autoParagraph: false,
                                                      }}
                                                      data={
                                                        this.state
                                                          .ShortDescription
                                                      }
                                                      onChange={(text) => {
                                                        this.setState({
                                                          ShortDescription:
                                                            text.editor.getData(),
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label htmlFor="sw-arrows-first-name">
                                                    Blog Content
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <div className="niceeditors">
                                                    <CKEditor
                                                      dataProcessor={(data) =>
                                                        data.replace(
                                                          /<p><\/p>/g,
                                                          ""
                                                        )
                                                      }
                                                      config={{
                                                        extraPlugins:
                                                          "justify,font,colorbutton",
                                                        autoParagraph: false,
                                                      }}
                                                      data={
                                                        this.state
                                                          .LongDescription
                                                      }
                                                      onChange={(text) => {
                                                        this.setState({
                                                          LongDescription:
                                                            text.editor.getData(),
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-12">
                                                <button
                                                  style={{
                                                    float: "right",
                                                    marginTop: "5px",
                                                  }}
                                                  onClick={() => {
                                                    if (this.state.BlogTitle != "") {
                                                      if (this.state.BlogCreatedBy != "") {
                                                        if (this.state.ShortDescription != "") {
                                                          if (this.state.LongDescription != "") {
                                                            {
                                                              this.onPostBlogData();
                                                            }
                                                          }
                                                          else {
                                                            Notiflix.Notify.Failure(
                                                              "Please enter blog content"
                                                            );
                                                          }
                                                        }
                                                        else {
                                                          Notiflix.Notify.Failure(
                                                            "Please Enter Short Description"
                                                          );
                                                        }
                                                      }
                                                      else {
                                                        Notiflix.Notify.Failure(
                                                          "Please Enter Written By"
                                                        );
                                                      }
                                                    }
                                                    else {
                                                      Notiflix.Notify.Failure(
                                                        "Please Enter Blog Title"
                                                      );
                                                    }
                                                  }}
                                                  className="btn align-items-center Bechofy-btn p-2 bg-dark text-white"
                                                >
                                                  {this.state.BlogID != ""
                                                    ? "Update Blog"
                                                    : "Save Blog"}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddBlog;
