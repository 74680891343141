import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./rooby.css";
const RoobyFoodCareer = () => {
  const [mailType, setType] = useState("");
  const [clientid, setClientid] = useState("");
  const mailtype = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Career, setCareer] = useState({
    name: "",
    email: "",
    message: "",
    Job_Position: "",
    mobile: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function RoobyFoodEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Ruby Foods Career</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: auto; margin: auto;border: 1px solid grey;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left; border-bottom: 1px dotted #ef1a24;padding: 10px 0px 10px 0px;margin: 0px 10px 0px 10px;">' +
      '<img style="width: 130px" src="https://rubyfood.com/images/logo-1.png"/>' +
      "</div>" +
      '<div style="padding: 20px">' +
      '<h4 style="text-align: left">Dear Ruby Foods, </h4>' +
      '<h4 style="font-weight:100">You have received an <b> job application </b> from the website. The details are as follows.</h4>' +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Full Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Job Position</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.Job_Position +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Career.message +
      "</div>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);
  useEffect(() => {
    setType(mailtype.mailtype);
    setClientid(mailtype.clientid);
  }, []);
  return (
    <div className="container-fluid form-border px-5 pt-5">
      <form className="row needs-validation">
        <div className="col-lg-6 col-12 mb-3">
          <input
            type="text"
            className="form-control ps-4"
            placeholder="Full Name *"
            id="Name"
            name="name"
            value={Career.name}
            onChange={(e) =>
              setCareer({ ...Career, [e.target.name]: e.target.value })
            }
            style={{ backgroundColor: 'rgb(234, 234, 234)' }}
          />
        </div>
        <div className="col-lg-6 col-12 mb-3">
          <input
            type="text"
            className="form-control ps-4"
            placeholder="Job Position"
            style={{ backgroundColor: 'rgb(234, 234, 234)' }}
            id="job_position"
            name="Job_Position"
            value={Career.Job_Position}
            onChange={(e) =>
              setCareer({ ...Career, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-lg-6 col-12 mb-3">
          <input
            type="text *"
            className="form-control ps-4"
            placeholder="Mobile Number *"
            style={{ backgroundColor: 'rgb(234, 234, 234)' }}
            id="MobileNumber"
            name="mobile"
            value={Career.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setCareer({
                  ...Career,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-lg-6 col-12 mb-3">
          <input
            type="email"
            className="form-control ps-4"
            placeholder="Email"
            style={{ backgroundColor: 'rgb(234, 234, 234)' }}
            id="email_id"
            name="email"
            value={Career.email}
            onChange={(e) =>
              setCareer({ ...Career, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <textarea
            className="form-control ps-4"
            placeholder="Message"
            style={{ backgroundColor: 'rgb(234, 234, 234)' }}
            id="message"
            name="message"
            value={Career.message}
            onChange={(e) =>
              setCareer({ ...Career, [e.target.name]: e.target.value })
            }
          ></textarea>
        </div>

        <div className="col-12 mb-3">
          <div className="row">
            <div className="col-12 col-sm-6">
              <p
                className="px-4 py-2 mb-2 border-dotted captcha-copy-disable d-flex justify-content-between captcharooby"
              >
                {captcha_number}
                <span
                  onClick={() => {
                    setRotatecaptcha("iorotatevig");
                    Genratecaptcha();
                  }}
                  className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
                >
                  <IoSync className="aButtonvig rotate" />
                </span>
              </p>
            </div>
            <div className="col-12 col-sm-6">
              <input
                type="captcha"
                className="form-control captcharooby ps-4"
                placeholder="Enter Captcha *"
                name="captcha"
                value={Career.captcha}
                onChange={(e) =>
                  setCareer({ ...Career, [e.target.name]: e.target.value })
                } />
            </div>
          </div>


        </div>

        <div className="col-lg-6 col-12  mb-3 d-flex justify-content-center">

        </div>
        <div className="col-lg-6 col-12  mb-3 d-flex justify-content-center">
          <button
            type="button"
            className="btn w-lg-25 w-100"
            style={{ backgroundColor: '#ef1a24', color: '#fff' }}
            onClick={() => {
              if (Career.name != "") {
                if (
                  Career.email == "" ||
                  Career.EmailRegex.test(Career.email)
                ) {
                  if (Career.mobile != "") {
                    if (Career.mobile.length == 10) {
                      if (
                        Career.captcha.toString() == captcha_number.toString()
                      ) {
                        const htmlMail = RoobyFoodEnquiry_formate();
                        Notiflix.Loading.Arrows("Please wait...");
                        PostApiCall.postRequest(
                          {
                            mailtype: mailType,
                            mail: htmlMail,
                            clientid: clientid,
                            name: Career.name,
                            mobile: Career.mobile,
                            email: Career.email,
                            message: Career.message,
                          },
                          "NewEnquiryMailerApi"
                          // "jobapplications"
                        ).then((results2) =>
                          results2.json().then((obj2) => {
                            if (results2.status == 200 || results2.status == 201) {
                              Notiflix.Notify.Success("Thank you, our team will contact you shortly!");
                              setTimeout(() => {
                                window.location.reload();
                              }, 3000);
                            }
                            else (
                              Notiflix.Loading.Remove()
                            )
                          })
                        );
                      }
                      else {
                        Notiflix.Notify.Failure("Please enter valid captcha");
                      }
                    }
                    else {
                      Notiflix.Notify.Failure("Please enter valid mobile number");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter mobile number");
                  }
                }
                else {
                  Notiflix.Notify.Failure("Please enter valid email");
                }
              }
              else {
                Notiflix.Notify.Failure("Please enter name");
              }
            }}
          >
            Submit
          </button>
        </div>
      </form> 
    </div>
  );
};
export default RoobyFoodCareer;
