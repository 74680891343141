import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "../Rayyanhotel/Rayyan.css";
import "./vignesh.css";
const VigneshCabsEnquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function VigneshCabsEnquiryEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Vignesh Cabs | ENQUIRY</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: 100%; margin: auto">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 30%" src="https://globaltrendz.online/vigneshcabs/images/Vignesh-Cabs-Logo.png"/>' +
      "</div>" +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Mam, </h2>' +
      "<p style='font-size:14px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.message +
      "</div>" +
      "</div>" +
      "<br>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div
      className="container-fluid form-border"
      style={{ background: "#4a483c", height: "100vh" }}
    >
      <form className="row needs-validation">
        <div className="col-md-6 mb-2 px-0 col-lg-6">
          <input
            type="text"
            placeholder="Name *"
            className="form-control inputborderradius"
            id="Name"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-2 px-0 col-lg-6">
          <input
            type="email"
            placeholder="Email"
            className="form-control inputborderradius"
            id="email_id"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-2 px-0 col-lg-6">
          <input
            type="text"
            placeholder="Mobile Number *"
            className="form-control inputborderradius"
            id="MobileNumber"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-md-6 mb-2 px-0 col-lg-6">
          <textarea
            className="form-control inputborderradius"
            placeholder="Message"
            id="Message"
            name="message"
            value={Enquiry.message}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          ></textarea>
        </div>
        <div className="col-md-6 mb-2 px-0 col-lg-6 captchdisplay">
          <p
            className="px-2 py-2 mb-2 me-2 border-dotted captchvaluesize inputborderradius captcha-copy-disable d-flex justify-content-between"
            style={{
              color: "#495057",
              background: "#ffffffe0",
              border: "1px solid #80808000",
            }}
          >
            {captcha_number}
            <span
              onClick={() => {
                setRotatecaptcha("iorotatevig");
                Genratecaptcha();
              }}
              className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
            >
              <IoSync className="aButtonvig rotate" />
            </span>
          </p>
          <input
            type="captcha"
            className="form-control border captchvaluesize inputborderradius"
            placeholder="Enter Captcha *"
            name="captcha"
            value={Enquiry.captcha}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-4 px-0">
          <button
            className="btn w-100 vignessendbtn"
            type="button"
            onClick={() => {
              if (Enquiry.name != "") {
                if (
                  Enquiry.email == "" ||
                  Enquiry.EmailRegex.test(Enquiry.email)
                ) {
                  if (Enquiry.mobile != "") {
                    if (Enquiry.mobile.length == 10) {
                      if (
                        Enquiry.captcha.toString() == captcha_number.toString()
                      ) {
                        {
                          setLoader(true);
                          const htmlMail = VigneshCabsEnquiryEnquiry_formate();
                          PostApiCall.postRequest(
                            {
                              mailtype: "from Vignesh Cabs Website",
                              mail: htmlMail,
                              clientid: clientid,
                              name: Enquiry.name,
                              mobile: Enquiry.mobile,
                              email: Enquiry.email,
                              message: Enquiry.message,
                            },
                            "NewEnquiryMailerApi"
                          ).then((results2) =>
                            results2.json().then((obj2) => {
                              if (
                                results2.status == 200 ||
                                results2.status == 201
                              ) {
                                setLoader(false);
                                Notiflix.Notify.Success(
                                  "Thank you, our team will contact you shortly!"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 3000);
                                // window.location.reload();
                              }
                            })
                          );
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter valid captcha");
                      }
                    } else {
                      Notiflix.Notify.Failure(
                        "Please enter valid mobile number"
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter mobile number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter valid email");
                }
              } else {
                Notiflix.Notify.Failure("Please enter name");
              }
            }}
          >
            {Loader == true ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              "Send"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
export default VigneshCabsEnquiry;
