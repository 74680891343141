import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Media, Row } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import avatar from '../../assets/images/users/avatar-1.jpg';
import Breadcrumb from '../../components/Common/Breadcrumb';
class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = { email: "", name: "", idx: 1 }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    componentDidMount() {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
                this.setState({ name: obj.displayName, email: obj.email, idx: obj.uid });
            }
            else if ((process.env.REACT_APP_DEFAULTAUTH === 'fake') || (process.env.REACT_APP_DEFAULTAUTH === 'jwt')) {
                this.setState({ name: obj.username, email: obj.email, idx: obj.uid });
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            if (obj) {
                this.setState({ name: obj.username });
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Skote" breadcrumbItem="Profile" />
                        <Row>
                            <Col lg="12">
                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                <Card>
                                    <CardBody>
                                        <Media>
                                            <div className="mr-3">
                                                <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                            </div>
                                            <Media body className="align-self-center">
                                                <div className="text-muted">
                                                    <h5>{this.state.name}</h5>
                                                    <p className="mb-1">{this.state.email}</p>
                                                    <p className="mb-0">Id no: #{this.state.idx}</p>
                                                </div>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
    const { error, success } = state.Profile;
    return { error, success };
}
export default withRouter(connect(mapStatetoProps, {  })(UserProfile));
