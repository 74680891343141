import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./cleankart.css";
import { IoSync } from "react-icons/io5";
import { HiPlusSm } from "react-icons/hi";
const Cleanroomkartenquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    companyname:"",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);

  function cleanroomkartenquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Cleanroom Kart | ENQUIRY</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%;background-color: #8080800d">' +
      '<div style="text-align: left; padding: 10px 0 0;">' +
      '<img style="width: 100px,height:100px" src="https://cleanroom.co.in/img/logo/logo.png"/>' +
      "</div>" +
      '<div style="padding: 0 20px 5px;" >' +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Mam, </h2>' +
      "<p style='font-size:14px;color: black;'>Greetings! you have received an enquiry via the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black;font-size:14px">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size:14px">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black;font-size:14px">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size:14px">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black;font-size:14px">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size:14px">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black;font-size:14px">Company Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size:14px">' +
      Enquiry.companyname +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black;font-size:14px">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size:14px">' +
      Enquiry.message +
      "</div>" +
      "</div>" +
      "<h3 style='text-align: left;font-size:15px;color: #000;margin-top:20px;font-size:14px;font-weight:400'>Thank you</h3>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 900000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div
      className="container-fluid form-border py-2"
      style={{
        background: "white",
        height: "100vh",
      }}
    >
      {/* React Helmet to add noscript iframe to the head of the document */}
      <Helmet>
        {/* Google Tag Manager (noscript) */}
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MVF9PQMC" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        />
        {/* End Google Tag Manager (noscript) */}
      </Helmet>

      <form className="contact-form custom-form-style-1">
        <div className="row row-gutter-sm">
          <div className="form-group col-lg-6 col-md-4 col-12 mb-2">
            <input
              type="text"
              className="form-control cleaninput"
              name="name"
              placeholder="Name *"
              value={Enquiry.name}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-6 col-md-4 col-12 mb-2">
            <input
              type="text"
              className="form-control cleaninput"
              name="mobile"
              placeholder="Mobile Number *"
              value={Enquiry.mobile}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setEnquiry({
                    ...Enquiry,
                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                  });
              }}
            />
          </div>
          <div className="form-group col-lg-6 col-md-4 col-12 mb-2">
            <input
              type="email"
              className="form-control cleaninput"
              placeholder="Email Id"
              name="email"
              value={Enquiry.email}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>

          <div className="form-group col-lg-6 col-md-4 col-12 mb-2">
            <input
              type="text"
              className="form-control cleaninput"
              placeholder="Company Name"
              name="companyname"
              value={Enquiry.companyname}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col mt-2 mt-md-0 pt-0 mb-2">
            <textarea
              maxLength="5000"
              rows="5"
              className="form-control messageinputclean"
              placeholder="Your Message *"
              name="message"
              value={Enquiry.message}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            ></textarea>
          </div>
        </div>

        <div className="col-md-12 mt-2 mt-md-0 pt-0 mb-0 px-0 col-lg-12 captchdisplay">
          <p className="me-2 d-flex justify-content-between captchvaluesize captcha-copy-disable captcha-number-size-clean-room-kart">
            {captcha_number}
            <span
              onClick={() => {
                setRotatecaptcha("iorotate");
                Genratecaptcha();
              }}
              className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
            >
              <IoSync className="aButtoncleanroomkart rotatemani clean-room-kart-captcharotate-icon" />
            </span>
          </p>
          <input
            type="captcha"
            className="form-control captchvaluesize captchafont captchabg-cleanroomkart"
            placeholder="Enter Captcha Code *"
            name="captcha"
            value={Enquiry.captcha}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>

        <div className="row">
          <div className="form-group col mb-0">
            <button
              className="btn btn-primary btn-modern fw-bolder text-3 px-4 py-3 btnprimarycleanroomkart"
              type="button"
              onClick={() => {
                if (Enquiry.name != "") {
                  if (
                    Enquiry.email == "" ||
                    Enquiry.EmailRegex.test(Enquiry.email)
                  ) {
                    if (Enquiry.mobile != "") {
                      if (Enquiry.mobile.length == 10) {
                        if (Enquiry.message != "") {
                          if (
                            Enquiry.captcha.toString() ==
                            captcha_number.toString()
                          ) {
                            setLoader(true);
                            const htmlMail = cleanroomkartenquiry_formate();
                            PostApiCall.postRequest(
                              {
                                mailtype: "from Website",
                                mail: htmlMail,
                                clientid: clientid,
                                name: Enquiry.name,
                                mobile: Enquiry.mobile,
                                email: Enquiry.email,
                                message: Enquiry.message,
                                companyname:Enquiry.companyname
                              },
                              "NewEnquiryMailerApi"
                            ).then((results2) =>
                              results2.json().then((obj2) => {
                                if (
                                  results2.status == 200 ||
                                  results2.status == 201
                                ) {
                                  setLoader(false);
                                  Notiflix.Notify.Success(
                                    "Thank you, our team will contact you shortly!"
                                  );
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 3000);
                                  // window.location.reload();
                                }
                              })
                            );
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter valid captcha"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter message");
                        }
                      } else {
                        Notiflix.Notify.Failure(
                          "Please enter valid mobile number"
                        );
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter mobile number");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid email");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter name");
                }
              }}
            >
              {Loader == true ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <HiPlusSm className="me-3 plus-clean-room-kart" /> SEND
                  MESSAGE
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Cleanroomkartenquiry;
