import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import moment from "moment";
import PostApiCall from "../../Api";
import profileImg from "../../assets/images/profile-img.png";
import facebook from './socialicon/fb.png';
import instagram from './socialicon/insta.png';
import linkedin from './socialicon/linkedin.png';
import youtube from './socialicon/youtube.png';
class WelcomeComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CompanyData: []
        };
    }
    componentDidMount() {
        var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
        PostApiCall.postRequest(
            {
                WhereClause: `where FLD_CLIENT_ID=${loginDetails[0].fld_userid}`,
            },
            "getEnquiryData"
        ).then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState(
                    {
                        CompanyData: obj.data,
                    }
                );
            })
        );
    }
    render() {
        return (
            <React.Fragment>
                <Card className="overflow-hidden">
                    <div className="gtrendz-bg-soft-pink">
                        <Row>
                            <Col xs="7">
                                <div className="gtrendz-text p-3">
                                    <h5 className="gtrendz-text">Welcome Back !</h5>
                                    {this.state.CompanyData.map((data, i) => {
                                        if (i === 0) {
                                            return (<p>{data.client_name}</p>
                                            )
                                        }
                                    })}
                                </div>
                            </Col>
                            <Col xs="5" className="align-self-end">
                                <img src={profileImg} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                    <CardBody className="pt-0">
                        <Row>
                            {this.state.CompanyData.map((data, i) => {
                                if (i === 0) {
                                    return (
                                        <Col sm="5">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <img src={data.fld_logo} alt="" className="img-thumbnail rounded-circle" />
                                            </div>
                                            <h5 className="font-size-15 text-truncate">{data.client_name}</h5>
                                        </Col>
                                    )
                                }
                            })}
                            <Col sm="7">
                                <div className="pt-4">
                                    <div className="mt-5">
                                        <Row>
                                            <Col xs="8">
                                                <Link to="" className="gtrendz-muted-text">last seen <br /> {moment().format('lll')}</Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12">
                                <div>
                                    <ul className="socialicons">
                                        {this.state.CompanyData.map((data, i) => {
                                            if (i === 0) {
                                                return (
                                                    <>
                                                        <li>
                                                            <a href={data.fld_facebook_page} target="_blank"> <img src={facebook} className="socialimg"></img></a>
                                                        </li>
                                                        <li>
                                                            <a href={data.fld_instagram_page} target="_blank"> <img src={instagram} className="socialimg"></img></a>
                                                        </li>
                                                        <li>
                                                            <a href={data.fld_youtube} target="_blank">   <img src={youtube} className="socialimg"></img></a>
                                                        </li>
                                                        <li>
                                                            <a href={data.fld_linkedin} target="_blank">  <img src={linkedin} className="socialimg"></img></a>
                                                        </li>
                                                    </>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
export default WelcomeComp;
