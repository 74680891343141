import React, { useState } from "react";

export default function Msme({
  setActiveTab,
  handleUpdateMsmeInfo,
  msmeDetails,
  onFileChangeMsme,
  selectedFileMsme,
}) {
  // const [isMSMERegistered, setIsMSMERegistered] = useState(false);
  // const [msmeType, setMSMEType] = useState("");
  // const [udyamRegistrationNumber, setUdyamRegistrationNumber] = useState("");
  // const [udyamCertificateFile, setUdyamCertificateFile] = useState(null);

  // handlers
  const handleCheckboxChange = (option) => {
    handleUpdateMsmeInfo({
      ...msmeDetails,
      isMSMERegistered: option === "yes",
    });
  };

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  const handleFieldChange = (field, value) => {
    handleUpdateMsmeInfo({
      ...msmeDetails,
      [field]: value,
    });
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <h2 className="text-center mb-4">MSME Registration</h2>
        {/* Left side - MSME Registration checkboxes */}
        <div className="col-12 mb-4">
          <label>
            MSME (Udyam Registration) <span className="text-danger">*</span>
          </label>
          <div className="d-flex">
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input rounded-circle"
                id="msmeRadioYes"
                checked={msmeDetails.isMSMERegistered}
                onChange={() => handleCheckboxChange("yes")}
              />
              <label className="form-check-label" htmlFor="msmeRadioYes">
                Yes
              </label>
            </div>

            <div className="form-check ms-3">
              <input
                type="radio"
                className="form-check-input rounded-circle"
                id="msmeRadioNo"
                checked={!msmeDetails.isMSMERegistered}
                onChange={() => handleCheckboxChange("no")}
              />
              <label className="form-check-label" htmlFor="msmeRadioNo">
                No
              </label>
            </div>
          </div>
        </div>

        {/* Right side - MSME Registration Number, MSME Type dropdown, and other fields */}
        {msmeDetails.isMSMERegistered && (
          <div className="col-md-6">
            {/* MSME Type dropdown */}
            <div className="mb-3">
              <label htmlFor="msmeType" className="form-label">
                MSME Type
              </label>
              <select
                className="form-select bg-white select reason-option inputselect poel-vendor-input"
                id="msmeType"
                value={msmeDetails.msmeType}
                onChange={(e) => handleFieldChange("msmeType", e.target.value)}
              >
                <option value="">Please select</option>
                <option value="Manufacturer - Micro">
                  Manufacturer - Micro
                </option>
                <option value="Manufacturer - Small">
                  Manufacturer - Small
                </option>
                <option value="Manufacturer - Medium">
                  Manufacturer - Medium{" "}
                </option>
                <option value="Service Provider - Micro">
                  Service Provider - Micro{" "}
                </option>
                <option value="Service Provider - Small">
                  Service Provider - Small{" "}
                </option>
                <option value="Service Provider - Medium">
                  Service Provider - Medium{" "}
                </option>

                {/* Add more options as needed */}
              </select>
            </div>
          </div>
        )}

        {/* </div> */}

        {/* Left side - Udyam Registration Number field (Visible only when "Yes" checkbox is selected) */}
        {msmeDetails.isMSMERegistered && (
          <div className="col-md-6">
            <label>Udyam Registration Number</label>
            <div className="mb-3">
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="udyamRegistrationNumber"
                placeholder="Enter Udyam Registration Number"
                value={msmeDetails.udyamRegistrationNumber}
                onChange={(e) =>
                  handleFieldChange("udyamRegistrationNumber", e.target.value)
                }
              />
            </div>
          </div>
        )}

        {/* Right side - Udyam Certificate field (Visible only when "Yes" checkbox is selected) */}
        {msmeDetails.isMSMERegistered && (
          <div className="col-md-6">
            <label> Udyam Certificate</label>
            <div className="mb-3">
              <div className="mb-3">
                <input
                  type="file"
                  className="form-control vendor-tds-file-input-heigth d-block poel-vendor-input poel-file"
                  placeholder="Choose File*"
                  aria-label="File"
                  id="udyamCertificateFile"
                  // onChange={(e) => handleFieldChange("udyamCertificateFile", e.target.files[0])}
                  onChange={onFileChangeMsme}
                />
              </div>
              <p>File name : {selectedFileMsme[0]?.name}</p>
              {/* <p>File name  : {msmeDetails.udyamCertificateFile?.name}</p> */}
            </div>
          </div>
        )}
      </div>
      <div className="row mt-3">
        <div className="col-12 d-flex justify-content-center">
          <button
            className="enquirybtnvendor text-white fs-6"
            type="button"
            onClick={(e) => {
              setActiveTab("gst");
            }}
          >
            Back
          </button>

          <button
            className="enquirybtnvendor text-white fs-6 ms-3"
            onClick={(e) => setActiveTab("bankDetails")}
            type="button"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
