import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { IoSync } from "react-icons/io5";
import { useParams } from "react-router-dom";
const GoodrichEnquiry = () => {
  const [mailType, setType] = useState("");
  const [clientid, setClientid] = useState("");
  const mailtype = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);

  // fileupload
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileBase64String, setfileBase64String] = useState("");
  const onFileChange = (e) => {
    if (e.target.files[0].type == "application/pdf") {
      setSelectedFile(e.target.files);
    } else {
      Notiflix.Notify.Failure("file should be in pdf format");
    }
  };

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64String(attachment);
        // console.log(setfileBase64String);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64(selectedFile[0]);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    mobile: "",
    email: "",
    age: "",
    location: "",
    attachment: "",
    country: "",
    postapplied: "",
    qualification: "",
    careernutshell: "",
    strongsuits: "",
    profachievements: "",
    jobleavingreason: "",
    otherinfo: "",
    File: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  function GoodrichEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Goodrich Gasket | ENQUIRY</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: 100%; margin: auto">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 30%" src="https://goodrichgasket.com/images/logo.png"/>' +
      "</div>" +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h2 style="text-align: left">Dear Goodrich,</h2>' +
      "<h2><b>Received an <b>Job Application</b> Form</b></h2>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Age</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.age +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Location</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.location +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Country</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.country +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Post Applied</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.postapplied +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Educational Qualification</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.qualification +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Your Career in a Nutshell</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.careernutshell +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Strong Suits</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.strongsuits +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Significant Professional Achievements</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.profachievements +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Reason For Leaving Your Current Job</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.jobleavingreason +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Any Other Information</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.otherinfo +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 100%; display: flex">' +
      Enquiry.attachment +
      "</div>" +
      "</div>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose:true,
      showOnlyTheLastOne:true,
      useFontAwesome:true,
      fontSize:"15px",
      timeout:4000
    });
  }, []);
  useEffect(() => {
    setType(mailtype.mailtype);
    setClientid(mailtype.clientid);
  }, []);

  return (
    <div className="container-fluid p-lg-5 p-3">
      <form className="row py-2">
        <div className="col-12 py-2">
          <h5 className="text-center">Join Our Team</h5>
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Name *"
            aria-label="Name"
            id="name"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Mobile *"
            aria-label="Mobile"
            id="mobile"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="email"
            className="form-control rounded-0"
            placeholder="Email *"
            aria-label="Email"
            id="email"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            id="age"
            name="age"
            placeholder="Age *"
            aria-label="Age"
            value={Enquiry.age}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Location *"
            aria-label="Location"
            id="location"
            name="location"
            value={Enquiry.location}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Country *"
            id="country"
            name="country"
            value={Enquiry.country}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            id="post_applied"
            name="postapplied"
            placeholder="Post Applied *"
            aria-label="Post Applied"
            value={Enquiry.postapplied}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Educational Qualification *"
            aria-label="Educational Qualifiction"
            id="educational_qualifiction"
            name="qualification"
            value={Enquiry.qualification}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            required
            type="text"
            className="form-control rounded-0"
            placeholder="Your Career in a Nutshell *"
            aria-label="Your Career in a Nutshell"
            id="career"
            name="careernutshell"
            value={Enquiry.careernutshell}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Strong Suits *"
            aria-label="Strong Suits"
            id="suits"
            name="strongsuits"
            value={Enquiry.strongsuits}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Significant Professional Achievements *"
            aria-label="Significant Professional Achievements"
            id="achievements"
            name="profachievements"
            value={Enquiry.profachievements}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Reason For Leaving Your Current Job *"
            aria-label="Reason For Leaving Your Current Job"
            id="current_job"
            name="jobleavingreason"
            value={Enquiry.jobleavingreason}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-8">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Any Other Information"
            aria-label="Any Other Information"
            id="information"
            name="otherinfo"
            value={Enquiry.otherinfo}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <input
            type="file"
            className="form-control rounded-0 d-block"
            placeholder="Choose File*"
            aria-label="File"
            id="file"
            name="File"
            onChange={onFileChange}
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-4">
          <p
            className=" p-2 border-dotted w-50 d-flex justify-content-between captcha-copy-disable"
            style={{ border: "3px dotted #006fbf", color: "#006fbf",userSelect:"none" }}
          >
            {captcha_number}
            <span
              onClick={() => {
                setRotatecaptcha("iorotatevig");
                Genratecaptcha();
              }}
              className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
            >
              <IoSync className="aButtonvig rotate" />
            </span>
          </p>
          <input
            type="captcha"
            className="form-control"
            placeholder="Captcha Code*"
            name="captcha"
            value={Enquiry.captcha}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
            style={{border: "1px solid #ced4da"}}
          />
        </div>
        <div className="col-md-6 mt-lg-5 py-2 mb-2 col-lg-4">
          <button
            className="btn w-100"
            style={{
              background: "#68bb29",
              color: "white",
              fontSize: "18px",
              color: "white",
              fontSize: "18px",
            }}
            type="button"
            onClick={() => {
              if (Enquiry.name != "") {
                if (Enquiry.mobile != "") {
                  if (Enquiry.mobile.length == 10) {
                    if (Enquiry.email != "") {
                      if (Enquiry.EmailRegex.test(Enquiry.email)) {
                        if (Enquiry.age != "") {
                          if (Enquiry.location != "") {
                            if (Enquiry.country != "") {
                              if (Enquiry.postapplied != "") {
                                if (Enquiry.qualification != "") {
                                  if (Enquiry.careernutshell != "") {
                                    if (Enquiry.strongsuits != "") {
                                      if (Enquiry.profachievements != "") {
                                        if (Enquiry.jobleavingreason != "") {
                                          if (selectedFile.length != 0) {
                                            if (
                                              Enquiry.captcha.toString() ==
                                              captcha_number.toString()
                                            ) {
                                              const htmlMail =
                                                GoodrichEnquiry_formate();
                                              Notiflix.Loading.Arrows("Please wait...");
                                              PostApiCall.postRequest(
                                                {
                                                  mailtype: mailType,
                                                  mail: htmlMail,
                                                  clientid: clientid,
                                                  name: Enquiry.name,
                                                  mobile: Enquiry.mobile,
                                                  email: Enquiry.email,
                                                  age: Enquiry.age,
                                                  location: Enquiry.location,
                                                  country: Enquiry.country,
                                                  postapplied:
                                                    Enquiry.postapplied,
                                                  qualification:
                                                    Enquiry.qualification,
                                                  careernutshell:
                                                    Enquiry.careernutshell,
                                                  strongsuits:
                                                    Enquiry.strongsuits,
                                                  profachievements:
                                                    Enquiry.profachievements,
                                                  jobleavingreason:
                                                    Enquiry.jobleavingreason,
                                                  otherinfo: Enquiry.otherinfo,
                                                  attachment: fileBase64String,
                                                },
                                                "jobapplications"
                                              ).then((results2) =>
                                                results2.json().then((obj2) => {
                                                  if (
                                                    results2.status == 200 ||
                                                    results2.status == 201
                                                  ) {
                                                    Notiflix.Notify.Success(
                                                      "Thank you, our team will contact you shortly!"
                                                    );
                                                    setTimeout(() => {
                                                      window.location.reload();
                                                    }, 3000);
                                                  }
                                                  else(
                                                    Notiflix.Loading.Remove()
                                                  )
                                                })
                                              );
                                            } else {
                                              Notiflix.Notify.Failure(
                                                "Please enter valid captcha"
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.Failure(
                                              "Please upload resume in pdf format"
                                            );
                                          }
                                        } else {
                                          Notiflix.Notify.Failure(
                                            "Please enter current job"
                                          );
                                        }
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Please fill significant professional achievements"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.Failure(
                                        "Please fill strong suits"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.Failure(
                                      "Please fill your career in a nutshell"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.Failure(
                                    "Please fill educational qualifiction"
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter Post Applied"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure("Please fill country");
                            }
                          } else {
                            Notiflix.Notify.Failure("Please fill location");
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter your age");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter valid email");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter email");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid mobile number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter mobile number");
                }
              }
              else {
                Notiflix.Notify.Failure("Please enter name");
              }
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
export default GoodrichEnquiry;
