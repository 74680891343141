import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./shivnadiappointment.css";
const ShivnadiAppoinment = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    appoinment: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function ShivnadiAppoinment_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>SRI SIVANADI ASTROLOGY CENTER | ENQUIRY</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: 100%; margin: auto">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 30%" src="http://onlineshivanaadi.com/assets/images/logo-2.png"/>' +
      "</div>" +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Mam, </h2>' +
      "<p style='font-size:14px;color: black;'>You have received an appoinment from the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Contact</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div className="container-fluid shivnadiappoinbg">
      <div className="row">
        <div className="col-12 px-0">
          <div className="as_journal_box_wrapper">
            <form>
              <h3 className="text-center as_subheading mb-3 text-white">
                Appointment Form
              </h3>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label className="labalcolor">Name <span className="text-danger">*</span></label>
                  <div className="form-group">
                    <input
                      className="form-control form-controlappoinment inputbgappoinment text-white"
                      id="Name"
                      type="text"
                      name="name"
                      value={Enquiry.name}
                      onChange={(e) =>
                        setEnquiry({
                          ...Enquiry,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label className="labalcolor">Email</label>
                  <div className="form-group">
                    <input
                      className="form-control form-controlappoinment inputbgappoinment text-white"
                      type="text"
                      id="email_id"
                      name="email"
                      value={Enquiry.email}
                      onChange={(e) =>
                        setEnquiry({
                          ...Enquiry,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label className="labalcolor">Mobile Number <span className="text-danger">*</span></label>
                  <div className="form-group">
                    <input
                      className="form-control form-controlappoinment inputbgappoinment text-white"
                      type="text"
                      name="mobile"
                      value={Enquiry.mobile}
                      onChange={(e) => {
                        if (e.target.value.length <= 10)
                          setEnquiry({
                            ...Enquiry,
                            [e.target.name]: e.target.value.replace(/\D/g, ""),
                          });
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <p
                      className=" p-2 border-dotted w-50 d-flex justify-content-between captcha-copy-disable"
                      style={{ border: "3px dotted #ffffff8a", color: "white" }}
                    >
                      {captcha_number}
                      <span
                        onClick={() => {
                          setRotatecaptcha("iorotate");
                          Genratecaptcha();
                        }}
                        className={
                          Rotatecaptcha == "iorotate" ? "iorotate" : ""
                        }
                      >
                        <IoSync className="aButton rotate" />
                      </span>
                    </p>
                    <label className="labalcolor">Captcha Code <span className="text-danger">*</span></label>
                    <input
                      type="captcha"
                      className="form-control form-controlappoinment inputbgappoinment text-white"
                      name="captcha"
                      value={Enquiry.captcha}
                      onChange={(e) =>
                        setEnquiry({
                          ...Enquiry,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center as_padderTop20">
                  <a
                    className="as_btn text-white"
                    type="button"
                    onClick={() => {
                      if (Enquiry.name != "") {
                        if (
                          Enquiry.email == "" ||
                          Enquiry.EmailRegex.test(Enquiry.email)
                        ) {
                          if (Enquiry.mobile != "") {
                            if (Enquiry.mobile.length == 10) {
                              // if (Enquiry.appoinment != "") {
                              if (
                                Enquiry.captcha.toString() ==
                                captcha_number.toString()
                              ) {
                                setLoader(true);
                                const htmlMail = ShivnadiAppoinment_formate();
                                PostApiCall.postRequest(
                                  {
                                    mailtype: mailtype,
                                    mail: htmlMail,
                                    clientid: clientid,
                                    name: Enquiry.name,
                                    mobile: Enquiry.mobile,
                                    email: Enquiry.email,
                                    // appoinment: Enquiry.appoinment,
                                  },
                                  "NewEnquiryMailerApi"
                                ).then((results2) =>
                                  results2.json().then((obj2) => {
                                    if (
                                      results2.status == 200 ||
                                      results2.status == 201
                                    ) {
                                      setLoader(false);
                                      Notiflix.Notify.Success(
                                        "Thank you, we will confirm your appointment shortly."
                                      );
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 3000);
                                      // window.location.reload();
                                    }
                                  })
                                );
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter valid captcha"
                                );
                              }
                            }
                            //   else {
                            //     Notiflix.Notify.Failure(
                            //       "Please enter reason for appointment"
                            //     );
                            //   }
                            // }
                            else {
                              Notiflix.Notify.Failure(
                                "Please enter valid mobile number"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter mobile number"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter valid email");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter name");
                      }
                    }}
                  >
                    {Loader == true ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ width: "1.4rem", height: "1.4rem" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Make an appointment"
                    )}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShivnadiAppoinment;
