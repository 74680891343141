import React, { useState, useEffect } from "react";
import BasicInformation from "./CustomerBasicInformation";
import GstInfo from "./CustomerGstInfo";
import TdsDetails from "./CustomerTdsDetails";
import { Nav, Navbar } from "react-bootstrap";
import "./formStyle.css";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
export default function CommonRouting() {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d+$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const tanRegex = /^[A-Z]{4}[0-9]{5}[A-Z]$/;

  // Basic information Trade License
  const [selectedTradeLicense, setSelectedTradeLicense] = useState([]);
  const [fileBase64StringTradeLicense, setfileBase64StringTradeLicense] =
    useState("");
  const [tradeLicenseFileName, setTradeLicenseFileName] = useState(""); // File name of the selected trade license


  // Tds Pan card
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileBase64String, setfileBase64String] = useState("");
  const [tdsPanFileName, setTdsPanFileName] = useState(""); // File name of the selected trade license

  // Tds Customer TDS-TCS card
  const [selectedFileCustomerTDS, setSelectedFileCustomerTDS] = useState([]);
  const [fileBase64StringCustomerTDS, setfileBase64StringCustomerTDS] = useState("");
  const [tdsCustomerTcsFileName, setTdsCustomerTcsFileName] = useState(""); // File name of the selected trade license

  // Gst
  const [selectedFileGst, setSelectedFileGst] = useState([]);
  const [fileBase64StringGst, setfileBase64StringGst] = useState("");
  const [gstFileName, setGstFileName] = useState(""); // File name of the selected trade license

  // Tds pancard
  const onFileChange = (e) => {
    setSelectedFile(e.target.files);
  };

  // TDS Custome TDS-TCS
  const onFileChangeCustomerTDS = (e) => {
    setSelectedFileCustomerTDS(e.target.files);
  }

  // Gst
  const onFileChangeGst = (e) => {
    setSelectedFileGst(e.target.files);
  };


  // Trande License
  const onFileChangeTradelicense = (e) => {
    setSelectedTradeLicense(e.target.files);
  };

  // TDS Pan card File upload
  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64String(attachment);
        setTdsPanFileName(file.name); // Set the file name
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64(selectedFile[0]);

  // TDS Customer TDS-TCS
  const encodeFileBase64CustomerTDS = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringCustomerTDS(attachment); 
        setTdsCustomerTcsFileName(file.name)  // Set the file name     
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64CustomerTDS(selectedFileCustomerTDS[0]);

  // Gst
  const encodeFileBase64Gst = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringGst(attachment);
        setGstFileName(file.name);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64Gst(selectedFileGst[0]);


  // Basic information Trade License Attachment
  const encodeFileBase64TradeLicense = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringTradeLicense(attachment);
        setTradeLicenseFileName(file.name); // Set the file name

      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64TradeLicense(selectedTradeLicense[0]);


  // whole form data
  const [basicInfoData, setBasicInfoData] = useState({
    orgName: "",
    streetAddress: "",
    streetAddress2: "",
    vendorCity: "",
    vendorStateName: "",
    vendorCountryName: "India",
    zipCode: "",
    contactPerson: "",
    contactPhone: "",
    contactEmail: "",
    accountPerson: "",
    accountPhone: "",
    accountEmail: "",
    companyregistration: "",
    exportCode: "",
    selectedCustomertypes: [], // newly moved
  });

  const [tdsBasicInfo, setTdsBasicInfo] = useState({
    panNo: "",
    // panCard: [],
    panType: "",
    tanNo: "",
    sectionQ: "",
  });

  const [gstBasicInfo, setGstBasicInfo] = useState({
    isCheckedYes: false,
    // isCheckedNo: true,
    gstRegistrationNumber: "",
    legalName: "",
    // gstCertificateFile: [],
    taxPayerType: "",
    eInvoiceApplicability: "",
    returnFilingFrequency: "",
  });

  // Indian vendor mailer 
  function poel_format() {
    return (
      '<html><head><meta charset="utf-8"><title>POEL Customer Registration</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%;height:100%;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left;margin: 0px 10px 0px 20px;">' +
      '<img style="width: 200px" src="http://poel.in/images/logo.png"/>' +
      "</div>" +
      '<div style="padding:5px 20px">' +
      '<h4 style="text-align: left;font-size:15px;color:#000">Dear Sir/Mam, </h4>' +
      '<h4 style="font-size:15px;color:#000">Greetings! You have received an registration for customer via the website. The details are as follows:</h4>' +
      '<table style="border-collapse: collapse;width:100%;height:100%"><tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Organisation Name</b> </td><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.orgName +
      '</td></tr>'+'<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Importer / Exporter Code</b></td><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.exportCode +
      '</td></tr>' +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Company Registration / Trade License  Number </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.companyregistration +
      '</td></tr>' +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress +
      '</td></tr>' +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address Line 2</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress2 +
      '</td></tr>' +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>City</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCity +
      '</td></tr>' +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>State</b> </td>' +'<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorStateName +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Country</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCountryName +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Zipcode</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.zipCode +
      '</td></tr>' +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Customer Type</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.selectedCustomertypes +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPerson +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Phone Number</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPhone +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact  Person Email</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactEmail +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPerson +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Phone No</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPhone +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Email</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountEmail +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>GST Registration Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.gstRegistrationNumber +
      "</td></tr>" +'<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Legal Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.legalName +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Tax Payer Type</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.taxPayerType +
      "</td></tr>" +
      '<tr><td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>PAN</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      tdsBasicInfo.panNo +
      "</td>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>TAN Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      tdsBasicInfo.tanNo +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Applicability of Section 194Q </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      tdsBasicInfo.sectionQ +
      "</td>" +
      "</tr>" +
      "</table>" +
      "<p style='font-size:15px;color:#000'><b>Thank You</b></p>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }

  // Overseas vendor mailer
  function poelOverseasMailFormat() {
    return (
      '<html><head><meta charset="utf-8"><title>POEL Customer Registration</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%;height:100%;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left;margin: 0px 10px 0px 20px;">' +
      '<img style="width: 200px" src="http://poel.in/images/logo.png"/>' +
      "</div>" +
      '<div style="padding:5px 20px">' +
      '<h4 style="text-align: left;font-size:15px;color:#000">Dear Sir/Mam, </h4>' +
      '<h4 style="font-size:15px;color:#000">Greetings! You have received an registration for customer via the website. The details are as follows:</h4>' +
      '<table style="border-collapse: collapse;width:100%;height:100%">' +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Organisation Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.orgName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Importer / Exporter Code</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.exportCode +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Company Registration / Trade License  Number </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.companyregistration +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address Line 2</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress2 +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>City</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCity +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>State</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorStateName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Country</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCountryName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Zipcode</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.zipCode +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Customer Type</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.selectedCustomertypes +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPerson +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Phone Number</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPhone +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact  Person Email</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactEmail +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPerson +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Phone No</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPhone +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Email</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountEmail +
      "</td>" +
      "</tr>" +
      "</table>" +
      "<p style='font-size:15px;color:#000'><b>Thank You</b></p>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }

  // Parent component' updaters 
  const handleUpdateGstBasicInfo = (data) => {
    setGstBasicInfo(data);
  };
  const handleUpdateBasicInfo = (data) => {
    setBasicInfoData(data);
    // console.log(data); //dev only
  };
  const handleUpdateTdsBasicInfo = (data) => {
    setTdsBasicInfo(data);
    // console.log(data); //dev only
  };

  const [activeTab, setActiveTab] = useState("basicInfo");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  // regex based validations 
  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };
  const isNumberValid = (phone) => {
    return phoneRegex.test(phone);
  };
  const isPanValid = (pan) => {
    const panUpperCase = pan.toUpperCase();
    return panRegex.test(panUpperCase)
  }
  const isTanValid = (tan) => {
    const tanUpperCase = tan.toUpperCase();
    return tanRegex.test(tanUpperCase)
  }

  // Submit function on Bank Details tab - for countries other than India
  const validateBasicInfoTab = () => {
    // console.log(basicInfoData.orgName);
    if (basicInfoData.orgName == "") {
      Notiflix.Notify.Failure("Organisation name is required");
      return;
    }

    if (basicInfoData.companyregistration === "") {
      Notiflix.Notify.Failure("Company registration number is required");
      return;
    }
    if (fileBase64StringTradeLicense === "") {
      Notiflix.Notify.Failure("Company Registration / Trade License file upload required");
      return;
    }

    if (basicInfoData.streetAddress === "") {
      Notiflix.Notify.Failure("Street address is required");
      return;
    }

    if (basicInfoData.vendorStateName === "") {
      Notiflix.Notify.Failure("State is required");
      return;
    }

    if (basicInfoData.zipCode === "") {
      Notiflix.Notify.Failure("Zipcode is required");
      return;
    }

    if (basicInfoData.selectedCustomertypes.length === 0) {
      Notiflix.Notify.Failure("Please select a customer type(s)");
      return;
    }

    if (basicInfoData.contactPerson === "") {
      Notiflix.Notify.Failure("Purchase contact person name is required");
      return;
    }
    if (basicInfoData.contactPhone === "") {
      Notiflix.Notify.Failure("Purchase Contact person phone is required");
      return;
    } else if (!isNumberValid(basicInfoData.contactPhone)) {
      Notiflix.Notify.Failure("Purchase contact person phone number is invalid")
      return
    }
    // purchase email specific
    if (basicInfoData.contactEmail === "") {
      Notiflix.Notify.Failure("Purchase contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.contactEmail)) {
      Notiflix.Notify.Failure("Purchase contact person email is invalid")
      return
    }
    if (basicInfoData.accountPerson === "") {
      Notiflix.Notify.Failure("Account person name is required");
      return;
    }
    // phone specific validation
    if (basicInfoData.accountPhone === "") {
      Notiflix.Notify.Failure("Accounts contact person phone number is required");
      return;
    } else if (!isNumberValid(basicInfoData.accountPhone)) {
      Notiflix.Notify.Failure("Accounts contact person phone number is invaild")
      return
    }
    // account email specific
    if (basicInfoData.accountEmail === "") {
      Notiflix.Notify.Failure("Accounts contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.accountEmail)) {
      Notiflix.Notify.Failure("Accounts contact person email is invalid")
      return
    }
    handleSubmit();
  };

  // Main submit function - on last tab
  const validateBasicFormData = () => {
    if (basicInfoData.orgName === "") {
      Notiflix.Notify.Failure("Organisation name is required");
      return;
    }

    if (basicInfoData.companyregistration === "") {
      Notiflix.Notify.Failure("Company registration number is required");
      return;
    }

    if (fileBase64StringTradeLicense === "") {
      Notiflix.Notify.Failure("Company Registration / Trade License file upload required");
      return;
    }

    if (basicInfoData.streetAddress === "") {
      Notiflix.Notify.Failure("Street address is required");
      return;
    }

    if (basicInfoData.vendorStateName === "") {
      Notiflix.Notify.Failure("State is required");
      return;
    }

    if (basicInfoData.zipCode === "") {
      Notiflix.Notify.Failure("Zipcode is required");
      return;
    }
    if (basicInfoData.selectedCustomertypes.length == 0) {
      Notiflix.Notify.Failure("Please select a customer type(s)");
      return;
    }
    if (basicInfoData.services === "") {
      Notiflix.Notify.Failure("Services are required");
      return;
    }
    if (basicInfoData.contactPerson === "") {
      Notiflix.Notify.Failure("Contact person name is required");
      return;
    }

    // purchase contact number specific
    if (basicInfoData.contactPhone === "") {
      Notiflix.Notify.Failure("Purchase contact person phone number is required");
      return;
    } else if (!isNumberValid(basicInfoData.contactPhone)) {
      Notiflix.Notify.Failure("Purchase contact person phone number is invalid")
      return
    }

    // purchase contact email specific
    if (basicInfoData.contactEmail === "") {
      Notiflix.Notify.Failure("Purchase contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.contactEmail)) {
      Notiflix.Notify.Failure("Purchase contact person email is invalid")
      return
    }

    if (basicInfoData.accountPerson === "") {
      Notiflix.Notify.Failure("Account person name is required");
      return;
    }

    if (basicInfoData.accountPhone === "") {
      Notiflix.Notify.Failure("Accounts contact person phone number is required");
      return;
    } else if (!isNumberValid(basicInfoData.accountPhone)) {
      Notiflix.Notify.Failure("Accounts contact person phone is invalid")
      return
    }

    // account contact email specific
    if (basicInfoData.accountEmail === "") {
      Notiflix.Notify.Failure("Account contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.accountEmail)) {
      Notiflix.Notify.Failure("Account contact person email is invalid")
      return
    }

    // for gst validation
    if (gstBasicInfo.isCheckedYes == true) {
      if (gstBasicInfo.gstRegistrationNumber === "") {
        Notiflix.Notify.Failure("GST registration number is required")
        return;
      } else if (gstBasicInfo.legalName == "") {
        Notiflix.Notify.Failure("GST legal name is required")
        return;
      } else if (fileBase64StringGst == "") {
        Notiflix.Notify.Failure("GST certificate is required");
        return;
      } else if (gstBasicInfo.taxPayerType == "") {
        Notiflix.Notify.Failure("Tax payer type is required")
        return;
      }
    }

    // PAN specific validation
    if (tdsBasicInfo.panNo === "") {
      Notiflix.Notify.Failure("PAN is required");
      return;
    } else if (!isPanValid(tdsBasicInfo.panNo)) {
      Notiflix.Notify.Failure("PAN is invalid")
      return
    }

    if (tdsBasicInfo.panType === "") {
      Notiflix.Notify.Failure("PAN type is required");
      return;
    }

    if (fileBase64String === "") {
      Notiflix.Notify.Failure("PAN card file is required");
      return;
    }

    // if (tdsBasicInfo.tanNo == "") {
    //   Notiflix.Notify.Failure("TAN is required")
    //   return;
    // } else if (!isTanValid(tdsBasicInfo.tanNo)) {
    //   Notiflix.Notify.Failure("TAN is invalid")
    //   return;
    // }

    if (fileBase64StringCustomerTDS ===""){
      Notiflix.Notify.Failure("Customer TDS-TCS confirmation file is required");
      return;
    }

    if (tdsBasicInfo.sectionQ === "") {
      Notiflix.Notify.Failure("Applicability of 194Q is required")
      return;
    }

    handleSubmit();
  };

  // submit - towards mailer
  const handleSubmit = () => {
    Notiflix.Loading.Dots("Please Wait...");
    // const htmlMail = poel_format();
    let htmlMail;
    if (basicInfoData.vendorCountryName == "India") {
      htmlMail = poel_format()
    } else {
      htmlMail = poelOverseasMailFormat()
    }

    PostApiCall.postRequest(
      {
        mailtype: "from POEL Customer Registration",
        mail: htmlMail,
        clientid: clientid,
        attachment: fileBase64String,
        tdsPanFileName:tdsPanFileName, // Add Tds Pan crad file name to the payload,
        gstAttachment: fileBase64StringGst,
        gstFileName:gstFileName, // Add the Gst file name to the payload,
        tradelicensebasicinfo: fileBase64StringTradeLicense,
        tradelicensefilename: tradeLicenseFileName, // Add the trade license file name to the payload,
        tdscustomertds:fileBase64StringCustomerTDS,
        tdsCustomerTcsFileName:tdsCustomerTcsFileName  // Add the tds-tcs customer file name to the payload,      
      },
      "PoelVendorMailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(
            "Thank you, our team will contact you shortly!"
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
    );
  };

  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);

  return (
    <div className="main-form-div">
      <Navbar>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-tabs">
            <Nav.Link
              className={`nav-link ${activeTab === "basicInfo" ? "active" : ""
                }`}
              onClick={() => handleChangeTab("basicInfo")}
            >
              Basic Info
            </Nav.Link>
            {basicInfoData.vendorCountryName === "India" && (
              <>
                <Nav.Link
                  className={`nav-link ${activeTab === "gst" ? "active" : ""}`}
                  onClick={() => handleChangeTab("gst")}
                >
                  GST
                </Nav.Link>
              </>
            )}

            {basicInfoData.vendorCountryName === "India" && (
              <Nav.Link
                className={`nav-link ${activeTab === "tdsDetails" ? "active" : ""
                  }`}
                onClick={() => handleChangeTab("tdsDetails")}
              >
                TDS
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        {activeTab === "basicInfo" && (
          <BasicInformation
            setActiveTab={setActiveTab}
            onHandleUpdate={handleUpdateBasicInfo}
            basicInfoData={basicInfoData}
            onFileChangeTradelicense={onFileChangeTradelicense}
            selectedTradeLicense={selectedTradeLicense}
            onSubmit={validateBasicInfoTab}
          />
        )}
        {activeTab === "gst" && (
          <GstInfo
            setActiveTab={setActiveTab}
            handleUpdateGstBasicInfo={handleUpdateGstBasicInfo}
            gstBasicInfo={gstBasicInfo}
            onFileChangeGst={onFileChangeGst}
            selectedFileGst={selectedFileGst}
          />
        )}
        {activeTab === "tdsDetails" && (
          <TdsDetails
            setActiveTab={setActiveTab}
            handleUpdateTdsBasicInfo={handleUpdateTdsBasicInfo}
            tdsBasicInfo={tdsBasicInfo}
            handleSubmit={validateBasicFormData}
            onFileChange={onFileChange}
            selectedFile={selectedFile}
            onFileChangeCustomerTDS={onFileChangeCustomerTDS}
            selectedFileCustomerTDS={selectedFileCustomerTDS}
          />
        )}
      </div>
    </div>
  );
}
