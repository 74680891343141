 var urlString = "https://globaltrendz.info/gt-api/";
// var urlString = "http://localhost:8088/gt-api/";
// var urlString = "http://192.168.1.25:8088/gt-api/";
const GetApiCall = {
  getRequest(url) {
    // console.log(urlString + url)
    return fetch(urlString + url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
      },
    })
  },
};
export default GetApiCall;
