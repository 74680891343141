import React, { useState, useEffect } from "react";
import BasicInformation from "./BasicInformation";
import GstInfo from "./GstInfo";
import Msme from "./Msme";
import BankDetails from "./BankDetails";
import TdsDetails from "./TdsDetails";
import { Nav, Navbar } from "react-bootstrap";
import "./formStyle.css";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
export default function CommonRouting() {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d+$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  // Basic information Trade License
  const [selectedTradeLicense, setSelectedTradeLicense] = useState([]);
  const [fileBase64StringTradeLicense, setfileBase64StringTradeLicense] =
    useState("");
  const [tradeLicenseFileName, setTradeLicenseFileName] = useState(""); // File name of the selected trade license

  // Tds Pan card
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileBase64String, setfileBase64String] = useState("");
  const [tdsPanFileName, setTdsPanFileName] = useState(""); // File name of the selected trade license

  // Gst
  const [selectedFileGst, setSelectedFileGst] = useState([]);
  const [fileBase64StringGst, setfileBase64StringGst] = useState("");
  const [gstFileName, setGstFileName] = useState(""); // File name of the selected trade license

  // MSME
  const [selectedFileMsme, setSelectedFileMsme] = useState([]);
  const [fileBase64StringMsme, setfileBase64StringMsme] = useState("");
  const [msmeFileName, setMsmeFileName] = useState(""); // File name of the selected trade license

  // Bank Details
  const [selectedFileBank, setSelectedFileBank] = useState([]);
  const [fileBase64StringBank, setfileBase64StringBank] = useState("");
  const [bankdetailsFileName, setBankDetailsFileName] = useState(""); // File name of the selected trade license

  // Tds pancard
  const onFileChange = (e) => {
    setSelectedFile(e.target.files);
  };

  // Gst
  const onFileChangeGst = (e) => {
    setSelectedFileGst(e.target.files);
  };

  // Msme
  const onFileChangeMsme = (e) => {
    setSelectedFileMsme(e.target.files);
  };

  // Bank Details
  const onFileChangeBank = (e) => {
    setSelectedFileBank(e.target.files);
  };

  // Trande License
  const onFileChangeTradelicense = (e) => {
    setSelectedTradeLicense(e.target.files);
  };

// TDS Pan card File upload
  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64String(attachment);
        setTdsPanFileName(file.name); // Set the file name
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64(selectedFile[0]);

  // Gst
  const encodeFileBase64Gst = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringGst(attachment);
        setGstFileName(file.name);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64Gst(selectedFileGst[0]);

  // MSME
  const encodeFileBase64Msme = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringMsme(attachment);
        setMsmeFileName(file.name);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64Msme(selectedFileMsme[0]);

  // Bank Details
  const encodeFileBase64Bank = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringBank(attachment);
        setBankDetailsFileName(file.name);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64Bank(selectedFileBank[0]);

  // Basic information Trade License Attachment
  const encodeFileBase64TradeLicense = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64StringTradeLicense(attachment);
        setTradeLicenseFileName(file.name); // Set the file name
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64TradeLicense(selectedTradeLicense[0]);

  const [errorMsg, setErrorMsg] = useState(
    "Please fill all the fields marked with *"
  );

  // whole form data
  const [basicInfoData, setBasicInfoData] = useState({
    orgName: "",
    streetAddress: "",
    streetAddress2: "",
    vendorCity: "",
    vendorStateName: "",
    vendorCountryName: "India",
    zipCode: "",
    services: "",
    contactPerson: "",
    contactPhone: "",
    contactEmail: "",
    accountPerson: "",
    accountPhone: "",
    accountEmail: "",
    companyregistration: "",
    exportCode: "",
    selectedVendorTypes: [], // newly moved
  });

  //   {
  //     console.log(basicInfoData.vendorCountryName);
  //   }

  const [bankInfoData, setBankInfoData] = useState({
    benificiaryName: "",
    bankName: "",
    branchName: "",
    ifscCode: "",
    accountNumber: "",
    swift: "",
    iban: "",
    // otherDocs: [],
  });

  const [tsdBasicInfo, setTdsBasicInfo] = useState({
    panNo: "",
    // panCard: [],
    panType: "",
    selectedVendorTypes: [],
  });

  const [gstBasicInfo, setGstBasicInfo] = useState({
    isCheckedYes: false,
    // isCheckedNo: true,
    gstRegistrationNumber: "",
    legalName: "",
    // gstCertificateFile: [],
    taxPayerType: "",
    eInvoiceApplicability: "",
    returnFilingFrequency: "",
  });

  const [msmeDetails, setMsmeDetails] = useState({
    isMSMERegistered: false,
    msmeType: "",
    udyamRegistrationNumber: "",
    // udyamCertificateFile: [],
  });
  // Indian vendor mailer 
  function poel_format() {
    return (
      '<html><head><meta charset="utf-8"><title>POEL Vendor Registration</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%;height:100%;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left;margin: 0px 10px 0px 20px;">' +
      '<img style="width: 200px" src="http://poel.in/images/logo.png"/>' +
      "</div>" +
      '<div style="padding:5px 20px">' +
      '<h4 style="text-align: left;font-size:15px;color:#000">Dear Sir/Mam, </h4>' +
      '<h4 style="font-size:15px;color:#000">Greetings! You have received an registration for vendor via the website. The details are as follows:</h4>' +
      '<table style="border-collapse: collapse;width:100%;height:100%">' +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Organisation Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.orgName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Importer / Exporter Code</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.exportCode +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Company Registration / Trade License  Number </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.companyregistration +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address Line 2</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress2 +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>City</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCity +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>State</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorStateName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Country</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCountryName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Zipcode</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.zipCode +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Services</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.services +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPerson +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Phone Number</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPhone +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact  Person Email</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactEmail +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPerson +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Phone No</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPhone +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Email</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountEmail +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>GST Registration Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.gstRegistrationNumber +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Legal Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.legalName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Tax Payer Type</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.taxPayerType +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>E-Invoice Applicability</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.eInvoiceApplicability +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Return Filing Frequency</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      gstBasicInfo.returnFilingFrequency +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>MSME Type</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      msmeDetails.msmeType +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Udyam Registration Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      msmeDetails.udyamRegistrationNumber +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Beneficiary Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.benificiaryName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Bank Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.bankName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Branch Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.branchName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Bank IFSC Code</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.ifscCode +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Bank Account Number</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.accountNumber +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>PAN Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      tsdBasicInfo.panNo +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>PAN Type</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      tsdBasicInfo.panType +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Vendor Type</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.selectedVendorTypes +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>PAN Number</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      tsdBasicInfo.panNo +
      "</td>" +
      "</tr>" +
      "</table>" +
      "<p style='font-size:15px;color:#000'><b>Thank You</b></p>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }

  // Overseas vendor mailer
  function poelOverseasMailFormat() {
    return (
      '<html><head><meta charset="utf-8"><title>POEL Vendor Registration</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%; height:100%;background-color: #8080800d;padding: 20px 0px 0px 0px;">' +
      '<div style="text-align: left;margin: 0px 10px 0px 20px;">' +
      '<img style="width: 200px" src="http://poel.in/images/logo.png"/>' +
      "</div>" +
      '<div style="padding:5px 20px">' +
      '<h4 style="text-align: left;font-size:15px;color:#000">Dear Sir/Mam, </h4>' +
      '<h4 style="font-size:15px;color:#000">Greetings! You have received an registration for vendor via the website. The details are as follows:</h4>' +
      '<table style="border-collapse: collapse;width:100%">' +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Organisation Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.orgName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Importer / Exporter Code</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.exportCode +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Company Registration / Trade License  Number </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.companyregistration +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address </b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Street Address Line 2</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.streetAddress2 +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>City</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCity +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>State</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorStateName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Country</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.vendorCountryName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Zipcode</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.zipCode +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Services</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.services +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPerson +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact Phone Number</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactPhone +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Purchase Contact  Person Email</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.contactEmail +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Name</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPerson +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Phone No</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountPhone +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Accounts Contact Person Email</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      basicInfoData.accountEmail +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Beneficiary Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.benificiaryName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Bank Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.bankName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>SWIFT</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.swift +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>IBAN</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.iban +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Branch Name</b></td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.branchName +
      "</td>" +
      "</tr>" +
      "<tr>" +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000"> <b>Bank Account Number</b> </td>' +
      '<td style="border:1px solid black; padding: 10px;font-size:15px;color:#000">' +
      bankInfoData.accountNumber +
      "</td>" +
      "</tr>" +
      "</table>" +
      "<p style='font-size:15px;color:#000'><b>Thank You</b></p>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }

  const handleUpdateMsmeInfo = (data) => {
    setMsmeDetails(data);
  };

  const handleUpdateGstBasicInfo = (data) => {
    setGstBasicInfo(data);
    // console.log(data)
  };

  const handleUpdateBasicInfo = (data) => {
    setBasicInfoData(data);
    // console.log(data); //dev only
  };

  const handleUpdateBankDetailsInfo = (data) => {
    setBankInfoData(data);
  };

  const handleUpdateTdsBasicInfo = (data) => {
    setTdsBasicInfo(data);
    // console.log(data); //dev only
  };


  const [activeTab, setActiveTab] = useState("basicInfo");

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  // regex based validations
  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };
  const isNumberValid = (phone) => {
    return phoneRegex.test(phone);
  };
  const isPanValid = (pan) => {
    const panUpperCase = pan.toUpperCase();
    return panRegex.test(panUpperCase)
  }

  // Submit function on Bank Details tab - for countries other than India
  const validateOnBankTab = () => {
    // console.log(basicInfoData.orgName);
    if (basicInfoData.orgName == "") {
      Notiflix.Notify.Failure("Organisation name is required");
      return;
    }

    if (basicInfoData.companyregistration === "") {
      Notiflix.Notify.Failure("Company registration number is required");
      return;
    }
    if (fileBase64StringTradeLicense === "") {
      Notiflix.Notify.Failure("Company Registration / Trade License file upload required");
      return;
    }

    if (basicInfoData.streetAddress === "") {
      Notiflix.Notify.Failure("Street address is required");
      return;
    }

    if (basicInfoData.vendorStateName === "") {
      Notiflix.Notify.Failure("State is required");
      return;
    }

    if (basicInfoData.zipCode === "") {
      Notiflix.Notify.Failure("Zipcode is required");
      return;
    }

    if (basicInfoData.selectedVendorTypes.length === 0) {
      Notiflix.Notify.Failure("Please select a vendor type(s)");
      return;
    }

    if (basicInfoData.services === "") {
      Notiflix.Notify.Failure("Services are required");
      return;
    }

    if (basicInfoData.contactPerson === "") {
      Notiflix.Notify.Failure("Purchase contact person name is required");
      return;
    }
    if (basicInfoData.contactPhone === "") {
      Notiflix.Notify.Failure("Purchase Contact person phone is required");
      return;
    } else if (!isNumberValid(basicInfoData.contactPhone)) {
      Notiflix.Notify.Failure("Purchase contact person phone number is invalid")
      return
    }
    // purchase email specific
    if (basicInfoData.contactEmail === "") {
      Notiflix.Notify.Failure("Purchase contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.contactEmail)) {
      Notiflix.Notify.Failure("Purchase contact person email is invalid")
      return
    }
    if (basicInfoData.accountPerson === "") {
      Notiflix.Notify.Failure("Account person name is required");
      return;
    }
    // phone specific validation
    if (basicInfoData.accountPhone === "") {
      Notiflix.Notify.Failure("Accounts contact person phone number is required");
      return;
    } else if (!isNumberValid(basicInfoData.accountPhone)) {
      Notiflix.Notify.Failure("Accounts contact person phone number is invaild")
      return
    }
    // account email specific
    if (basicInfoData.accountEmail === "") {
      Notiflix.Notify.Failure("Accounts contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.accountEmail)) {
      Notiflix.Notify.Failure("Accounts contact person email is invalid")
      return
    }

    if (bankInfoData.benificiaryName === "") {
      Notiflix.Notify.Failure("Benificiary name is required");
      return;
    }
    if (bankInfoData.bankName === "") {
      Notiflix.Notify.Failure("Bank name is required");
      return;
    }
    if (bankInfoData.branchName === "") {
      Notiflix.Notify.Failure("Branch name is required");
      return;
    }
    // if (bankInfoData.ifscCode === "") {
    //   Notiflix.Notify.Failure("IFSC code is required");
    //   return;
    // }
    if (bankInfoData.accountNumber === "") {
      Notiflix.Notify.Failure("Account number is required");
      return;
    }

    if (bankInfoData.swift === "") {
      Notiflix.Notify.Failure("SWIFT code is required");
      return;
    }

    if (fileBase64StringBank === "") {
      Notiflix.Notify.Failure("Cancelled Cheque Leaf / Bank Details in Company Letter head file upload required");
      return;
    }

    // Test

    // if (bankInfoData.iban === "") {
    //   Notiflix.Notify.Failure("IBAN is required");
    //   return;
    // }

    handleSubmit();
  };

  // Submit fucntion on TDS 
  const validateBasicFormData = () => {
    if (basicInfoData.orgName === "") {
      Notiflix.Notify.Failure("Organisation name is required");
      return;
    }

    if (basicInfoData.companyregistration === "") {
      Notiflix.Notify.Failure("Company registration number is required");
      return;
    }

    if (fileBase64StringTradeLicense === "") {
      Notiflix.Notify.Failure("Company Registration / Trade License file upload required");
      return;
    }

    // Basic info validation
    if (basicInfoData.streetAddress === "") {
      Notiflix.Notify.Failure("Street address is required");
      return;
    }

    if (basicInfoData.vendorStateName === "") {
      Notiflix.Notify.Failure("State is required");
      return;
    }

    if (basicInfoData.zipCode === "") {
      Notiflix.Notify.Failure("Zipcode is required");
      return;
    }
    if (basicInfoData.selectedVendorTypes.length == 0) {
      Notiflix.Notify.Failure("Please select vendor type(s)");
      return;
    }
    if (basicInfoData.services === "") {
      Notiflix.Notify.Failure("Services are required");
      return;
    }
    if (basicInfoData.contactPerson === "") {
      Notiflix.Notify.Failure("Contact person name is required");
      return;
    }

    // purchase contact number specific
    if (basicInfoData.contactPhone === "") {
      Notiflix.Notify.Failure("Purchase contact person phone number is required");
      return;
    } else if (!isNumberValid(basicInfoData.contactPhone)) {
      Notiflix.Notify.Failure("Purchase contact person phone number is invalid")
      return
    }

    // purchase contact email specific
    if (basicInfoData.contactEmail === "") {
      Notiflix.Notify.Failure("Purchase contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.contactEmail)) {
      Notiflix.Notify.Failure("Purchase contact person email is invalid")
      return
    }

    if (basicInfoData.accountPerson === "") {
      Notiflix.Notify.Failure("Account person name is required");
      return;
    }

    if (basicInfoData.accountPhone === "") {
      Notiflix.Notify.Failure("Accounts contact person phone number is required");
      return;
    } else if (!isNumberValid(basicInfoData.accountPhone)) {
      Notiflix.Notify.Failure("Accounts contact person phone is invalid")
      return
    }

    // account contact email specific
    if (basicInfoData.accountEmail === "") {
      Notiflix.Notify.Failure("Account contact person email is required");
      return;
    } else if (!isEmailValid(basicInfoData.accountEmail)) {
      Notiflix.Notify.Failure("Account contact person email is invalid")
      return
    }

    // for gst validation
    if (gstBasicInfo.isCheckedYes == true) {
      if (gstBasicInfo.gstRegistrationNumber === "") {
        Notiflix.Notify.Failure("GST registration number is required")
        return;
      } else if (gstBasicInfo.legalName == "") {
        Notiflix.Notify.Failure("GST legal name is required")
        return;
      } else if (fileBase64StringGst == "") {
        Notiflix.Notify.Failure("GST certificate is required");
        return;
      } else if (gstBasicInfo.taxPayerType == "") {
        Notiflix.Notify.Failure("Tax payer type is required")
        return;
      } else if (gstBasicInfo.eInvoiceApplicability == "") {
        Notiflix.Notify.Failure("E Invoice applicability is required")
        return;
      } else if (gstBasicInfo.returnFilingFrequency == "") {
        Notiflix.Notify.Failure("Return filling frequency is required")
        return;
      }
    }

    // MSME validation
    if (msmeDetails.isMSMERegistered == true) {
      if (msmeDetails.msmeType == "") {
        Notiflix.Notify.Failure("MSME type is required")
        return;
      } else if (msmeDetails.udyamRegistrationNumber == "") {
        Notiflix.Notify.Failure("Udyam registration number is required")
        return;
      } else if (fileBase64StringMsme === "") {
        Notiflix.Notify.Failure("Udyam certificate is required ");
        return;
      }
    }

    // bank details 
    if (bankInfoData.benificiaryName === "") {
      Notiflix.Notify.Failure("Benificiary name is required");
      return;
    }

    if (bankInfoData.bankName === "") {
      Notiflix.Notify.Failure("Bank name is required");
      return;
    }

    if (bankInfoData.branchName === "") {
      Notiflix.Notify.Failure("Branch name is required");
      return;
    }

    if (bankInfoData.ifscCode === "") {
      Notiflix.Notify.Failure("Bank IFSC code is required");
      return;
    }

    if (bankInfoData.accountNumber === "") {
      Notiflix.Notify.Failure("Bank account number is required");
      return;
    }

    if (fileBase64StringBank === "") {
      Notiflix.Notify.Failure("Cancelled Cheque Leaf / Bank Details in Company Letter head is required");
      return;
    }


    // PAN specific validation
    if (tsdBasicInfo.panNo === "") {
      Notiflix.Notify.Failure("PAN is required");
      return;
    } else if (!isPanValid(tsdBasicInfo.panNo)) {
      Notiflix.Notify.Failure("PAN is invalid")
      return
    }

    if (tsdBasicInfo.panType === "") {
      Notiflix.Notify.Failure("PAN type is required");
      return;
    }

    if (fileBase64String === "") {
      Notiflix.Notify.Failure("PAN card file is required");
      return;
    }


    handleSubmit();
  };

  // submit - towards mailer
  const handleSubmit = () => {
    Notiflix.Loading.Dots("PLease Wait...");
    // const htmlMail = poel_format();
    let htmlMail;
    if (basicInfoData.vendorCountryName == "India") {
      htmlMail = poel_format()
    } else {
      htmlMail = poelOverseasMailFormat()
    }

    PostApiCall.postRequest(
      {
        mailtype: "from POEL Vendor Registration",
        mail: htmlMail,
        clientid: clientid,
        attachment: fileBase64String,
        tdsPanFileName:tdsPanFileName, // Add Tds Pan crad file name to the payload,
        gstAttachment: fileBase64StringGst,
        gstFileName:gstFileName, // Add the Gst file name to the payload,
        msmeAttachment: fileBase64StringMsme,
        msmeFileName:msmeFileName, // Add the MSME file name to the payload,
        bankAttachment: fileBase64StringBank,
        bankdetailsFileName:bankdetailsFileName, // Add the Bank Details file name to the payload,
        tradelicensebasicinfo: fileBase64StringTradeLicense,
        tradelicensefilename: tradeLicenseFileName, // Add the trade license file name to the payload,
      },
      "PoelVendorMailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(
            "Thank you, our team will contact you shortly!"
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
    );
  };

  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);

  return (
    <div className="main-form-div">
      <Navbar>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-tabs">
            <Nav.Link
              className={`nav-link ${activeTab === "basicInfo" ? "active" : ""
                }`}
              onClick={() => handleChangeTab("basicInfo")}
            >
              Basic Info
            </Nav.Link>
            {basicInfoData.vendorCountryName === "India" && (
              <>
                <Nav.Link
                  className={`nav-link ${activeTab === "gst" ? "active" : ""}`}
                  onClick={() => handleChangeTab("gst")}
                >
                  GST
                </Nav.Link>
                <Nav.Link
                  className={`nav-link ${activeTab === "msmeDetails" ? "active" : ""
                    }`}
                  onClick={() => handleChangeTab("msmeDetails")}
                >
                  MSME
                </Nav.Link>
              </>
            )}
            <Nav.Link
              className={`nav-link ${activeTab === "bankDetails" ? "active" : ""
                }`}
              onClick={() => handleChangeTab("bankDetails")}
            >
              Bank Details
            </Nav.Link>

            {basicInfoData.vendorCountryName === "India" && (
              <Nav.Link
                className={`nav-link ${activeTab === "tdsDetails" ? "active" : ""
                  }`}
                onClick={() => handleChangeTab("tdsDetails")}
              >
                TDS
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        {activeTab === "basicInfo" && (
          <BasicInformation
            setActiveTab={setActiveTab}
            onHandleUpdate={handleUpdateBasicInfo}
            basicInfoData={basicInfoData}
            onFileChangeTradelicense={onFileChangeTradelicense}
            selectedTradeLicense={selectedTradeLicense}
          />
        )}
        {activeTab === "gst" && (
          <GstInfo
            setActiveTab={setActiveTab}
            handleUpdateGstBasicInfo={handleUpdateGstBasicInfo}
            gstBasicInfo={gstBasicInfo}
            onFileChangeGst={onFileChangeGst}
            selectedFileGst={selectedFileGst}
          />
        )}
        {activeTab === "msmeDetails" && (
          <Msme
            setActiveTab={setActiveTab}
            msmeDetails={msmeDetails}
            handleUpdateMsmeInfo={handleUpdateMsmeInfo}
            onFileChangeMsme={onFileChangeMsme}
            selectedFileMsme={selectedFileMsme}
          />
        )}
        {activeTab === "bankDetails" && (
          <BankDetails
            setActiveTab={setActiveTab}
            onUpdateBankInfo={handleUpdateBankDetailsInfo}
            bankInfoData={bankInfoData}
            onFileChangeBank={onFileChangeBank}
            selectedFileBank={selectedFileBank}
            basicInfoData={basicInfoData}
            onSubmit={validateOnBankTab}
          />
        )}
        {activeTab === "tdsDetails" && (
          <TdsDetails
            setActiveTab={setActiveTab}
            handleUpdateTdsBasicInfo={handleUpdateTdsBasicInfo}
            tsdBasicInfo={tsdBasicInfo}
            handleSubmit={validateBasicFormData}
            onFileChange={onFileChange}
            selectedFile={selectedFile}
          />
        )}
      </div>
    </div>
  );
}
