import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
class SocialSource extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      EnquiryListData: [],
      fileData: [],
      startDate: null,
      endDate: null,
      searchInput: "",
    };
  }
  componentDidMount() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
    PostApiCall.postRequest(
      {
        WhereClause: `where FLD_CLIENT_ID=${loginDetails[0].fld_userid}`,
      },
      "getEnquiryData"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data);
        this.setState({
          EnquiryListData: obj.data,
          // console.log(EnquiryListData)
        });
      })
    );
  }
  onPost() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
    if (loginDetails[0].fld_userid == 1) {
      Notiflix.Loading.Dots("Please Wait...");
      var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
      PostApiCall.postRequest(
        {
          WhereClause: `where cast (fld_created_on as date) BETWEEN cast('${moment(
            this.state.startDate == null ? null : this.state.startDate
          ).format("MM-DD-YYYY")}' as date)
    AND cast('${moment(
            this.state.endDate == null ? null : this.state.endDate
          ).format("MM-DD-YYYY")}' as date)
    `,
        },
        "getEnquiryData"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            // console.log(obj.data);
            this.setState({
              EnquiryListData: obj.data,
            });
            Notiflix.Loading.Remove();
          }
        })
      );
    } else {
      Notiflix.Loading.Dots("Please Wait...");

      PostApiCall.postRequest(
        {
          WhereClause: `where FLD_CLIENT_ID=${loginDetails[0].fld_userid
            } AND cast (fld_created_on as date) BETWEEN cast('${moment(
              this.state.startDate == null ? null : this.state.startDate
            ).format("MM-DD-YYYY")}' as date)
    AND cast('${moment(
              this.state.endDate == null ? null : this.state.endDate
            ).format("MM-DD-YYYY")}' as date)
    `,
        },
        "getEnquiryData"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            // console.log(obj.data);
            this.setState({
              EnquiryListData: obj.data,
            });
            Notiflix.Loading.Remove();
          }
        })
      );
    }

  }
  //   Search bar
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  // Date handler
  dateHandler = (e) => {
    e.preventDefault();
    Notiflix.Loading.Dots("Please Wait...");
    PostApiCall.postRequest(
      {
        RecordCount: "*",
        WhereClause: `where cast (FLD_CREATED_ON as date) BETWEEN cast('${moment(
          this.state.startDate == null ? null : this.state.startDate
        ).format("YYYY-MM-DD")}' as date)
        AND cast('${moment(
          this.state.endDate == null ? null : this.state.endDate
        ).format("YYYY-MM-DD")}' as date)
        `,
      },
      "GET_DATA_BY_DATE"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            EnquiryListData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  // download
  DownloadCSV = () => {
    this.setState(
      {
        fileData: this.state.EnquiryListData,
      },
      () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      }
    );
  };
  //   search bar
  render() {
    return (
      <React.Fragment>
        <Card className="mini-stats-wid">
          <CardBody className="pt-0 mt-4">
            <Media className="d-block">
              <Media body>
                <Row>
                  <Col xl="12">
                    <Card className="overflow-hidden">
                      <div className="gt-enquiry-bg-soft-pink">
                        <Row>
                          <Col xs="6">
                            <div className="gt-enquiry-text p-3">
                              <h5 className="gt-enquiry-text text-white">
                                Enquiry List
                              </h5>
                            </div>
                          </Col>
                          <Col xs="6">
                            <div className="gt-enquiry-text p-3">
                              <Link
                                style={{
                                  float: "right",
                                  marginTop: "-5px",
                                }}
                                className="btn align-items-center btn gt-enquiry-btn2 "
                                onClick={this.DownloadCSV.bind(this)}
                              >
                                Download(.csv)
                              </Link>
                              <CSVLink
                                data={this.state.fileData}
                                filename="EnquiryData.csv"
                                className="hidden"
                                ref={this.csvLink}
                                target="_blank"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Row className="my-3 px-2">
                        <div className="col-2 d-flex align-item-center pl-3">
                          <input
                            className="form-control"
                            style={{ fontSize: "16px" }}
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={(e) => this.seachBarHandler(e)}
                            value={this.state.searchInput}
                          />
                        </div>
                        <div className="col-4 d-flex enquiry-start-date-size">
                          <h5 className="display-inline pb-0 mb-0">
                            Start Date
                          </h5>
                          <input
                            className="form-control left-margin-negative"
                            type="date"
                            placeholder="Search"
                            aria-label="Search"
                            value={this.state.startDate}
                            onChange={(e) => {
                              this.setState({
                                startDate: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-4 d-flex enquiry-start-date-size">
                          <h5 className="display-inline pb-0 mb-0">End Date</h5>
                          <input
                            className="form-control left-margin-negative"
                            type="date"
                            placeholder="Search"
                            aria-label="Search"
                            value={this.state.endDate}
                            onChange={(e) => {
                              this.setState({
                                endDate: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-2 text-end pr-3 d-flex enquiry-filter-size">
                          <a
                            className="btn align-items-center btn gt-enquiry-btn px-4 bg-dark text-white"
                            style={{ marginLeft: "10px" }}
                            onClick={(e) => {
                              if (
                                this.state.startDate != null &&
                                this.state.endDate != null
                              ) {
                                this.onPost();
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please Enter Start Date and end Date"
                                );
                              }
                            }}
                          >
                            Filter
                          </a>
                        </div>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div
                      class="table-responsive mt-3"
                      style={{ maxHeight: "530px" }}
                    >
                      <table class="table table-bordered mb-0">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Client Name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>City</th>
                            <th>Company Name</th>
                            <th>Message</th>
                            <th>Requested on</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.EnquiryListData.filter((data) => {
                            if (this.state.searchInput == "") {
                              return data;
                            }
                            if (
                              (this.state.searchInput != "" &&
                                `${data.enquiry_name
                                  ? data.enquiry_name.toLowerCase()
                                  : ""
                                  }`.includes(
                                    this.state.searchInput.toLowerCase()
                                  )) ||
                              `${data.fld_city ? data.fld_city.toLowerCase() : ""
                                }`.includes(this.state.searchInput.toLowerCase()) ||
                              `${data.fld_mobile_no
                                ? data.fld_mobile_no.toLowerCase()
                                : ""
                                }`.includes(this.state.searchInput.toLowerCase())
                            ) {
                              return data;
                            }
                          }).map((data, i) => (
                            <tr>
                              <td className="centered">{i + 1}</td>
                              <td className="leftAlign">{data.client_name}</td>
                              <td className="leftAlign">{data.enquiry_name}</td>
                              <td className="centered">{data.fld_email}</td>
                              <td className="centered">{data.fld_mobile_no}</td>
                              <td className="centered">{data.fld_city}</td>
                              <td className="rightAlign">
                                {data.fld_company_name}
                              </td>
                              <td className="rightAlign">{data.fld_message}</td>
                              <td className="rightAlign">
                                {moment(data.fld_created_on).format("YYYY/MM/DD")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
export default SocialSource;
