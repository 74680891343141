import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./Passage.css";

const PassageEnquiry = () => {
  const { countryname } = useParams(); // Fetch 'country' from static country iframe using params
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    numberofperson: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  function getParamFromQueryString(queryString, paramName) {
    const params = queryString.slice(1).split("&");
    for (let i = 0; i < params.length; i++) {
      const [key, value] = params[i].split("=");
      if (key === paramName) {
        return decodeURIComponent(value);
      }
    }
    return null;
  }

  const queryString = window.location.search;
  const paramValue = getParamFromQueryString(queryString, "paramName");
  // console.log(paramValue);

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);

  // console.log(paramValue);

  function PassageEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Passage | Booking Form</title></head>' +
      '<body style="font-family: sans-serif;color:#000;background-color: #8080800d;>' +
      "<div>" +
      '<div style="padding: 20px">' +
      '<div style="text-align: left; margin-bottom: 10px;width: 100%;margin: auto;text-align: center;border-bottom: 2px solid;"><img style="width:120px" src="https://thepassage.travel/assets/images/passage-logo-color.png"/></div>' +
      '<h2 style="text-align: left;font-size:16px;color: #000;margin-top: 20px;">Dear Sir/Madam, </h2>' +
      "<p style='font-size:16px;color: #000;padding-bottom: 0;margin-bottom: 5px;'>Greetings!</p>" +
      "<p style='font-size:16px;color: #000;padding-top: 0;margin-top: 5px;padding-bottom: 0;margin-bottom: 0;'>You have received an enquiry from the website. The details are as follows:</p>" +
      `<p style="color:#000;font-size:16px; padding: 0 0 5px;margin: 0;"><b style="color:#000">Full Name: </b>
      ${Enquiry.name}
      </p>` +
      `<p style="color:#000;font-size:16px;padding: 0 0 5px;margin: 0;"><b style="color:#000">Phone Number: </b>
      ${Enquiry.mobile}
      </p>` +
      `<p style="color:#000;font-size:16px;padding: 0 0 5px;margin: 0;"><b style="color:#000">Email: </b>
      ${Enquiry.email}
      </p>` +
      `<p style="color:#000;font-size:16px;padding: 0 0 5px;margin: 0;"><b style="color:#000">Number of Person: </b>
      ${Enquiry.numberofperson}
      </p>` +
      `<p style="color:#000;font-size:16px;padding: 0 0 5px;margin: 0;"><b style="color:#000">Message: </b>
      ${Enquiry.message}
      </p>` +
      "<p style='font-size:16px;padding-top:5px;margin:0'><b>Thank You</b></p>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 900000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <>
      <div
        className="container-fluid form-border passagebg"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <form className="row">
          <div className="col-12 col-md-6 mb-4">
            <input
              type="text"
              className="form-control passageinput"
              id="Name"
              name="name"
              placeholder="Full Name *"
              value={Enquiry.name}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6 mb-4">
            <input
              type="text"
              className="form-control passageinput"
              id="MobileNumber"
              name="mobile"
              placeholder="Phone Number"
              value={Enquiry.mobile}
              onChange={(e) => {
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
              }}
            />
          </div>
          <div className="col-12 col-md-6 mb-4">
            <input
              type="email"
              className="form-control passageinput"
              placeholder="Email"
              id="email_id"
              name="email"
              value={Enquiry.email}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6 mb-4">
            <input
              type="text"
              className="form-control passageinput"
              placeholder="Number of person *"
              id="numberofperson"
              name="numberofperson"
              value={Enquiry.numberofperson}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 mb-4">
            <textarea
              className="form-control passageinput"
              rows="5"
              id="Message"
              placeholder="Message"
              name="message"
              value={Enquiry.message}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            ></textarea>
          </div>
          <div className="col-md-12 mb-4 captchdisplay">
            <p className="mb-2 me-2 d-flex justify-content-between captchvaluesize inputborderradius  inputzeroborder-radius inputbgzero inputzero passagecaptcha passagecaptcafont">
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotate");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
              >
                <IoSync className="passagerotatebtn rotate" />
              </span>
            </p>
            <input
              type="captcha"
              className="form-control passagecaptchainput captchvaluesize inputborderradius inputbgzero inputzero mt-4 mt-sm-0"
              placeholder="Enter Captcha Code *"
              name="captcha"
              value={Enquiry.captcha}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 text-end">
            <button
              className="btn-passage"
              type="button"
              onClick={() => {
                if (Enquiry.name != "") {
                  if (Enquiry.mobile != "") {
                    if (
                      Enquiry.email == "" ||
                      Enquiry.EmailRegex.test(Enquiry.email)
                    ) {
                      if (Enquiry.numberofperson != "") {
                        if (
                          Enquiry.captcha.toString() ==
                          captcha_number.toString()
                        ) {
                          setLoader(true);
                          const htmlMail = PassageEnquiry_formate();

                          PostApiCall.postRequest(
                            {
                              // mailtype: mailtype,
                              mailtype: paramValue,
                              mail: htmlMail,
                              clientid: clientid,
                              name: Enquiry.name,
                              mobile: Enquiry.mobile,
                              email: Enquiry.email,
                              message: Enquiry.message,
                            },
                            "NewEnquiryMailerApi"
                          ).then((results2) =>
                            results2.json().then((obj2) => {
                              if (
                                results2.status == 200 ||
                                results2.status == 201
                              ) {
                                setLoader(false);
                                Notiflix.Notify.Success(
                                  "Thank you, our team will contact you shortly!"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 3000);
                              }
                            })
                          );
                        } else {
                          Notiflix.Notify.Failure("Please enter valid captcha");
                        }
                      } else {
                        Notiflix.Notify.Failure(
                          "Please enter number of person"
                        );
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter valid email");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter phone number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter full name");
                }
              }}
            >
              {Loader == true ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default PassageEnquiry;
