import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./del.css";
import { IoSync } from "react-icons/io5";
const Quote = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const [Service, setService] = useState("");
  const [Loader, setLoader] = useState(false);
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [serviceData, setServiceData] = useState([
    { Key: 1, Value: "Aeronautical" },
    { Key: 2, Value: "Airlines" },
    { Key: 3, Value: "Automotive" },
    { Key: 4, Value: "Cement" },
    { Key: 5, Value: "Central/State Corporations" },
    { Key: 6, Value: "Civil Engineering" },
    { Key: 7, Value: "Cold Storage" },
    { Key: 8, Value: "Construction" },
    { Key: 9, Value: "Consultancy" },
    { Key: 10, Value: "Chemical Plants" },
    { Key: 11, Value: "Defense" },
    { Key: 12, Value: "Delivery Services" },
    { Key: 13, Value: "Logistics" },
    { Key: 14, Value: "Manpower Deployment/Management" },
    { Key: 15, Value: "Mining" },
    { Key: 16, Value: "Miscellaneous" },
    { Key: 17, Value: "Other Government Agencies" },
    { Key: 18, Value: "Other Industrial" },
    { Key: 19, Value: "Railways" },
    { Key: 20, Value: "Renewable Energy" },
    { Key: 21, Value: "Retail" },
    { Key: 22, Value: "Security" },
    { Key: 23, Value: "Service Centers" },
    { Key: 24, Value: "Shipping" },
    { Key: 25, Value: "Sports" },
    { Key: 26, Value: "Steel" },
    { Key: 27, Value: "Telecommunication" },
  ]);
  const [QuoteEnquiry, setQuoteEnquiry] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
    company: "",
    quantity: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);

  function Deltechquote_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Deltech Quote</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: auto; margin: auto;border: 1px solid grey;padding: 0px 0px 15px 0px;">' +
      '<div style="text-align: left; margin-bottom: -25px">' +
      '<img style="width: 30%" src="http://deltechsafety.com/images/logo.png"/>' +
      "</div>" +
      '<div style="padding: 20px">' +
      '<h4 style="text-align: left">Hello Sir/Madam, </h4>' +
      '<h4 style="font-weight:100">Greetings! you have received an enquiry via the website. The details are as follows:' +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      QuoteEnquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Company Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      QuoteEnquiry.company +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Industry Type</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Service +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b>Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      QuoteEnquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Details</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      QuoteEnquiry.message +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Quantity</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      QuoteEnquiry.quantity +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b>Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      QuoteEnquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      "</body>" +
      "</html>"
    );
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);

  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div className="container-fluid form-border px-lg-5 pt-lg-5 quotebackground">
      <form className="form col-md-7">
        <div className="mb-3 row">
          <label for="FullName" className="col-sm-3 col-form-label">
            Full Name <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <input
              name="name"
              type="text"
              id="name"
              className="form-control"
              style={{ border: "1px solid #ced4da" }}
              value={QuoteEnquiry.name}
              onChange={(e) =>
                setQuoteEnquiry({
                  ...QuoteEnquiry,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label for="Email" className="col-sm-3 col-form-label">
            Email Id
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              style={{ border: "1px solid #ced4da" }}
              className="form-control"
              name="email"
              id="Email"
              value={QuoteEnquiry.email}
              onChange={(e) => {
                setQuoteEnquiry({
                  ...QuoteEnquiry,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label for="CompanyName" className="col-sm-3 col-form-label">
            Company Name <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <input
              name="company"
              type="text"
              id="company"
              className="form-control"
              style={{ border: "1px solid #ced4da" }}
              value={QuoteEnquiry.company}
              onChange={(e) => {
                setQuoteEnquiry({
                  ...QuoteEnquiry,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="IndustryType" class="col-sm-3 col-form-label">
            Industry Type <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <select
              class="form-select select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok "
              aria-label="Default select example"
              style={{ border: "1px solid #ced4da" }}
              value={Service}
              onChange={(e) => {
                setService(e.target.value);
              }}
            >
              <option key="" value="">Select Industry Type</option>
              {serviceData.map((data, index) => {
                return (
                  <option key={data.Key} value={data.Value}>
                    {data.Value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label for="Details" className="col-sm-3 col-form-label">
            Details <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <textarea
              type="text"
              className="form-control"
              id="message"
              name="message"
              style={{ border: "1px solid #ced4da" }}
              value={QuoteEnquiry.message}
              onChange={(e) => {
                setQuoteEnquiry({
                  ...QuoteEnquiry,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label for="Quantity" className="col-sm-3 col-form-label">
            Quantity <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              style={{ border: "1px solid #ced4da" }}
              className="form-control"
              id="Quantity"
              name="quantity"
              value={QuoteEnquiry.quantity}
              onChange={(e) => {
                setQuoteEnquiry({
                  ...QuoteEnquiry,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label for="Phone" className="col-sm-3 col-form-label">
            Mobile Number <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">+91</span>
              <input type="text" className="form-control" id="Phone"
                name="mobile"
                style={{ border: "1px solid #ced4da" }}
                value={QuoteEnquiry.mobile}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setQuoteEnquiry({
                      ...QuoteEnquiry,
                      [e.target.name]: e.target.value.replace(/\D/g, ""),
                    });
                  }
                }} />
            </div>
          </div>
        </div>

        <div className="mb-3 row">
          <label for="Phone" className="col-sm-3 col-form-label">
            Captcha Code <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9 d-flex">
            <p
              className="me-2 d-flex justify-content-between p-2 captchvaluesize captcha-copy-disable captchacodesize"
              style={{
                color: "#6f7172",
              }}
            >
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotate");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
              >
                <IoSync className="aButtoncleanroomkart rotatemani clean-room-kart-captcharotate-icon" />
              </span>
            </p>
            <input
              type="captcha"
              className="form-control p-2 captchafont captchacodesizeinput"
              placeholder="Enter Captcha Code *"
              name="captcha"
              value={QuoteEnquiry.captcha}
              onChange={(e) =>
                setQuoteEnquiry({ ...QuoteEnquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3"></div>
          <div className="col-9 formsubmit-buttondel">
            <button
              type="button"
              className="btn formsubmit"
              onClick={() => {
                if (QuoteEnquiry.name != "") {
                  if (
                    QuoteEnquiry.email == "" ||
                    QuoteEnquiry.EmailRegex.test(QuoteEnquiry.email)
                  ) {
                    if (QuoteEnquiry.company != "") {
                      if (Service != "") {
                        if (QuoteEnquiry.message != "") {
                          if (QuoteEnquiry.quantity != "") {
                            if (QuoteEnquiry.mobile != "") {
                              if (QuoteEnquiry.mobile.length == 10) {
                                if (
                                  QuoteEnquiry.captcha.toString() ==
                                  captcha_number.toString()
                                ) {
                                  setLoader(true);
                                  const htmlMail = Deltechquote_formate();
                                  PostApiCall.postRequest(
                                    {
                                      mailtype: mailtype,
                                      mail: htmlMail,
                                      clientid: clientid,
                                      name: QuoteEnquiry.name,
                                      mobile: QuoteEnquiry.mobile,
                                      email: QuoteEnquiry.email,
                                      message: QuoteEnquiry.message,
                                    },
                                    "NewEnquiryMailerApi"
                                  ).then((results2) =>
                                    results2.json().then((obj2) => {
                                      if (
                                        results2.status == 200 ||
                                        results2.status == 201
                                      ) {
                                        setLoader(false);
                                        Notiflix.Notify.Success(
                                          "Thank you, our team will contact you shortly!"
                                        );
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 3000);
                                        // window.location.reload();
                                      }
                                    })
                                  );
                                }
                                else {
                                  Notiflix.Notify.Failure(
                                    "Please enter valid captcha"
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter valid mobile number"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure("Please enter mobile number");
                            }
                          } else {
                            Notiflix.Notify.Failure("Please enter quantity");
                          }
                        } else {
                          Notiflix.Notify.Failure("please enter details");
                        }
                      } else {
                        Notiflix.Notify.Failure("please select industry type");
                      }
                    }
                    else {
                      Notiflix.Notify.Failure("please enter company name");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid email");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter name");
                }
              }}
            >
              {Loader == true ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Quote;
