import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./Manichetinad.css";
import { IoSync } from "react-icons/io5";
import { setOptions } from "leaflet";
const EnquiryManichetinad = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  function ManichetinadEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Mani Chetinad Hotel | ENQUIRY</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%; margin: auto">' +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Madam, </h2>' +
      "<p style='font-size:14px;color: black;'>Greetings! you have received an enquiry via the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;color: black">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;color: black">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;color: black">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%;color: black">' +
      Enquiry.message +
      "</div>" +
      "</div>" +
      "<br>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  // console.log(Rotatecaptcha);
  return (
    <>
      <div
        className="container-fluid form-border"
        style={{
          height: "100vh",
          background: "#f3f3f3",
          overflow:"hidden"
        }}
      >
        <form className="row needs-validation">
          <div className="clearfix">
            <div className="form-group">
              <div className="field-inner maniform">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={Enquiry.name}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                  }
                  placeholder="Your Name *"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="field-inner maniform">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={Enquiry.email}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                  }
                  onClick={(e) => {
                    setOptions({
                      ...setEnquiry,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  placeholder="Your Email"
                />
              </div>
            </div>
            <div className="form-group ">
              <div className="field-inner maniform">
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  className="form-control"
                  value={Enquiry.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      setEnquiry({
                        ...Enquiry,
                        [e.target.name]: e.target.value.replace(/\D/g, ""),
                      });
                  }}
                  placeholder="Mobile Number *"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="field-inner maniform">
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Message"
                  value={Enquiry.message}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-12 mb-4 px-0 col-lg-12 captchdisplay">
              <p
                className="me-2 d-flex justify-content-between captchvaluesize captcha-copy-disable"
                style={{
                  color: "#000000",
                  background: "#ffffffe0",
                  border: "2px dotted #ced4da",
                  padding: "14px 15px",
                }}
              >
                {captcha_number}
                <span
                  onClick={() => {
                    setRotatecaptcha("iorotate");
                    Genratecaptcha();
                  }}
                  className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
                >
                  <IoSync className="aButtonmani rotatemani" />
                </span>
              </p>
              <input
                type="captcha"
                className="form-control captchvaluesize inputzeroborder-radius mani_captcha captchafont"
                placeholder="Enter Captcha Code *"
                name="captcha"
                value={Enquiry.captcha}
                onChange={(e) =>
                  setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                }
              />
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div class="form-group d-flex justify-content-center">
                  <button
                    type="button"
                    className="theme-btn btn-style-one clearfix d-flex justify-content-center"
                    onClick={() => {
                      if (Enquiry.name != "") {
                        if (
                          Enquiry.email == "" ||
                          Enquiry.EmailRegex.test(Enquiry.email)
                        ) {
                          if (Enquiry.mobile != "") {
                            if (Enquiry.mobile.length == 10) {
                              if (
                                Enquiry.captcha.toString() ==
                                captcha_number.toString()
                              ) {
                                setLoader(true);
                                const htmlMail = ManichetinadEnquiry_formate();
                                PostApiCall.postRequest(
                                  {
                                    mailtype: mailtype,
                                    mail: htmlMail,
                                    clientid: clientid,
                                    name: Enquiry.name,
                                    mobile: Enquiry.mobile,
                                    email: Enquiry.email,
                                    message: Enquiry.message,
                                  },
                                  "NewEnquiryMailerApi"
                                ).then((results2) =>
                                  results2.json().then((obj2) => {
                                    if (
                                      results2.status == 200 ||
                                      results2.status == 201
                                    ) {
                                      setLoader(false);
                                      Notiflix.Notify.Success(
                                        "Thank you, our team will contact you shortly!"
                                      );
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 3000);
                                    }
                                  })
                                );
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter valid captcha"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure(
                                "Please enter valid mobile number"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter mobile number"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter valid email");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter name");
                      }
                    }}
                  >
                    <span className="btn-wrap">
                      <span className="text-one">send your message</span>
                      <span className="text-two">
                        {Loader == true ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ width: "1.4rem", height: "1.4rem" }}
                            >
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          "send your message"
                        )}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default EnquiryManichetinad;
