import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./templeton.css";
const TempletonEnquiry = () => {
    // Adults
    const [TempletonCourseSelect, setTempletonCourseSelect] = useState("");
    const [TempletonCourseOption, setTempletonCourseOption] = useState([
        { Key: 1, Value: "Spoken English" },
        { Key: 2, Value: "IELTS" },
        { Key: 3, Value: "TOEFL" },
        { Key: 4, Value: "BEC" },
        { Key: 5, Value: "GRE" },
        { Key: 6, Value: "Foreign Language" },
    ]);
    const [selectedServices, setSelectedServices] = React.useState();
    const [selectedServicespackage, setSelectedServicespackage] = React.useState();
    const [mailtype, setType] = useState("");
    const [clientid, setClientId] = useState("");
    const mailtypefetchurl = useParams();
    const [Rotatecaptcha, setRotatecaptcha] = useState(false);
    // state for loader
    const [Loader, setLoader] = useState(false);
    const [Enquiry, setEnquiry] = useState({
        name: "",
        email: "",
        mobile: "",
        message: "",
        captcha: "",
        NumRegex: /^[0-9]*$/,
        EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    });
    function TempletonEnquiry_formate() {
        return (
            '<html><head><meta charset="utf-8"><title>TEMPLETON | ENQUIRY FORM</title></head>' +
            '<body style="font-family: sans-serif">' +
            '<div style="width: 100%; margin: auto">' +
            '<div style="background-color: #8080800d; padding: 20px">' +
            '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Ma’am, </h2>' +
            "<p style='font-size:14px;color: black;'>I hope this email finds you well. This email is to inform you that we have received an enquiry on the website from a client regarding the course.</p>" +
            "<p style='font-size:14px;color: black;'>Here are the details of the client and the course they chose:</p>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.name +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.mobile +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.email +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Vehicle Model</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            TempletonCourseSelect +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex">' +
            '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%">' +
            Enquiry.message +
            "</div>" +
            "</div>" +
            "<br>" +
            "<p style='font-size:14px;color: black;'>We have acknowledged the client's enquiry. You can follow up with the client.</p>" +
            "<p style='font-size:14px;color: black;'>We request you to kindly get in touch with the client and assist them with any further queries or concerns that they may have.</p>" + "<p style='text-align: left;font-size:15px;color: black;margin-top:'30px'>Thank you</p>" +
            "</div></div>" +
            "</body>" +
            "</html>"
        );
    }
    useEffect(() => {
        if (Rotatecaptcha == "") {
            setRotatecaptcha("iorotatevig");
        }
    }, [Rotatecaptcha]);
    function Genratecaptcha() {
        var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
            .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
        setcaptcha_number(reloadcapcha);
        setRotatecaptcha(!Rotatecaptcha);
    }
    const [captcha_number, setcaptcha_number] = useState("");
    useEffect(() => {
        setcaptcha_number(
            Math.floor(100000 + Math.random() * 90000000)
                .toString(36)
            [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
        );
    }, []);
    // notiflix message position
    useEffect(() => {
        Notiflix.Notify.Init({
            width: "280px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
            clickToClose: true,
            showOnlyTheLastOne: true,
            useFontAwesome: true,
            fontSize: "15px",
            timeout: 4000,
        });
    }, []);
    useEffect(() => {
        setType(mailtypefetchurl.mailtype);
        setClientId(mailtypefetchurl.clientid);
    }, []);
    return (
        <div className="container-fluid form-border p-sm-5 p-2 templemain">
            <form className="row needs-validation">
                <div className="mb-4 col-lg-6 col-12">
                    <input type="text" className="form-control" id="Name" placeholder="Name*" name="name" value={Enquiry.name} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                </div>
                <div className="mb-4 col-lg-6 col-12">
                    <input type="email" className="form-control" id="email_id" placeholder="Email" name="email" value={Enquiry.email} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                </div>
                <div className="mb-4 col-lg-6 col-12">
                    <input
                        required
                        type="text"
                        className="form-control"
                        id="MobileNumber"
                        placeholder="Mobile Number*"
                        name="mobile"
                        value={Enquiry.mobile}
                        onChange={(e) => {
                            if (e.target.value.length <= 10)
                                setEnquiry({
                                    ...Enquiry,
                                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                                });
                        }}
                    />
                </div>

                <div className="mb-4 col-lg-6 col-12">
                    <select
                        name="consulting"
                        className="select reason-option minimal bg-white form-control inputselect templeselect"
                        placeholder="Course Intersted in*"
                        style={{ borderRadius: "0px !important" }}
                        value={TempletonCourseSelect}
                        onChange={(e) => {
                            setTempletonCourseSelect(e.target.value);
                        }}
                    >
                        <option key="" value="">
                            ---- Course Intersted In ----
                        </option>
                        {TempletonCourseOption.map((data, index) => {
                            return (
                                <option key={data.Key} value={data.Value}>
                                    {data.Value}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="mb-4 mb-4 col-12">
                    <textarea
                        className="form-control"
                        id="Message"
                        placeholder="Message"
                        name="message"
                        value={Enquiry.message}
                        onChange={(e) =>
                            setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                        }
                    ></textarea>
                </div>

                <div className="col-12">
                    <div className="d-flex">
                        <p
                            className="me-2 p-2 ttborder templeselect cngcaptchsize d-flex justify-content-between captcha-copy-disable"
                            style={{
                                color: "#000000",
                                borderRadius: "0.25rem",
                                userSelect: "none",
                                alignItems: 'center'
                            }}
                        >
                            {captcha_number}
                            <span
                                onClick={() => {
                                    setRotatecaptcha("iorotatevig");
                                    Genratecaptcha();
                                }}
                                className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
                            >
                                <IoSync className="aButtonvig rotate" />
                            </span>
                        </p>
                        <input type="captcha" className="form-control" placeholder="Captcha Code*" name="captcha" value={Enquiry.captcha} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                    </div>
                </div>
                <div className="col-md-4 mt-4 col-lg-4">
                    <button
                        className="templeton-enquiry-btn"
                        type="button"
                        onClick={() => {
                            if (Enquiry.name != "") {
                                if (Enquiry.email == "" || Enquiry.EmailRegex.test(Enquiry.email)) {
                                    if (Enquiry.mobile != "") {
                                        if (Enquiry.mobile.length == 10) {
                                            if (TempletonCourseSelect != "") {
                                                if (Enquiry.captcha.toString() == captcha_number.toString()) {
                                                    setLoader(true);
                                                    const htmlMail = TempletonEnquiry_formate();
                                                    PostApiCall.postRequest(
                                                        {
                                                            mailtype: mailtype,
                                                            mail: htmlMail,
                                                            clientid: clientid,
                                                            name: Enquiry.name,
                                                            mobile: Enquiry.mobile,
                                                            email: Enquiry.email,
                                                            message: Enquiry.message,
                                                        },
                                                        "NewEnquiryMailerApi"
                                                    ).then((results2) =>
                                                        results2.json().then((obj2) => {
                                                            if (results2.status == 200 || results2.status == 201) {
                                                                setLoader(false);
                                                                Notiflix.Notify.Success("Thank you, our team will contact you shortly!");
                                                                setTimeout(() => {
                                                                    window.location.reload();
                                                                }, 3000);
                                                                // window.location.reload();
                                                            }
                                                        })
                                                    );
                                                } else {
                                                    Notiflix.Notify.Failure("Please enter valid captcha");
                                                }
                                            } else {
                                                Notiflix.Notify.Failure("Please select course");
                                            }
                                        } else {
                                            Notiflix.Notify.Failure("Please enter valid mobile number");
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("Please enter mobile number");
                                    }
                                } else {
                                    Notiflix.Notify.Failure("Please enter valid email");
                                }
                            } else {
                                Notiflix.Notify.Failure("Please enter name");
                            }
                        }}
                    >
                        {Loader == true ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status" style={{ width: "1.4rem", height: "1.4rem" }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            "Register Now"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};
export default TempletonEnquiry;
