import DOMPurify from "dompurify";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdEdit } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";

import { HiOutlineSearch } from "react-icons/hi";
import { IoIosAddCircle } from "react-icons/io";
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import GetApiCall from "../../GETAPI";
const Skeletonbloglist = () => {
  return (
    <Card>
      <div>
        <Row className="justify-content-between mb-0 pb-0">
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
          <Col xs="3" className="px-3">
            <div className="Bechofy-text pt-3 pb-0 px-2">
              <h4
                className="Bechofy-text pb-0 mb-0"
                style={{ color: "#495057" }}
              >
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between py-0">
          <Col xs="12" className="px-3">
            <div className="Bechofy-text py-3 px-2">
              <h4 className="Bechofy-text" style={{ color: "#495057" }}>
                <Skeleton className="py-2" />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      {/* *********** Card *********** */}
      <Card>
        <CardBody className="pt-0">
          <Row>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                          <br />
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                          <br />
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col xs="12" xl="4" lg="6" md="12">
              <CardBody>
                <Row className="card-row">
                  <Col sm="6">
                    <div className="imagecard">
                      <div className="form-group ">
                        <label htmlFor="sw-arrows-first-name">
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                          <br />
                          <Skeleton
                            className="mx-0"
                            style={{ height: "20px", width: "200px" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3 px-3 justify-content-between">
                  <Col sm="3" className="text-end px-0">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                  <Col sm="8" className="text-end d-flex justify-content-end">
                    <Skeleton className="py-2 px-5" />
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Card>
  );
};

class BlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchInput: "",
      BlogData: [],
      ViewClick: "true",
      open: false,
      Status: "Active",
      Name: "",
      openedit: false,
      Id: null,
      BlogName: null,
      count: "",
      loader: true,
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    // Notiflix.Loading.Dots("");
    GetApiCall.getRequest("GetBlogArticle").then((resultdes) =>
      resultdes.json().then((obj) => {
        //
        this.setState({
          BlogData: obj.data,
          count: obj.data.length,
          loader: false,
        });
        Notiflix.Loading.Remove();
      })
    );
  }
  // grid search
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content pb-0">
          <Container fluid>
            <Breadcrumbs
              title={"Global Trendz"}
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              )
                .replace("-", " ")
                .replace("_", " ")}
              urlPath={"/Blog-Master"}
            />

            <div className="row">
              <div className="col-12">
                <Card>
                  <CardBody className="pt-0 mt-4">
                    <Media>
                      <Media body className="w-100">
                        <Row>
                          <Col xl="12">
                            <Card className="overflow-hidden">
                              <div className="gt-enquiry-bg-soft-pink">
                                <Row>
                                  <Col xs="6">
                                    <div className="gt-enquiry-text p-3">
                                      <h5 className="gt-enquiry-text text-white">
                                        <h5
                                          className="Bechofy-text pb-0 mb-0 text-white"
                                          id="upload-card"
                                        >
                                          {this.state.count == 0 ? (
                                            "Blog List"
                                          ) : (
                                            <>Blog List [{this.state.count}]</>
                                          )}
                                        </h5>
                                      </h5>
                                    </div>
                                  </Col>

                                  <Col
                                    xs="6"
                                    className="text-end text-white d-flex align-items-center justify-content-end"
                                  >
                                    <div className="gt-enquiry-text px-2">
                                      <Link
                                        className="btn align-items-center btn gt-enquiry-btn2 p-2 m-0"
                                        to={{
                                          pathname: `/blogs`,
                                          state: {
                                            blogid: 0,
                                          },
                                        }}
                                      >
                                        <button className="btn align-items-center btn Bechofy-btn AddNewBtn p-0 m-0">
                                          <span className="">
                                            <IoIosAddCircle
                                              className="btnicons fs-6"
                                              style={{ marginRight: "5px" }}
                                            />
                                          </span>
                                          Add New Blog{" "}
                                        </button>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>

                              <div className="page-content mt-0 p-0">
                                <Container fluid>
                                  <Row>
                                    <Col xl="12" className="p-0">
                                      {this.state.loader == true ? (
                                        <>
                                          <Skeletonbloglist />
                                        </>
                                      ) : (
                                        <div
                                          className="overflow-hidden"
                                          style={{ padding: "10px 20px" }}
                                        >
                                          <div className="row my-1 ">
                                            <div className="col-12">
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span
                                                    className="input-group-text"
                                                    id="basic-addon1"
                                                  >
                                                    <span className="">
                                                      <HiOutlineSearch className="btnicons" />
                                                    </span>
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Type the search phrase you wish to search within the grid"
                                                  aria-label="Search"
                                                  aria-describedby="basic-addon1"
                                                  onChange={(e) =>
                                                    this.seachBarHandler(e)
                                                  }
                                                  value={this.state.searchInput}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <Row className="mt-4">
                                            <Col xl="12">
                                              <Row>
                                                {this.state.BlogData.filter(
                                                  (data) => {
                                                    // in case of an empty searchbar
                                                    if (
                                                      this.state.searchInput ==
                                                      ""
                                                    ) {
                                                      return data; // return the whole data
                                                    }
                                                    if (
                                                      this.state.searchInput !==
                                                      "" &&
                                                      (`${data.fld_title
                                                        ? data.fld_short_description.toLowerCase()
                                                        : ""
                                                        }`.includes(
                                                          this.state.searchInput.toLowerCase()
                                                        ) ||
                                                        `${data.fld_short_description
                                                          ? data.fld_short_description.toLowerCase()
                                                          : ""
                                                          }`.includes(
                                                            this.state.searchInput.toLowerCase()
                                                          ))
                                                    ) {
                                                      return data;
                                                    }
                                                  }
                                                ).map((data, i) => (
                                                  <Col xs="6">
                                                    <Card className="blog-list-card-size">
                                                      <CardBody className="attribut-list-border">
                                                        <Row className="card-row">
                                                          <Col
                                                            sm="6"
                                                            className="px-0 mb-3"
                                                          >
                                                            <h6>
                                                              <b>
                                                                {" "}
                                                                Last Updated on{" "}
                                                              </b>
                                                              {moment(
                                                                data.fld_created_on
                                                              ).format(
                                                                "DD/MM/YYYY"
                                                              )}{" "}
                                                            </h6>
                                                          </Col>
                                                          <Col
                                                            sm="6"
                                                            className="px-0 mb-3 text-right"
                                                          >
                                                            <Form>
                                                              <Form.Check
                                                                type="switch"
                                                                checked={
                                                                  data.fld_status ===
                                                                    "Active"
                                                                    ? true
                                                                    : false
                                                                }
                                                                id="custom-switch"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  PostApiCall.postRequest(
                                                                    {
                                                                      status:
                                                                        e.target
                                                                          .checked ===
                                                                          true
                                                                          ? "Active"
                                                                          : "InActive",
                                                                      blogid:
                                                                        data.fld_id,
                                                                    },
                                                                    "UpdateBlogStatus"
                                                                  ).then(
                                                                    (results) =>
                                                                      results
                                                                        .json()
                                                                        .then(
                                                                          (
                                                                            obj
                                                                          ) => {
                                                                            if (
                                                                              results.status ==
                                                                              200 ||
                                                                              results.status ==
                                                                              201
                                                                            ) {
                                                                              Notiflix.Notify.Success(
                                                                                "Status Updated Successfully"
                                                                              );
                                                                              GetApiCall.getRequest(
                                                                                "GetBlogArticle"
                                                                              ).then(
                                                                                (
                                                                                  resultdes
                                                                                ) =>
                                                                                  resultdes
                                                                                    .json()
                                                                                    .then(
                                                                                      (
                                                                                        obj
                                                                                      ) => {
                                                                                        //
                                                                                        this.setState(
                                                                                          {
                                                                                            BlogData:
                                                                                              obj.data,
                                                                                            count:
                                                                                              obj
                                                                                                .data
                                                                                                .length,
                                                                                            loader: false,
                                                                                          }
                                                                                        );
                                                                                        Notiflix.Loading.Remove();
                                                                                      }
                                                                                    )
                                                                              );
                                                                              Notiflix.Loading.Remove();
                                                                            } else {
                                                                              Notiflix.Notify.Failure(
                                                                                obj.data
                                                                              );
                                                                              Notiflix.Loading.Remove();
                                                                            }
                                                                          }
                                                                        )
                                                                  );
                                                                }}
                                                                label="Active"
                                                              />
                                                            </Form>
                                                          </Col>
                                                          <Col
                                                            sm="12"
                                                            className="px-0 mb-4"
                                                          >
                                                            <div className="img-parent">
                                                              <img
                                                                src={
                                                                  data.fld_blogimage
                                                                }
                                                                alt="Blog Image"
                                                                className="w-100 h-100 blog-image-main"
                                                              />
                                                            </div>
                                                          </Col>
                                                          <Col sm="12">
                                                            <p>
                                                              <strong>
                                                                {data.fld_title}
                                                              </strong>
                                                            </p>
                                                            <p className="blog-description my-1">
                                                              <div
                                                                className="blog-description-size"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    DOMPurify.sanitize(
                                                                      data.fld_short_description
                                                                    ),
                                                                }}
                                                              />
                                                            </p>
                                                            <hr className="my-3" />
                                                          </Col>

                                                          <Col sm="6">
                                                            <div className="form-group mb-0">
                                                              <p className="mb-0">
                                                                <b>Status</b> -{" "}
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      data.fld_status ==
                                                                        "Active"
                                                                        ? "green"
                                                                        : "red",
                                                                  }}
                                                                >
                                                                  {
                                                                    data.fld_status
                                                                  }
                                                                </span>
                                                              </p>
                                                            </div>
                                                          </Col>
                                                          <Col sm="6">
                                                            <Link
                                                              to={{
                                                                pathname: `/blogs`,
                                                                state: {
                                                                  blogid:
                                                                    data.fld_id,
                                                                },
                                                              }}
                                                            >
                                                              <button className="btn Bechofy-btn waves-effect waves-light btn-sm float-right p-2 bg-dark text-white">
                                                                <span className="">
                                                                  <MdEdit className="btnicons fs-5" />
                                                                </span>
                                                                Edit Blog
                                                              </button>
                                                            </Link>
                                                          </Col>
                                                        </Row>
                                                      </CardBody>
                                                    </Card>
                                                  </Col>
                                                ))}
                                              </Row>
                                            </Col>
                                          </Row>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default BlogList;
