import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./shivnadi.css";
const ShivnadiEnquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);

  function ShivnadiEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>SRI SIVANADI ASTROLOGY CENTER | ENQUIRY</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="width: 100%; margin: auto">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 30%" src="http://onlineshivanaadi.com/assets/images/logo-2.png"/>' +
      "</div>" +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Mam, </h2>' +
      "<p style='font-size:14px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.message +
      "</div>" +
      "</div>" +
      "<br>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div className="container-fluid p-0 m-0 shivnadienquirybg">
      <form>
        <div className="form-group mt-0">
          <label className="labalcolor">
            Full Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control form-controlshiv shivfontcolor"
            id="Name"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label className="labalcolor">Email Address</label>
          <input
            type="text"
            className="form-control form-controlshiv shivfontcolor"
            id="email_id"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label className="labalcolor">
            Mobile Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control form-controlshiv shivfontcolor"
            id="MobileNumber"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="form-group">
          <label className="labalcolor">
            Message <span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control form-controlshiv shivfontcolor"
            id="Message"
            name="message"
            value={Enquiry.message}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <p
            className=" p-2 border-dotted w-50 d-flex justify-content-between captcha-copy-disable"
            style={{ border: "3px dotted #ffffff8a", color: "white" }}
          >
            {captcha_number}
            <span
              onClick={() => {
                setRotatecaptcha("iorotate");
                Genratecaptcha();
              }}
              className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
            >
              <IoSync className="aButton rotate" />
            </span>
          </p>
          <label className="labalcolor">
            Captcha Code <span className="text-danger">*</span>
          </label>
          <input
            type="captcha"
            className="form-control form-controlshiv shivfontcolor"
            name="captcha"
            value={Enquiry.captcha}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <a
          href="#"
          className="as_btn shivfontcolor"
          type="button"
          onClick={() => {
            if (Enquiry.name != "") {
              if (
                Enquiry.email == "" ||
                Enquiry.EmailRegex.test(Enquiry.email)
              ) {
                if (Enquiry.mobile != "") {
                  if (Enquiry.mobile.length == 10) {
                    if (Enquiry.message != "") {
                      if (
                        Enquiry.captcha.toString() == captcha_number.toString()
                      ) {
                        setLoader(true);
                        const htmlMail = ShivnadiEnquiry_formate();
                        PostApiCall.postRequest(
                          {
                            mailtype: mailtype,
                            mail: htmlMail,
                            clientid: clientid,
                            name: Enquiry.name,
                            mobile: Enquiry.mobile,
                            email: Enquiry.email,
                            message: Enquiry.message,
                          },
                          "NewEnquiryMailerApi"
                        ).then((results2) =>
                          results2.json().then((obj2) => {
                            if (
                              results2.status == 200 ||
                              results2.status == 201
                            ) {
                              setLoader(false);
                              Notiflix.Notify.Success("Thank you, our team will contact you shortly!");
                              // window.location.reload();
                              setTimeout(() => {
                                window.location.reload();
                              }, 3000);
                            }
                          })
                        );
                      } else {
                        Notiflix.Notify.Failure("Please enter valid captcha");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter message");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid mobile number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter mobile number");
                }
              } else {
                Notiflix.Notify.Failure("Please enter valid email");
              }
            } else {
              Notiflix.Notify.Failure("Please enter name");
            }
          }}
        >
          {Loader == true ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border"
                role="status"
                style={{ width: "1.4rem", height: "1.4rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            "Submit"
          )}
        </a>
      </form>
    </div>
  );
};
export default ShivnadiEnquiry;
