import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./pitstop.css";
const Pitstopenquiry = () => {
  const data = {
    services: [
      {
        name: "Car Spa",
        key: 1,
        category: [
          {
            name: "Foam Wash",
            key: 1,
          },
          {
            name: "Rubbing Polish",
            key: 2,
          },
          {
            name: "Interior Detailing",
            key: 3,
          },
          {
            name: "Ceramic Coating",
            key: 4,
          },
          {
            name: "Ppf (paint Protection Film)",
            key: 5,
          },
          {
            name: "Underchassis Coating",
            key: 6,
          },
          {
            name: "Door Rust Protection",
            key: 7,
          },
        ],
      },
      {
        name: "Service",
        key: 2,
        category: [
          { name: "General Maintenance", key: 1 },
          { name: "Engine Overhauling", key: 2 },
          { name: "Car Ac Service", key: 3 },
          { name: "Electrical Services", key: 4 },
          { name: "Brakdown Assistance ", key: 5 },
          { name: "Vehicle Scaning" },
        ],
      },
      {
        name: "Body Shop",
        key: 3,
        category: [
          { name: "Door Panel & Bumper Painting", key: 1 },
          { name: "Full Body Painting ", key: 2 },
          { name: "Alloy Wheel Painting", key: 3 },
          { name: "Rusted Panel Repair", key: 4 },
          { name: "Accidental Restoration", key: 5 },
          { name: "Water Leakage Arrest", key: 6 },
        ],
      },
      {
        name: "Customizing & Modifications",
        key: 4,
        category: [
          {
            name: "Water Transfer Printing (hydrographics)",
            key: 1,
          },
          { name: "Hydro Chrome", key: 2 },
          { name: "Body Kit", key: 3 },
          { name: "Custom Wrapping", key: 4 },
          { name: "Glow Painting", key: 5 },
          { name: "Colour Changing Paint", key: 6 },
        ],
      },
      {
        name: "Car Accessories",
        key: 5,
        category: [
          {
            name: "Audio System",
            key: 1,
          },
          { name: "Uv Protection Film", key: 2 },
          { name: "Seat Covers", key: 3 },
          { name: "Light Upgrade (led/hid) ", key: 4 },
          { name: "Sound Damping", key: 5 },
          { name: "Perfume", key: 6 },
        ],
      },
      {
        name: "Cng Fitting",
        key: 1,
        category: [{ name: "Cng Fitting", key: 1 }],
      },
    ],
  };
  const [selectedServices, setSelectedServices] = React.useState();
  const [selectedServicespackage, setSelectedServicespackage] =
    React.useState();
  const availableService = data.services.find(
    (c) => c.name === selectedServices
  );
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    vehiclemodel: "",
    requirement: "",
    location: "",
    mobile: "",
    captcha: "",
    NumRegex: /^[0-9]*$/,
  });
  function Pitstopenquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>PIT STOP | ENQUIRY FORM</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%; margin: auto">' +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 250px;height:100px;margin-top:15px" src="http://www.thepitstopcarcare.com/assets/images/logo.png"/>' +
      "</div>" +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Ma’am, </h2>' +
      "<p style='font-size:14px;color: black;'>I hope this email finds you well. This email is to inform you that we have received an enquiry on the website from a client regarding the Service.</p>" +
      "<p style='font-size:14px;color: black;'>Here are the details of the client and the services they chose:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Vehicle Model</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.vehiclemodel +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Service Category </b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      selectedServices +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Service</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      selectedServicespackage +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Area</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.location +
      "</div>" +
      "</div>" +
      "<br>" +
      "<p style='font-size:14px;color: black;'>We have acknowledged the client's enquiry. You can follow up with the client.</p>" +
      "<p style='font-size:14px;color: black;'>We request you to kindly get in touch with the client and assist them with any further queries or concerns that they may have.</p>" +
      "<p><strong>Thank You<strong></p>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div className="container-fluid form-border p-sm-5 p-2 ptstopmain">
      <form className="row needs-validation">
        <div className="col-md-6 mb-4 col-lg-6 col-12">
          <label for="Name" className="form-label">
            Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pitstopinput"
            id="Name"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-6 col-12">
          <label for="contact_Person" className="form-label">
            Mobile Number <span className="text-danger">*</span>
          </label>
          <input
            required
            type="text"
            className="form-control pitstopinput"
            id="MobileNumber"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-md-6 mb-4 col-lg-6 col-12">
          <label for="vehiclemodel" className="form-label">
            Vehicle model <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pitstopinput"
            id="vehiclemodel"
            name="vehiclemodel"
            value={Enquiry.vehiclemodel}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>

        <div className="mb-4 col-md-6 mb-4 col-lg-6 col-12">
          <label for="location" className="form-label">
            Location <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pitstopinput"
            id="location"
            name="location"
            value={Enquiry.location}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>

        <div className="col-md-6 mb-4 col-lg-6 col-12">
          <label>Service category</label> <span className="text-danger">*</span>
          <select
            placeholder="Service"
            value={selectedServices}
            className="form-control select reason-option inputselect"
            onChange={(e) => setSelectedServices(e.target.value)}
          >
            <option value="0" key="0">
              --Service Name--
            </option>
            {data.services.map((value, key) => {
              return (
                <option value={value.name} key={key}>
                  {value.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6 mb-4 col-lg-6 col-12">
          <label>Service </label> <span className="text-danger">*</span>
          <select
            placeholder="Package"
            className="form-control select reason-option inputselect"
            value={selectedServicespackage}
            onChange={(e) => setSelectedServicespackage(e.target.value)}
          >
            <option value="" key="">
              --Service Package--
            </option>
            {availableService?.category.map((e, key) => {
              return (
                <option value={e.name} key={key}>
                  {e.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-md-6 col-lg-6 col-12">
          <label for="carcapacity" className="form-label">
            Captcha Code <span className="text-danger">*</span>
          </label>
          <div className="d-flex">
            <p
              className="me-2 p-2 border-dotted cngcaptchsize d-flex justify-content-between captcha-copy-disable pitstopinput"
              style={{
                color: "#000000",
                borderRadius: "0.25rem",
                userSelect: "none",
              }}
            >
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotatevig");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
              >
                <IoSync className="aButtonvig rotate" />
              </span>
            </p>
            <input
              type="captcha"
              className="form-control pitstopinput"
              name="captcha"
              value={Enquiry.captcha}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-md-4 mt-4 col-lg-4">
          <button
            className="deafult-btn1"
            style={{ fontSize: "15px", background: "#F89F3C" }}
            type="button"
            onClick={() => {
              if (Enquiry.name != "") {
                {
                  if (Enquiry.mobile != "") {
                    if (Enquiry.mobile.length == 10) {
                      if (Enquiry.vehiclemodel != "") {
                        if (Enquiry.location != "") {
                          if (
                            selectedServices != undefined &&
                            selectedServices != 0
                          ) {
                            if (
                              selectedServicespackage != undefined &&
                              selectedServicespackage != 0
                            ) {
                              if (
                                Enquiry.captcha.toString() ==
                                captcha_number.toString()
                              ) {
                                setLoader(true);
                                const htmlMail = Pitstopenquiry_formate();
                                PostApiCall.postRequest(
                                  {
                                    mailtype: mailtype,
                                    mail: htmlMail,
                                    clientid: clientid,
                                    name: Enquiry.name,
                                    mobile: Enquiry.mobile,
                                    email: Enquiry.email,
                                  },
                                  "NewEnquiryMailerApi"
                                ).then((results2) =>
                                  results2.json().then((obj2) => {
                                    if (
                                      results2.status == 200 ||
                                      results2.status == 201
                                    ) {
                                      setLoader(false);
                                      Notiflix.Notify.Success(
                                        "Thank you, our team will contact you shortly!"
                                      );
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 3000);
                                    }
                                  })
                                );
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter valid captcha"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure("Please Choose service");
                            }
                          } else {
                            Notiflix.Notify.Failure(
                              "Please Choose service category"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure(
                            "Please enter your location "
                          );
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter Vehicle model");
                      }
                    } else {
                      Notiflix.Notify.Failure(
                        "Please enter valid mobile number"
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter mobile number");
                  }
                }
              } else {
                Notiflix.Notify.Failure("Please enter name");
              }
            }}
          >
            {Loader == true ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              "Send Message"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
export default Pitstopenquiry;
