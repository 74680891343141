import React, { useState } from "react";

export default function BankDetails({
  setActiveTab,
  onUpdateBankInfo,
  bankInfoData,
  onFileChangeBank,
  selectedFileBank,
  basicInfoData,
  onSubmit,
}) {
  const handleFieldChange = (field, value) => {
    // specific field update
    onUpdateBankInfo({
      ...bankInfoData,
      [field]: value, // key : value
    });
  };

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="h2 text-center mb-4">Bank Details</div>
        <div className="col-12 col-md-6 mb-3">
          <label>
            Beneficiary Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control poel-vendor-input "
            id="Name"
            name="name"
            value={bankInfoData.benificiaryName}
            onChange={(e) => {
              handleFieldChange("benificiaryName", e.target.value);
            }}
          />
        </div>

        <div className="col-12 col-md-6 mb-3">
          <label>
            Bank Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control poel-vendor-input"
            id="BankName"
            name="name"
            value={bankInfoData.bankName}
            onChange={(e) => {
              handleFieldChange("bankName", e.target.value);
            }}
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label>
            Branch Name<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control poel-vendor-input"
            id="BranchName"
            name="branchname"
            value={bankInfoData.branchName}
            onChange={(e) => {
              handleFieldChange("branchName", e.target.value);
            }}
          />
        </div>

        {
          basicInfoData.vendorCountryName == "India" &&
          <div className="col-12 col-md-6 mb-3">
            <label>
              Bank IFSC Code <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control poel-vendor-input"
              id="IfscCode"
              name="ifsccode"
              value={bankInfoData.ifscCode}
              onChange={(e) => {
                handleFieldChange("ifscCode", e.target.value);
              }}
            />
          </div>
        }

        <div className="col-12 col-md-6 mb-3">
          <label>
            Bank Account Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control poel-vendor-input"
            id="AccountNumber"
            name="accountnumber"
            value={bankInfoData.accountNumber}
            onChange={(e) => {
              handleFieldChange("accountNumber", e.target.value);
            }}
          />
        </div>

        {basicInfoData.vendorCountryName !== "India" && (
          <>
            <div className="col-12 col-md-6 mb-3">
              <label>
                SWIFT <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="SwiftCode"
                name="swift"
                value={bankInfoData.swift}
                onChange={(e) => {
                  handleFieldChange("swift", e.target.value);
                }}
              />
            </div>

            <div className="col-12 col-md-6 mb-3">
              <label>
                IBAN
              </label>
              <input
                type="text"
                className="form-control poel-vendor-input"
                id="Iban"
                name="iban"
                value={bankInfoData.iban}
                onChange={(e) => {
                  handleFieldChange("iban", e.target.value);
                }}
              />
            </div>
          </>
        )}

        <div className="col-md-6 mb-3">
          <label>
            Cancelled Cheque Leaf / Bank Details in Company Letter head
            <span className="text-danger">*</span>
          </label>
          <div className="mb-3">
            <div className="input-group">
              <input
                type="file"
                className="form-control d-block poel-vendor-input"
                style={{ height: "50px !important", padding: "12px 20px" }}
                placeholder="Browse Files"
                aria-label="File"
                id="OtherDocs"
                name="otherdocs"
                onChange={onFileChangeBank}
              />
            </div>
            <p className="mt-2">File name : {selectedFileBank[0]?.name}</p>
            {/* <p>File name  : {bankInfoData.otherDocs?.name}</p> */}
          </div>
        </div>
        <div className="col-12">
          <p className="mt-1"><b><span style={{ fontSize: '16px' }}>Note</span><span className="text-danger" style={{ fontSize: '16px' }}> *</span></b><br /> Payments will be processed only with the Bank Accounts registered with us. Different bank account(s) for payment processing will not be entertained!</p>
        </div>
        <div className="col-12 mb-5 text-start d-flex justify-content-center mt-4">
          <button
            className="enquirybtnvendor text-white fs-6"
            onClick={(e) => {
              setActiveTab(
                basicInfoData.vendorCountryName == "India"
                  ? "msmeDetails"
                  : "basicInfo"
              );
            }}
            type="button"
          >
            Back
          </button>
          {/* this button needs to be changed according to the submit functionality  */}
          {basicInfoData.vendorCountryName == "India" ? (
            <button
              className="enquirybtnvendor text-white fs-6 ms-3"
              onClick={(e) => {
                setActiveTab("tdsDetails");
              }}
              type="button"
            >
              Next
            </button>
          ) : (
            <button
              className="enquirybtnvendor text-white fs-6 ms-3"
              type="button"
              onClick={() => onSubmit()}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
