import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./OuskiOt.css";
import { IoSync } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { SlCalender } from "react-icons/sl";

const OuskiOtAppointment = () => {
  // const history = useHistory();
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  const [Enquiry, setEnquiry] = useState({
    name: "",
    mobile: "",
    email: "",
    appointmentDate: null,
    message: "",
    Qution: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  const today = moment(); // Current date and time

  // Select current date to last two month
  const minDate = today.startOf("day");
  // const maxDate = today.clone().endOf("month"); // End of the current month
  const maxDate = today.clone().add(2, "months").endOf("month"); // cureent month date to next 2 month

  function OuskiOtAppointment_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Uski Ot Dental Clinic | Appointment</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="background-color: #8080800d;">' +
      '<div style="padding: 20px">' +
      // '<div style="text-align: left;width:100%"><img style="width: 210px;padding-top:20px" src="https://globaltrendz.online/uski-dental/assests/images/uski-ot-logo.png></div>' +
      '<div style=width:"100%">' +
      '<p style="text-align: left;font-size: 15px;color:#000; padding: 0;margin: 5px 0;">Dear Sir/Mam,</p>' +
      '<p style="font-size: 15px;color: black;padding: 0;margin: 5px 0;">You have received an enquiry from the website. The details are as follows:</p>' +
      `<p style="width: 100%;font-size: 15px;color: black;text-transform: capitalize;padding: 0;
      margin: 5px 0;"><b style="color: black">Name: </b>
      ${Enquiry.name}</p>` +
      `<p style="width: 100%;font-size: 15px;color: black; padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Phone No. : </b>
      ${Enquiry.mobile} </p>` +
      `<p style="width: 100%;font-size: 15px;color: black; padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Email: </b> ${Enquiry.email} </p>` +
      `<p style="width: 100%;font-size: 15px;color: black; padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Appointment Date: </b> ${moment(
        Enquiry.appointmentDate
      ).format("DDMMM, YYYY")} </p>` +
      `<p style="width: 100%; font-size: 15px;color: black;padding: 0;
      margin: 5px 0;"><b style="color: black;padding: 0;
      margin: 5px 0;">Message: </b>
      ${Enquiry.message}` +
      "<p style='font-size: 15px;color: black'><strong>Thank You</strong></p>" +
      // "</div>" +
      "</div>" +
      "</div>" +
      "</body>" +
      "</html>"
    );
  }

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-center",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);

  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  return (
    <div className="container-fluid form-border my-3 main-box-oushkiotp">
      <form className="row py-3 needs-validation">
        <div className="col-12 col-md-6 mb-4">
          <label for="Name" className="form-label oushkifont">
            Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control oushkiotboder oushkiotinput oushkifont"
            id="name"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label for="mobile_number" className="form-label oushkifont">
            Mobile Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control oushkiotboder oushkiotinput oushkifont"
            id="mobile_number"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label for="email_id" className="form-label oushkifont">
            Email Id
          </label>
          <input
            type="email"
            className="form-control oushkiotboder oushkiotinput oushkifont"
            id="email_id"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>

        <div class="col-md-6 mb-3 position-relative">
          <label for="selectdate" class="form-label oushkifont">
            Select Date <span class="text-danger"> *</span>
          </label>
          {/* <DatePicker
            className="form-control oushkiotboder oushkiotinput oushkifont"
            dateFormat="dd-MM-yyyy"
            minDate={moment().toDate()}
            selected={Enquiry.appointmentDate}
            onChange={(date) => {
              setEnquiry({ ...Enquiry, appointmentDate: date });
            }}
          /> */}

          {/* <DatePicker
            className="form-control oushkiotboder oushkiotinput oushkifont"
            dateFormat="dd-MM-yyyy"
            minDate={minDate.toDate()}
            maxDate={maxDate.toDate()}
            selected={Enquiry.appointmentDate}
            onChange={(date) => {
              setEnquiry({ ...Enquiry, appointmentDate: date });
            }}
          /> */}

          <DatePicker
            className="form-control oushkiotboder oushkiotinput oushkifont"
            dateFormat="dd-MM-yyyy"
            minDate={minDate.toDate()}
            maxDate={maxDate.toDate()}
            selected={Enquiry.appointmentDate}
            onChange={(date) => {
              setEnquiry({ ...Enquiry, appointmentDate: date });
            }}
          />
        </div>

        <div className="col-12 mb-4">
          <label for="message" className="form-label oushkifont">
            Message
          </label>
          <textarea
            type="text"
            className="form-control oushkiotboder oushkifont"
            id="message"
            name="message"
            value={Enquiry.message}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <label for="captcha" className="form-label oushkifont">
            Enter Captcha Code <span className="text-danger">*</span>
          </label>
          <div className="row">
            <div className="col-12 col-md-6 mb-2 mb-md-0">
              <p className="d-flex w-100 oushkifont oshkiotcaptchaborder captcha-copy-disable justify-content-between align-items-center">
                {captcha_number}
                <span
                  onClick={() => {
                    setRotatecaptcha("iorotatevig");
                    Genratecaptcha();
                  }}
                  className={
                    Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""
                  }
                >
                  <IoSync className="aButtonblessing rotate" />
                </span>
              </p>
            </div>
            <div className="col-12 col-md-6">
              <input
                type="captcha oushkifont"
                className="form-control oshkiotcaptchavalueborder fs-6"
                placeholder="Enter Captcha"
                name="captcha"
                value={Enquiry.captcha}
                onChange={(e) =>
                  setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 d-flex align-items-center">
          <button
            className="btn fs-6 btn-block py-2 text-white oushkiotbtnmargin ouskibtn"
            type="button"
            onClick={() => {
              if (Enquiry.name != "") {
                if (
                  Enquiry.email == "" ||
                  Enquiry.EmailRegex.test(Enquiry.email)
                ) {
                  if (Enquiry.mobile != "") {
                    if (Enquiry.mobile.length == 10) {
                      if (Enquiry.appointmentDate !== null) {
                        if (
                          Enquiry.captcha.toString() ==
                          captcha_number.toString()
                        ) {
                          const htmlMail = OuskiOtAppointment_formate();
                          Notiflix.Loading.Arrows("Please wait...");
                          PostApiCall.postRequest(
                            {
                              mailtype: "from Uski Ot Dental Clinic",
                              mail: htmlMail,
                              clientid: clientid,
                              name: Enquiry.name,
                              mobile: Enquiry.mobile,
                              // arrivaldate: moment(Enquiry.appointmentDate).format(
                              //   "DD-MM-YYYY"
                              // ),
                              arrivaldate: moment(
                                Enquiry.appointmentDate
                              ).format("DDMMM, YYYY"),
                              email: Enquiry.email,
                              message: Enquiry.message,
                            },
                            "NewEnquiryMailerApi"
                          ).then((results2) =>
                            results2.json().then((obj2) => {
                              if (
                                results2.status == 200 ||
                                results2.status == 201
                              ) {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Success(
                                  "Thank you, our team will contact you shortly!"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 3000);
                              }
                            })
                          );
                        } else {
                          Notiflix.Notify.Failure("Please enter valid captcha");
                        }
                      } else {
                        Notiflix.Notify.Failure(
                          "Please choose appointment date"
                        );
                      }
                    } else {
                      Notiflix.Notify.Failure(
                        "Please enter valid mobile number"
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter mobile number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter valid email");
                }
              } else {
                Notiflix.Notify.Failure("Please enter name");
              }
            }}
          >
            Book An Appointment
          </button>
        </div>
      </form>
    </div>
  );
};
export default OuskiOtAppointment;
