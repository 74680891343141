import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./Emcistyle.css";
import { IoSync } from "react-icons/io5";
const EmciEnquiry = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    companyname: "",
    name: "",
    Designation: "",
    Address: "",
    city: "",
    State: "",
    country: "",
    PinCode: "",
    TelephoneNumber: "",
    mobile: "",
    FaxNumber: "",
    email: "",
    WebAddress: "",
    BussinessType: "",
    RequireProduct: "",
    Information: "",
    ImmediateProduct: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function EmciEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>EMCI PLUS</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%; margin: auto">' +
      '<div style="text-align: left; margin-bottom: 10px">' +
      '<img style="width: 20%" src="https://emciplus.com/assets/img/demos/it-services/logo.png"/>' +
      "</div>" +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h2 style="text-align: left;font-size: 14px;color: black;">Dear EMCI PLUS,</h2>' +
      "<p style='font-size: 14px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color: black">Company Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.companyname +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color: black">Contact Person</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Designation & Department</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.Designation +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Address</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.Address +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">City</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.city +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">State</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.State +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Country</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.country +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Website Address</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.WebAddress +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Type of Business</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.BussinessType +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Product Category of Interest for which you would require information from time to time via email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.RequireProduct +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">information do you want from us</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.Information +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color: black">Kindly mention your specific product requirements for which you require immediate assistance</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.ImmediateProduct +
      "</div>" +
      "</div>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div className="container form-border my-5">
      <form className="row g-3 needs-validation" novalidate>
        <div className="col-md-12">
          <label for="company_name" className="form-label">
            Company Name
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="company_name"
            name="companyname"
            value={Enquiry.companyname}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="contact_Person" className="form-label">
            Contact Person
          </label>
          <input
            required
            type="text"
            className="form-control emciborderinput"
            id="contact_Person"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="designation" className="form-label">
            Designation & Department
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="designation"
            name="Designation"
            value={Enquiry.Designation}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="address" className="form-label">
            Address
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="address"
            name="Address"
            value={Enquiry.Address}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="city" className="form-label">
            City
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="city"
            name="city"
            value={Enquiry.city}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="state" className="form-label">
            State
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="state"
            name="State"
            value={Enquiry.State}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="country" className="form-label">
            Country
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="country"
            name="country"
            value={Enquiry.country}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="pin_code" className="form-label">
            Pin Code
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="pin_code"
            name="PinCode"
            value={Enquiry.PinCode}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="telephone_number" className="form-label">
            Telephone Number
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="telephone_number"
            name="TelephoneNumber"
            value={Enquiry.TelephoneNumber}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="mobile_number" className="form-label">
            Mobile Number <span className="text-danger">*</span>
          </label>
          <input
            required
            type="text"
            className="form-control emciborderinput"
            id="mobile_number"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-md-12">
          <label for="fax_number" className="form-label">
            Fax Number
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="fax_number"
            name="FaxNumber"
            value={Enquiry.FaxNumber}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="email_id" className="form-label">
            Enter your email
          </label>
          <input
            type="email"
            className="form-control emciborderinput"
            id="email_id"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-6">
          <label for="web_address" className="form-label">
            Website Address
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="web_address"
            name="WebAddress"
            value={Enquiry.WebAddress}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="bussiness_type" className="form-label">
            Type of Business
          </label>
          <select
            id="bussiness_type"
            name="BussinessType"
            value={Enquiry.BussinessType}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
            className="form-select select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
          >
            <option value="Select">---- Select ----</option>
            <option value="Test House">Test House</option>
            <option value="Defence Lab">Defence Lab</option>
            <option value="Manufacturing Sector">Manufacturing Sector</option>
            <option value="Power / Electricity Utility">
              Power / Electricity Utility
            </option>
            <option value="space / Aeronautics">space / Aeronautics</option>
            <option value="Telecom /IT">Telecom / IT</option>
            <option value="Semiconductor">Semiconductor</option>
            <option value="Education Institute">Education Institute</option>
            <option value="Public Sector Enterprise">
              Public Sector Enterprise
            </option>
            <option value="Private Sector Enterprise">
              Private Sector Enterprise
            </option>
            <option value="small Scale Industry">small Scale Industry</option>
            <option value="Dealer / Agent / Trader">
              Dealer / Agent / Trader
            </option>
            <option value="Exporter / Importer">Exporter / Importer</option>
            <option value="Service Center">Service Center</option>
            <option value="Individual">Individual</option>
            <option value="Others...">Others...</option>
          </select>
        </div>
        <div className="col-md-12">
          <label for="require_product" className="form-label">
            Product Category of Interest for which you would require information
            from time to time via email
          </label>
          <select
            id="require_product"
            name="RequireProduct"
            value={Enquiry.RequireProduct}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
            className="form-select select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
          >
            <option value="Select">---- Select ----</option>
            <option value="Absorbers">Absorbers</option>
            <option value="Amplifier">Amplifier</option>
            <option value="Anechoic chamber">Anechoic chamber</option>
            <option value="Antenna">Antenna</option>
            <option value="Antenna Mast">Antenna Mast</option>
            <option value="Attenuators">Attenuators</option>
            <option value="Avionics Test system for RTCA / DO 160(Sec 17/19/22/25)">
              Avionics Test system for RTCA / DO 160(Sec 17/19/22/25)
            </option>
            <option value="capacitor Test System">capacitor Test System</option>
            <option value="Common Mode Disturbance Tester(61000-4-16)">
              Common Mode Disturbance Tester(61000-4-16)
            </option>
            <option value="Conducted Immunity RF Test System(61000-4-16)">
              Conducted Immunity RF Test System(61000-4-16)
            </option>
            <option value="coupling Decoupling Networks">
              coupling Decoupling Networks
            </option>
            <option value="Damped Oscilltory Wave Tester(61000-4-10/18)">
              Damped Oscilltory Wave Tester(61000-4-10/18)
            </option>
            <option value="Directional Couplers">Directional Couplers</option>
            <option value="EFT Tester(61000-4-4)">EFT Tester(61000-4-4)</option>
            <option value="Electric Field Probe">Electric Field Probe</option>
            <option value="EM Field Sensors">EM Field Sensors</option>
            <option value="EM clamp">EM clamp</option>
            <option value="EMI Receivers">EMI Receivers</option>
            <option value="EMI / EMC Software">EMI / EMC Software</option>
            <option value="ESD Tester(61000-4-2)">ESD Tester(61000-4-2)</option>
            <option value="GTEM Cells">GTEM Cells</option>
            <option value="Harmonic / Flicker Test Systems(61000-3-2/3)">
              Harmonic / Flicker Test Systems(61000-3-2/3)
            </option>
            <option value="Harmonic / Flicker Test Systems(61000-4-7/13/14/17)">
              Harmonic / Flicker Test Systems(61000-4-7/13/14/17)
            </option>
            <option value="Honeycomb">Honeycomb</option>
            <option value="Insulation / Impulse Test Systems">
              Insulation / Impulse Test Systems
            </option>
            <option value="LISN">LISN</option>
            <option value="Marx Generators">Marx Generators</option>
            <option value="MIL Test System(RS)">MIL Test System(RS)</option>
            <option value="MIL Test System(RE)">MIL Test System(RE)</option>
            <option value="MIL Test System(CS)">MIL Test System(CS)</option>
            <option value="MIL Test System(CE)">MIL Test System(CE)</option>
            <option value="PCI / CWI Test System">PCI / CWI Test System</option>
            <option value="Power Magnetic Field Tester(61000-4-8)">
              Power Magnetic Field Tester(61000-4-8)
            </option>
            <option value="Pre Amplifiers">Pre Amplifiers</option>
            <option value="Protection Device Test Systems">
              Protection Device Test Systems
            </option>
            <option value="Pulse Magnetic Field Tester(61000-4-9)">
              Pulse Magnetic Field Tester(61000-4-9)
            </option>
            <option value="Radiated Immunity Test System(61000-4-3)">
              Radiated Immunity Test System(61000-4-3)
            </option>
            <option value="RF Power Meter">RF Power Meter</option>
            <option value="RF Relay Switching Unit">
              RF Relay Switching Unit
            </option>
            <option value="RFI Trap">RFI Trap</option>
            <option value="Shielded Audio System">Shielded Audio System</option>
            <option value="Shielded Camera">Shielded Camera</option>
            <option value="Shielded Optical Converters">
              Shielded Optical Converters
            </option>
            <option value="Signal Generators">Signal Generators</option>
            <option value="Strip Lines">Strip Lines</option>
            <option value="Surge Protection Filters">
              Surge Protection Filters
            </option>
            <option value="Surge / Telecom / ring Wave Tester(61000-4-5/12)">
              Surge / Telecom / ring Wave Tester(61000-4-5/12)
            </option>
            <option value="TEM Cells">TEM Cells</option>
            <option value="Test Tables">Test Tables</option>
            <option value="Turntable">Turntable</option>
            <option value="Voltage Dips / Interrupts Tester(61000-4-11/29)">
              Voltage Dips / Interrupts Tester(61000-4-11/29)
            </option>
            <option value="Waveguides">Waveguides</option>
          </select>
        </div>
        <div className="col-md-12">
          <label for="information" className="form-label">
            Information do you want from us?
          </label>
          <input
            type="text"
            className="form-control emciborderinput"
            id="information"
            name="Information"
            value={Enquiry.Information}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-md-12">
          <label for="immediate_product" className="form-label">
            Kindly mention your specific product requirements for which you
            require immediate assistance
          </label>
          <textarea
            className="form-control emciborderinput"
            rows="3"
            id="immediate_product"
            name="ImmediateProduct"
            value={Enquiry.ImmediateProduct}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          ></textarea>
        </div>
        <div className="col-md-6 my-lg-5 my-md-5 d-flex">
          <p
            className="mb-2 me-2 captchvaluesize inputborderradius d-flex captcha-copy-disable justify-content-between"
            style={{
              color: "#495057",
              border: "2px dotted #ced4da",
              padding: '11px'
            }}
          >
            {captcha_number}
            <span
              onClick={() => {
                setRotatecaptcha("iorotatevig");
                Genratecaptcha();
              }}
              className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
            >
              <IoSync className="aButtonvig rotate" />
            </span>
          </p>
          <input
            type="captcha"
            className="form-control emciborderinput"
            placeholder="Enter Captcha"
            name="captcha"
            value={Enquiry.captcha}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
            style={{padding: '22px'}}
          />
        </div>
        <div className="col-md-6 my-lg-5 my-md-5">
          <button
            className="btn w-100"
            style={{ background: "#0b813b", color: "white", fontSize: "18px" }}
            type="button"
            onClick={() => {
              if (Enquiry.mobile != "") {
                if (Enquiry.mobile.length == 10) {
                  if (
                    Enquiry.email == "" ||
                    Enquiry.EmailRegex.test(Enquiry.email)
                  ) {
                    if (
                      Enquiry.captcha.toString() == captcha_number.toString()
                    ) {
                      const htmlMail = EmciEnquiry_formate();
                      Notiflix.Loading.Arrows("Please wait...");
                      PostApiCall.postRequest(
                        {
                          mailtype: mailtype,
                          mail: htmlMail,
                          clientid: clientid,
                          companyname: Enquiry.companyname,
                          name: Enquiry.name,
                          mobile: Enquiry.mobile,
                          email: Enquiry.email,
                          city: Enquiry.city,
                          country: Enquiry.country,
                        },
                        "NewEnquiryMailerApi"
                      ).then((results2) =>
                        results2.json().then((obj2) => {
                          if (
                            results2.status == 200 ||
                            results2.status == 201
                          ) {
                            Notiflix.Notify.Success("Thank you, our team will contact you shortly!");
                            setTimeout(() => {
                              window.location.reload();
                            }, 3000);
                          } else Notiflix.Loading.Remove();
                        })
                      );
                    } else {
                      Notiflix.Notify.Failure("Please enter valid captcha");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter valid email");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter valid mobile number");
                }
              } else {
                Notiflix.Notify.Failure("Please enter mobile number");
              }
            }}
          >
            SEND ENQUIRY
          </button>
        </div>
      </form>
    </div>
  );
};
export default EmciEnquiry;
