import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import PostApiCall from "../../Api";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import "./olympic.css";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "./TummyTemptation.css"
const TummyTemptation = () => {
	const [mailtype, setType] = useState("");
	const [clientid, setClientId] = useState("");
	const mailtypefetchurl = useParams();
	const [Rotatecaptcha, setRotatecaptcha] = useState(false);
	// state for loader
	const [Loader, setLoader] = useState(false);
	const [Enquiry, setEnquiry] = useState({
		name: "",
		email: "",
		mobile: "",
		value: "",
		code: "",
		message: "",
		companyname: "",
		city: "",
		country: "",
		captcha: "",
		EmailRegex:
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		NumRegex: /^[0-9]*$/,
	});
	function formateHtml() {
		return (
			'<html><head><meta charset="utf-8"><title>Sresan | Enquiry</title></head>' +
			'<body style="font-family: sans-serif">' +
			'<div style="width: 100%; margin: auto;background-color: #8080800d;">' +
			'<div style="text-align: left; margin-bottom: 10px;padding: 15px 15px 0;">' +
			'<img style="width: 200px" src="https://www.tummytemptation.com/images/main-logo.jpeg"/>' +
			"</div>" +
			'<div style="padding:1px 20px 0">' +
			'<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Mam, </h2>' +
			"<p style='font-size:14px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.name +
			"</div>" +
			"</div>" +
			"<br>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.mobile +
			"</div>" +
			"</div>" +
			"<br>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.email +
			"</div>" +
			"</div>" +
			"<br>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.message +
			"</div>" +
			"</div>" +
			"<p style='color:black;padding-bottom: 20px;'>Thank You</p>" +
			"</div></div>" +
			"</body>" +
			"</html>"
		);
	}
	useEffect(() => {
		if (Rotatecaptcha == "") {
			setRotatecaptcha("iorotatevig");
		}
	}, [Rotatecaptcha]);
	const [captcha_number, setcaptcha_number] = useState("");
	useEffect(() => {
		setcaptcha_number(
			Math.floor(100000 + Math.random() * 900000)
				.toString(36)
			[Math.random() < 0.5 ? "toString" : "toUpperCase"]()
		);
	}, []);
	function Genratecaptcha() {
		var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
			.toString(36)
		[Math.random() < 0.5 ? "toString" : "toUpperCase"]();
		setcaptcha_number(reloadcapcha);
		setRotatecaptcha(!Rotatecaptcha);
	}
	// notiflix message position
	useEffect(() => {
		Notiflix.Notify.Init({
			width: "280px",
			position: "center-bottom",
			distance: "10px",
			opacity: 1,
			clickToClose: true,
			showOnlyTheLastOne: true,
			//   useFontAwesome: true,
			fontSize: "15px",
			timeout: 4000,
		});
	}, []);
	useEffect(() => {
		setType(mailtypefetchurl.mailtype);
		setClientId(mailtypefetchurl.clientid);
	}, []);
	const submitEnquiry = () => {
		if (Enquiry.name != "") {
			if (
				Enquiry.email != ""
			) if (Enquiry.EmailRegex.test(Enquiry.email)) {
				{
					if (Enquiry.value != "") {
						if (Enquiry.value !== Enquiry.code) {
							if (
								Enquiry.captcha.toString() == captcha_number.toString()
							) {
								setLoader(true);
								const htmlMail = formateHtml();
								PostApiCall.postRequest(
									{
										mailtype: mailtype,
										mail: htmlMail,
										clientid: clientid,
										name: Enquiry.name,
										mobile: Enquiry.mobile,
										email: Enquiry.email,
										message: Enquiry.message,
									},
									"NewEnquiryMailerApi"
								).then((results2) =>
									results2.json().then((obj2) => {
										if (
											results2.status == 200 ||
											results2.status == 201
										) {
											setLoader(false);
											Notiflix.Notify.Success(
												"Thank you, our team will contact you shortly!"
											);
											setTimeout(() => {
												window.location.reload();
											}, 3000);
										}
									})
								);
							} else {
								Notiflix.Notify.Failure("Please enter valid captcha");
							}
						} else {
							Notiflix.Notify.Failure("Please enter mobile number");
						}

					} else {
						Notiflix.Notify.Failure("Please enter mobile number");
					}
				}
			} else {
					Notiflix.Notify.Failure("Please enter valid email");
				} else {
				Notiflix.Notify.Failure("Please enter email");
			}
		} else {
			Notiflix.Notify.Failure("Please enter name");
		}
	}

	const handleNumber = (value, country) => {
		setEnquiry(prevState => ({
			...prevState,
			mobile: "+" + value,
			value: value,
			code: country.dialCode,
		}));
	};
	return (
		<div
			className="container-fluid form-border tummy-form-box"
			style={{ background: "#F8F9FA", height: "100vh" }}
		>
			<form className="row">
				<div className="col-12 col-md-6 mb-3 ">
					{/* <input
						type="text"
						placeholder="Name *"
						className="form-control manzil-input"
						id="Name"
						name="name"
						value={Enquiry.name}
						onChange={(e) =>
							setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
						}
					/> */}
					<FloatingLabel controlId="name" label="Name *">
						<Form.Control className="form-control manzil-input" onChange={(e) =>
							setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
						} type="text"
							placeholder="Name *" id="Name"
							name="name"
							value={Enquiry.name} />
					</FloatingLabel>
				</div>

				{/* <div className="col-12 col-md-6 mb-3 ">
					<input
						type="company"
						placeholder="Company Name"
						className="form-control manzil-input"
						id="company"
						name="companyname"
						value={Enquiry.companyname}
						onChange={(e) =>
							setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
						}
					/>
				</div> */}

				<div className="col-12 col-md-6 mb-3 ">
					<FloatingLabel controlId="email" label="Email *">
						<Form.Control type="email"
							placeholder="Email *"
							className="form-control manzil-input"
							id="email_id"
							name="email"
							value={Enquiry.email}
							onChange={(e) =>
								setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
							} />
					</FloatingLabel>
				</div>
				<div className="col-12 col-md-6 mb-3 ">
					{/* <input
						type="text"
						placeholder="Mobile Number *"
						className="form-control manzil-input"
						id="MobileNumber"
						name="mobile"
						value={Enquiry.mobile}
						onChange={(e) => {
							if (e.target.value.length <= 10)
								setEnquiry({
									...Enquiry,
									[e.target.name]: e.target.value.replace(/\D/g, ""),
								});
						}}
					/> */}
					<PhoneInput
						country={'in'}
						className="form-control tummy-phone-box"
						countryCodeEditable={false}
						// value={Enquiry.mobile}
						onChange={handleNumber}
						enableSearch={true}
					/>
				</div>
				<div className="col-12 col-md-6 mb-3 ">
					{/* <textarea
						className="form-control manzil-textarea"
						placeholder="Message"
						rows="4"
						id="Message"
						name="message"
						value={Enquiry.message}
						onChange={(e) =>
							setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
						}
					></textarea> */}
					<FloatingLabel controlId="message" label="Message">
						<Form.Control
							as="textarea"
							className="form-control w-100"
							placeholder="Message"
							id="Message"
							name="message"
							value={Enquiry.message}
							style={{ height: '100px' }}
							onChange={(e) =>
								setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
							}
						/>
					</FloatingLabel>
				</div>
				<div className="col-12 mb-3 captchdisplay">
					<p
						className="px-2 py-2 mb-2 me-3 border-dotted captcha-copy-disable manzil-captcha captchvaluesize d-flex justify-content-between align-items-center"
						style={{
							background: "#F8F9FA",
						}}
					>
						{captcha_number}
						<span
							onClick={() => {
								setRotatecaptcha("iorotatevig");
								Genratecaptcha();
							}}
							className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
						>
							<IoSync className="aButtonmanzil rotate" />
						</span>
					</p>
					<input
						type="captcha"
						className="form-control captchvaluesize manzil-captcha"
						placeholder="Enter Captcha *"
						name="captcha"
						value={Enquiry.captcha}
						onChange={(e) =>
							setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
						}
					/>
				</div>
				<div className="d-lg-grid justify-content-end align-items-center col">
					<button
						className="btn text-white tummy-btn fs-6"
						type="button"
						onClick={
							// console.log(clientid);
							// console.log(mailtype);
							submitEnquiry
						}
					>
						{Loader == true ? (
							<div className="d-flex justify-content-center">
								<div
									className="spinner-border"
									role="status"
									style={{ width: "1.4rem", height: "1.4rem" }}
								>
									<span className="visually-hidden">Loading...</span>
								</div>
							</div>
						) : (
							"Submit"
						)}
					</button>
				</div>
			</form>
		</div>
	);
};
export default TummyTemptation;
