import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import "./blessing.css";
import { IoSync } from "react-icons/io5";
import { Select, Space } from "antd";
// import { useHistory } from 'react-router-dom';

const BlessingsEnquiry = () => {
  // const history = useHistory();
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const mailtypefetchurl = useParams();
  const [Enquiry, setEnquiry] = useState({
    name: "",
    Gender: "",
    MaritalStatus: "",
    BirthTime: "",
    DateofBirth: "",
    PlaceOfBirth: "",
    mobile: "",
    email: "",
    Qution: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  const [Service, setService] = useState([]);
  const [ServiceMenu, setServiceMenu] = useState([
    { Key: 1, Value: "Astrology" },
    { Key: 2, Value: "Vastu Shastra" },
    { Key: 3, Value: "Handwriting & Signature in Analysis" },
    { Key: 4, Value: "Tarot" },
    { Key: 5, Value: "Palmistry" },
    { Key: 6, Value: "Numerology" },
    { Key: 7, Value: "Reiki Healing" },
    { Key: 8, Value: "Match Making" },
  ]);
  function BlessingsEnquiry_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>Blessings | ENQUIRY</title></head>' +
      '<body style="font-family: sans-serif">' +
      '<div style="width: 100%; margin: auto">' +
      '<div style="background-color: #8080800d; padding: 20px">' +
      '<h3 style="text-align: left;font-size: 15px;color:#000">Dear Sir/Mam,</h3>' +
      "<p style='font-size: 15px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%;display:flex;font-size: 15px;color: black"><b style="color: black">Name</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black;">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%;display:flex;font-size: 15px;color: black"><b style="color: black">Gender</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Enquiry.Gender +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%; display: flex;font-size: 15px;color: black"><b style="color: black">Marital Status</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Enquiry.MaritalStatus +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%; display: flex;font-size: 15px;color: black"><b style="color: black">Date of Birth</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Enquiry.DateofBirth +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%; display: flex;font-size: 15px;color: black"><b style="color: black">Birth Time</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Enquiry.BirthTime +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%; display: flex;font-size: 15px;color: black"><b style="color: black">Place Of Birth</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Enquiry.PlaceOfBirth +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%; display: flex;font-size: 15px;color: black"><b style="color: black">Phone No.</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%; display: flex;font-size: 15px;color: black"><b style="color: black">Email</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 20%; display: flex;font-size: 15px;color: black"><b style="color:black">Service</b></div><div style="text-align:left;width: 10%;">-</div>' +
      '<div class="col1" style="width: 50%;font-size: 15px;color: black">' +
      Service +
      "</div>" +
      "</div>" +
      "<br>" +
      "<p style='font-size: 15px;color: black'><strong>Thank You</strong></p>" +
      "</div></div>" +
      "</body>" +
      "</html>"
    );
  }

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-center",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  function handleChangeService(value) {
    setService(value);
    // console.log(Service);
  }
  return (
    <div className="container form-border my-3">
      <form className="row py-3 needs-validation">
        <div className="col-12 col-md-6 mb-3">
          <label for="Name" className="form-label blessing-form-label">
            Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control comon-input-height"
            id="name"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label for="gender" className="form-label blessing-form-label">
            Gender <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control comon-input-height"
            id="gender"
            name="Gender"
            value={Enquiry.Gender}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <div className="mb-3">
            <label
              for="materialstatus"
              className="form-label blessing-form-label"
            >
              Marital Status
            </label>
            <input
              type="text"
              className="form-control comon-input-height"
              id="MaritalStatus"
              name="MaritalStatus"
              value={Enquiry.MaritalStatus}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <div className="dateemci">
            <label for="birthdate" className="form-label blessing-form-label">
              Date of Birth <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control comon-input-height date-of-birth-blessing"
              id="DateofBirth"
              name="DateofBirth"
              value={Enquiry.DateofBirth}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
              style={{ border: "1px solid #ced4da" }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label for="Birthtime" className="form-label blessing-form-label">
            Birth Time <span className="text-danger">*</span>
          </label>
          <input
            type="time"
            className="form-control comon-input-height"
            id="Birthtime"
            name="BirthTime"
            value={Enquiry.BirthTime}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label for="birthplace" className="form-label blessing-form-label">
            Place Of Birth <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control comon-input-height"
            id="PlaceOfBirth"
            name="PlaceOfBirth"
            value={Enquiry.PlaceOfBirth}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label for="mobile_number" className="form-label blessing-form-label">
            Mobile Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control comon-input-height"
            id="mobile_number"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label for="email_id" className="form-label blessing-form-label">
            Email Id
          </label>
          <input
            type="email"
            className="form-control comon-input-height"
            id="email_id"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>

        {/* start */}
        <div className="col-12 multiselect-service-option mb-3">
          <label className="blessing-form-label" for="message">
            Service<span className="text-danger classmandatory">*</span>
          </label>

          <Select
            name="consulting"
            className="select reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
            style={{
              borderRadius: "0px !important",
            }}
            value={Service}
            onChange={handleChangeService}
            mode="multiple"
          >
            <option key="" value="">
              ---- Select Service ----
            </option>
            {ServiceMenu.map((data, index) => {
              return (
                <option key={data.Key} value={data.Value}>
                  {data.Value}
                </option>
              );
            })}
          </Select>
        </div>

        {/* End */}

        <div className="col-xl-12 col-md-12 mb-3">
          <label for="web_address" className="form-label blessing-form-label">
            Ask Your Question
          </label>
          <textarea
            type="Website_address"
            className="form-control"
            id="Qution"
            name="Qution"
            rows="3"
            value={Enquiry.Qution}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <label for="email_id" className="form-label blessing-form-label">
            Enter Captcha Code <span className="text-danger">*</span>
          </label>
          <div className="row">
            <div className="col-6">
              <p className="d-flex fs-6 blessing-captcha-border captcha-copy-disable justify-content-between comon-input-height align-items-center">
                {captcha_number}
                <span
                  onClick={() => {
                    setRotatecaptcha("iorotatevig");
                    Genratecaptcha();
                  }}
                  className={
                    Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""
                  }
                >
                  <IoSync className="aButtonblessing rotate" />
                </span>
              </p>
            </div>
            <div className="col-6">
              <input
                type="captcha"
                className="form-control emciborderinput comon-input-height fs-6"
                placeholder="Enter Captcha"
                name="captcha"
                value={Enquiry.captcha}
                onChange={(e) =>
                  setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-center pe-0 pe-lg-0">
          <div className="col-lg-4 col-12 pe-0 pe-lg-0">
            <div className="mb-3">
              <button
                className="btn fs-6 btn-warning btn-block py-2 text-light mx-auto blessing-appoinment"
                type="button"
                onClick={() => {
                  if (Enquiry.name != "") {
                    if (
                      Enquiry.email == "" ||
                      Enquiry.EmailRegex.test(Enquiry.email)
                    ) {
                      if (Enquiry.Gender != "") {
                        if (Enquiry.DateofBirth != "") {
                          if (Enquiry.BirthTime != "") {
                            if (Enquiry.PlaceOfBirth != "") {
                              if (Enquiry.mobile != "") {
                                if (Enquiry.mobile.length == 10) {
                                  if (Service != "") {
                                    if (
                                      Enquiry.captcha.toString() ==
                                      captcha_number.toString()
                                    ) {
                                      const htmlMail =
                                        BlessingsEnquiry_formate();
                                      Notiflix.Loading.Arrows("Please wait...");
                                      PostApiCall.postRequest(
                                        {
                                          mailtype: "from Blessings Astrology",
                                          mail: htmlMail,
                                          clientid: clientid,
                                          name: Enquiry.name,
                                          mobile: Enquiry.mobile,
                                          email: Enquiry.email,
                                        },
                                        "NewEnquiryMailerApi"
                                      ).then((results2) =>
                                        results2.json().then((obj2) => {
                                          if (
                                            results2.status == 200 ||
                                            results2.status == 201
                                          ) {
                                            Notiflix.Loading.Remove();
                                            Notiflix.Notify.Success(
                                              "Thank you, our team will contact you shortly!"
                                            );
                                            setTimeout(() => {
                                              window.location.reload();
                                            }, 3000);

                                            // window.location.href = "https://blessingsastrology.com/thankyou";
                                          }
                                        })
                                      );
                                    } else {
                                      Notiflix.Notify.Failure(
                                        "Please enter valid captcha"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.Failure(
                                      "Please choose services"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.Failure(
                                    "Please enter valid mobile number"
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter mobile number"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure(
                                "Please enter place of birth"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure("Please enter birth time");
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter date of birth");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter gender");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter valid email");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter name");
                  }
                }}
              >
                Request Appointment
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default BlessingsEnquiry;
