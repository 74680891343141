import React, { Component } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { loginUser, apiError } from "../../store/actions";
import profile from "../../assets/images/profile-img.png";
import logox from "../../assets/images/logo.png";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordVisible: false,
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            NumRegex: /^[0-9]*$/,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            UrlRegex:
                /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            Email: "",
            Password: "",
            history: useHistory,
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
    }
    onClickLogin() {
        if (this.state.Email != "") {
            if (this.state.EmailRegex.test(this.state.Email)) {
                if (this.state.Password != "") {
                    Notiflix.Loading.Dots("");
                    PostApiCall.postRequest(
                        {
                            email: this.state.Email,
                            password: this.state.Password,
                            action: "Login",
                            actiondate: moment().format("lll"),
                        },
                        "AuthenticateUser"
                    ).then((results) =>
                        results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                localStorage.setItem(
                                    "OldPassword",
                                    JSON.stringify(this.state.Password)
                                );
                                localStorage.setItem(
                                    "lastlogin",
                                    JSON.stringify(moment().format("lll"))
                                );
                                localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
                                Notiflix.Loading.Remove();
                                window.location.href = "/dashboard";
                            } else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(obj.data);
                            }
                        })
                    );
                } else {
                    Notiflix.Notify.failure("Please enter password.");
                }
            } else {
                Notiflix.Notify.Failure("Please enter valid email address.");
            }
        } else {
            Notiflix.Notify.Failure("Please enter email address.");
        }
    }
    OnChangepwd(password) {
        this.setState({
            Password: password.target.value,
        });
    }
    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="gtrendz-bg-soft-pink">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="gtrendz-muted-text p-4"
                                                    style={{ color: '#fff' }}>
                                                    <h5 className="gtrendz-muted-text" style={{ color: '#fff' }}>
                                                        Welcome Back !
                                                    </h5>
                                                    <p>Sign in to continue to Global Trendz</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Link to="/">
                                                    <div className="avatar-md profile-user-wid mb-4">
                                                        <span className="avatar-title rounded-circle bg-light">
                                                            <img
                                                                src={logox}
                                                                alt=""
                                                                className="rounded-circle"
                                                                height="34"
                                                            />
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <label for="email">Email</label>
                                                        <input
                                                            value={this.state.Email}
                                                            onChange={(text) => {
                                                                this.setState({
                                                                    Email: text.target.value,
                                                                });
                                                            }}
                                                            type="email"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="pwd">Password:</label>
                                                        <input
                                                            value={this.state.Password}
                                                            type={
                                                                this.state.isPasswordVisible
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            onChange={this.OnChangepwd.bind(this)}
                                                            className="form-control"
                                                        />
                                                        <span class="login-icon-change-pass">
                                                            <i
                                                                style={{
                                                                    color: this.state.isPasswordVisible
                                                                        ? "#777f80"
                                                                        : "",
                                                                }}
                                                                className="fa fa-eye"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isPasswordVisible:
                                                                            !this.state.isPasswordVisible,
                                                                    });
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <button
                                                        className="btn gtrendz-btn  btn-block waves-effect waves-light"
                                                        type="submit"
                                                        onClick={this.onClickLogin.bind(this)}
                                                    >
                                                        Log In
                                                    </button>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        © {new Date().getFullYear()}  Global
                                        Trendz.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = (state) => {
    const { error } = state.Login;
    return { error };
};
export default withRouter(
    connect(mapStatetoProps, { loginUser, apiError })(Login)
);
