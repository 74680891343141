import classname from "classnames";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";
import PostApiCall from "../../Api";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReport: false,
      Menu: [],
      SubMenu: {
        Dashboard: [],
        Managements: [
          {
            fld_menuname: "Enquiries",
            fld_pagename: "/mailer/1",
            fld_show: "1",
            fld_parentid: 4,
          },
          {
            fld_menuname: "Job Applications test",
            fld_pagename: "/Jobapplication",
            fld_show: "1",
            fld_parentid: 4,
          },
          {
            fld_menuname: "Blogs",
            fld_pagename: "/blog-list",
            fld_show: "1",
            fld_parentid: 4,
          },
        ],
      },
    };
  }
  componentDidMount() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));

    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    // ------------------- for dynamic menu -------------------------------------
    PostApiCall.postRequest(
      {
        userid: loginDetails[0].fld_userid
      },
      "getusermenu"
    ).then((results) =>
      results.json().then((obj) => {
        // console.log(results)
        if (results.status == 200 || results.status == 201) {
          this.setState({
            Menu: obj.data,
          });
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };
  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav ">
                  {this.state.Menu &&
                    this.state.Menu.map((data, index) => {
                      return (
                        <li key={index} className="nav-item dropdown">
                          {data.fld_pagename != "/#" ? (
                            <Link
                              to={`${data.fld_pagename}`}
                              className="nav-link  arrow-none"
                            >
                              {this.props.t(`${data.fld_menuname}`)}
                            </Link>
                          )
                            : (
                              <li className="nav-item dropdown">
                                <a className="nav-link  arrow-none">
                                  {this.props.t(`${data.fld_menuname}`)}{" "}
                                  <div className="arrow-down"></div>
                                </a>
                                <div
                                  className={classname("dropdown-menu", {
                                    show: this.state.uiState,
                                  })}
                                >
                                  {this.state.SubMenu[data.fld_menuname] ==
                                    undefined ? (
                                    <span></span>
                                  ) : this.state.SubMenu[data.fld_menuname]
                                    .length > 0 ? (
                                    <span>
                                      {this.state.SubMenu[data.fld_menuname] ==
                                        undefined ? (
                                        <span></span>
                                      ) : (
                                        this.state.SubMenu[data.fld_menuname].map(
                                          (submenu, i) => (
                                            <Link
                                              to={`${submenu.fld_pagename}`}
                                              className="dropdown-item"
                                            >
                                              {this.props.t(
                                                `${submenu.fld_menuname}`
                                              )}
                                            </Link>
                                          )
                                        )
                                      )}
                                    </span>
                                  ) : (
                                    <p></p>
                                  )}
                                </div>
                              </li>
                            )
                          }
                        </li>
                      );
                    })}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withNamespaces()(Navbar));
