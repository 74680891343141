import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { IoSync } from "react-icons/io5";
import { useParams } from "react-router-dom";
import "./microlab.css";
const MicroLabCareer = () => {
    const [mailType, setType] = useState("");
    const [clientid, setClientid] = useState("");
    const mailtype = useParams();
    const [Rotatecaptcha, setRotatecaptcha] = useState(false);
    const [Loader, setLoader] = useState(false);
    // fileupload
    const [selectedFile, setSelectedFile] = useState([]);
    const [fileBase64String, setfileBase64String] = useState("");
    const onFileChange = (e) => {
        if (e.target.files[0].type == "application/pdf") {
            setSelectedFile(e.target.files);
        } else {
            Notiflix.Notify.Failure("file should be in pdf format");
        }
    };

    const encodeFileBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var attachment = reader.result;
                setfileBase64String(attachment);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };
    encodeFileBase64(selectedFile[0]);
    const [Enquiry, setEnquiry] = useState({
        name: "",
        mobile: "",
        email: "",
        age: null,
        location: "",
        attachment: "",
        country: "",
        postapplied: "",
        qualification: "",
        careernutshell: "",
        strongsuits: "",
        profachievements: "",
        jobleavingreason: "",
        otherinfo: "",
        File: "",
        captcha: "",
        EmailRegex:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        NumRegex: /^[0-9]*$/,
    });

    function MicroLabCareer_MailFormate() {
        return (
            '<html><head><meta charset="utf-8"><title>Microlab Animal Health Care | ENQUIRY FORM</title></head>' +
            '<body style="font-family: sans-serif;border: 1px solid #0000ff3b;border-radius: 5px;background-color: #8080800d">' +
            '<div style="width: 100%; margin: auto">' +
            '<div style="text-align: left; margin-bottom: 0px; padding: 10px 0px 0px 20px;">' +
            '<img style="width: 210px;padding-top:20px" src="https://globaltrendz.online/microlabs/images/logo.png"/>' +
            "</div>" +
            '<div style="padding: 20px">' +
            '<h2 style="text-align: left;font-size:14px;color: #000;">Dear Sir/Ma’am, </h2>' +
            "<p style='font-size:14px;color: #000;'>I hope this email finds you well. This email is to inform you that a candidate has just filled out a career form on your website.</p>" +
            "<p style='font-size:14px;color: #000;'>Here are the details of the candidate:</p>" +
            '<div class="row" style="width: 100%; display: flex;color: #000;">' +
            '<div class="col1" style="width: 30%;display:flex;color: #000;"><b>Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%;color: #000;">' +
            Enquiry.name +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex;color: #000;">' +
            '<div class="col1" style="width: 30%;display:flex;color: #000;"><b>Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%;color: #000;">' +
            Enquiry.email +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex;color: #000;">' +
            '<div class="col1" style="width: 30%;display:flex;color: #000;"><b>Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%;color: #000;">' +
            Enquiry.mobile +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex;color: #000;">' +
            '<div class="col1" style="width: 30%; display: flex;color: #000;"><b>Post Applied</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%;color: #000;">' +
            Enquiry.postapplied +
            "</div>" +
            "</div>" +
            "<br>" +
            '<div class="row" style="width: 100%; display: flex;color: #000;">' +
            '<div class="col1" style="width: 30%; display: flex;color: #000;"><b>Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
            '<div class="col1" style="width: 50%;color: #000;">' +
            Enquiry.otherinfo +
            "</div>" +
            "</div>" +
            '<div class="row" style="width: 100%; display: flex;color: #000;">' +
            '<div class="col1" style="width: 100%; display: flex;color: #000;">' +
            Enquiry.attachment +
            "</div>" +
            "</div>" +
            "<h3 style='text-align: left;font-size:15px;color: #000;margin-top:'0px'>Thank you</h3>" +
            "</div></div>" +
            "</body>" +
            "</html>"
        );
    }
    useEffect(() => {
        if (Rotatecaptcha == "") {
            setRotatecaptcha("iorotatevig");
        }
    }, [Rotatecaptcha]);
    const [captcha_number, setcaptcha_number] = useState("");
    useEffect(() => {
        setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
    }, []);
    function Genratecaptcha() {
        var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
        setcaptcha_number(reloadcapcha);
        setRotatecaptcha(!Rotatecaptcha);
    }

    // notiflix message position
    useEffect(() => {
        Notiflix.Notify.Init({
            width: "280px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
            clickToClose: true,
            showOnlyTheLastOne: true,
            useFontAwesome: true,
            fontSize: "15px",
            timeout: 4000
        });
    }, []);
    useEffect(() => {
        setType(mailtype.mailtype);
        setClientid(mailtype.clientid);
    }, []);

    return (
        <div className="container-fluid p-lg-5 p-3">
            <form className="row py-2">
                <div className="col-md-12 col-12 col-lg-6 mb-4">
                    <input
                        type="text"
                        className="form-control rounded-5 microlab-career-input"
                        placeholder="Name *"
                        aria-label="Name"
                        id="name"
                        name="name"
                        value={Enquiry.name}
                        onChange={(e) =>
                            setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                        }
                    />
                </div>
                <div className="col-md-12 col-12 col-lg-6 mb-4">
                    <input
                        type="text"
                        className="form-control rounded-5 microlab-career-input"
                        placeholder="Mobile *"
                        aria-label="Mobile"
                        id="mobile"
                        name="mobile"
                        value={Enquiry.mobile}
                        onChange={(e) => {
                            if (e.target.value.length <= 10)
                                setEnquiry({
                                    ...Enquiry,
                                    [e.target.name]: e.target.value.replace(/\D/g, ""),
                                });
                        }}
                    />
                </div>
                <div className="col-md-12 col-12 col-lg-6 mb-4">
                    <input
                        type="email"
                        className="form-control rounded-5 microlab-career-input"
                        placeholder="Email *"
                        aria-label="Email"
                        id="email"
                        name="email"
                        value={Enquiry.email}
                        onChange={(e) =>
                            setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                        }
                    />
                </div>
                <div className="col-md-12 col-12 col-lg-6 mb-4">
                    <input
                        type="text"
                        className="form-control rounded-5 microlab-career-input"
                        id="post_applied"
                        name="postapplied"
                        placeholder="Post Applied *"
                        aria-label="Post Applied"
                        value={Enquiry.postapplied}
                        onChange={(e) =>
                            setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                        }
                    />
                </div>
                <div className="col-md-12 col-12 col-lg-6 mb-4">
                    <input
                        type="text"
                        className="form-control rounded-5 microlab-career-input"
                        placeholder="Any Other Information"
                        aria-label="Any Other Information"
                        id="information"
                        name="otherinfo"
                        value={Enquiry.otherinfo}
                        onChange={(e) =>
                            setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                        }
                    />
                </div>
                <div className="col-md-12 col-12 col-lg-6 mb-4">
                    <input
                        type="file"
                        className="form-control rounded-5 microlab-career-input microlab-file-pdf d-block py-1"
                        placeholder="Choose File*"
                        aria-label="File"
                        id="file"
                        name="File"
                        onChange={onFileChange}
                    />
                </div>
                <div className="col-md-12 col-12 col-lg-6 mb-4">
                    <div className="d-flex">
                        <p
                            className="me-2 cngcaptchsize microlab-career-input bg-white d-flex justify-content-between captcha-copy-disable"
                            style={{
                                color: "#000000",
                                borderRadius: "0.25rem",
                                userSelect: "none",
                                border: '1px solid #ced4da',
                                padding: '10px'
                            }}
                        >
                            {captcha_number}
                            <span
                                onClick={() => {
                                    setRotatecaptcha("iorotatevig");
                                    Genratecaptcha();
                                }}
                                className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
                            >
                                <IoSync className="aButtonvig rotate" />
                            </span>
                        </p>
                        <input type="captcha" className="form-control stoneinput microlab-career-input" placeholder="Captcha Code *" name="captcha" value={Enquiry.captcha} onChange={(e) => setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })} />
                    </div>
                </div>
                <div className="col-md-4 col-4 col-lg-4 mb-4" style={{ marginTop: '12px' }}>
                    <button
                        className="micro-lab-career-btn btn-hover-fill"
                        type="button"
                        onClick={() => {
                            if (Enquiry.name != "") {
                                if (Enquiry.mobile != "") {
                                    if (Enquiry.mobile.length == 10) {
                                        if (Enquiry.email != "") {
                                            if (Enquiry.EmailRegex.test(Enquiry.email)) {
                                                if (Enquiry.postapplied != "") {
                                                    if (selectedFile.length != 0) {
                                                        if (
                                                            Enquiry.captcha.toString() ==
                                                            captcha_number.toString()
                                                        ) {
                                                            setLoader(true);
                                                            const htmlMail =
                                                                MicroLabCareer_MailFormate();
                                                            Notiflix.Loading.Arrows("Please wait...");
                                                            PostApiCall.postRequest(
                                                                {
                                                                    mailtype: mailType,
                                                                    mail: htmlMail,
                                                                    clientid: clientid,
                                                                    name: Enquiry.name,
                                                                    mobile: Enquiry.mobile,
                                                                    email: Enquiry.email,
                                                                    age: Enquiry.age,
                                                                    location: Enquiry.location,
                                                                    country: Enquiry.country,
                                                                    postapplied:
                                                                        Enquiry.postapplied,
                                                                    qualification:
                                                                        Enquiry.qualification,
                                                                    careernutshell:
                                                                        Enquiry.careernutshell,
                                                                    strongsuits:
                                                                        Enquiry.strongsuits,
                                                                    profachievements:
                                                                        Enquiry.profachievements,
                                                                    jobleavingreason:
                                                                        Enquiry.jobleavingreason,
                                                                    otherinfo: Enquiry.otherinfo,
                                                                    attachment: fileBase64String,
                                                                },
                                                                "jobapplications"
                                                            ).then((results2) =>
                                                                results2.json().then((obj2) => {
                                                                    if (
                                                                        results2.status == 200 ||
                                                                        results2.status == 201
                                                                    ) {
                                                                        setLoader(false);
                                                                        Notiflix.Notify.Success(
                                                                            "Thank you, our team will contact you shortly!"
                                                                        );
                                                                        setTimeout(() => {
                                                                            window.location.reload();
                                                                        }, 3000);
                                                                    }
                                                                    else (
                                                                        Notiflix.Loading.Remove()
                                                                    )
                                                                })
                                                            );
                                                        } else {
                                                            Notiflix.Notify.Failure(
                                                                "Please enter valid captcha"
                                                            );
                                                        }
                                                    } else {
                                                        Notiflix.Notify.Failure(
                                                            "Please upload resume in pdf format"
                                                        );
                                                    }
                                                } else {
                                                    Notiflix.Notify.Failure(
                                                        "Please enter post applied"
                                                    );
                                                }
                                            } else {
                                                Notiflix.Notify.Failure("Please enter valid email");
                                            }
                                        } else {
                                            Notiflix.Notify.Failure("Please enter email");
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("Please enter valid mobile number");
                                    }
                                } else {
                                    Notiflix.Notify.Failure("Please enter mobile number");
                                }
                            }
                            else {
                                Notiflix.Notify.Failure("Please enter name");
                            }
                        }}
                    >
                        Apply Now
                    </button>
                </div>
            </form >
        </div >
    );
};
export default MicroLabCareer;
