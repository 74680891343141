import React, { useEffect, useState } from "react";
import PostApiCall from "../../../Api";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import "./RingJoint.css";
const KammprofileGasket = () => {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    companyname: "",
    email: "",
    mobile: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  function KammprofileGasket_formate() {
    return (
      '<html><head><meta charset="utf-8"><title>KAMMPROFILE GASKETS | ENQUIRY</title></head>' +
      ' <body style="font-family: sans-serif">' +
      ' <div style="background-color: #8080800d; padding: 25px 20px 20px;">' +
      '<div style="text-align: left; margin-bottom: 20px;">' +
      '<img style="width: 20%" src="https://kammprofilegasket.com/assets/images/goodrich-gasket-logo.png"/>' +
      "</div>" +
      '<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Mam, </h2>' +
      "<p style='font-size:14px;color: black;'>You have received an enquiry from the <b>Kammprofile Gasket</b> website. The details are as follows:</p>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.name +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Company Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.companyname +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.mobile +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.email +
      "</div>" +
      "</div>" +
      "<br>" +
      '<div class="row" style="width: 100%; display: flex">' +
      '<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
      '<div class="col1" style="width: 50%">' +
      Enquiry.message +
      "</div>" +
      "</div>" +
      "<br>" +
      "<p><b>Thank You</b></p>"+
      "</div></div></div>" +
      "</body>" +
      "</html>"
    );
  }
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);
  return (
    <div className="container-fluid form-border">
      <form className="row">
        <div className="mb-4 col-12 col-md-6">
          <input
            type="text"
            className="form-control ringjoint-input-fields"
            placeholder="Name *"
            id="Name"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="mb-4 col-12 col-md-6">
          <input
            type="text"
            className="form-control ringjoint-input-fields"
            placeholder="Company Name"
            name="companyname"
            value={Enquiry.companyname}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="mb-4 col-12 col-md-6">
          <input
            type="email"
            className="form-control ringjoint-input-fields"
            id="email_id"
            placeholder="Email Id"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>

        <div className="mb-4 col-12 col-md-6">
          <input
            type="text"
            className="form-control ringjoint-input-fields"
            id="MobileNumber"
            placeholder="Mobile Number *"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>

        <div className="col-12 mb-4">
          <textarea
            class="form-control ringjoint-text-area"
            type="text"
            rows="5"
            id="message"
            placeholder="Message"
            name="message"
            value={Enquiry.message}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
            }
          />
        </div>

        <div className="col-12 col-md-6 captchdisplay mb-2">
            <p
              className="me-2 d-flex justify-content-between captchvaluesize ring-joint-captchs-style"
            >
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotate");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
              >
                <IoSync className="aButtonred rotate" />
              </span>
            </p>
            <input
              type="captcha"
              className="form-control ring-joint-captcha"
              placeholder="Enter Captcha Code *"
              name="captcha"
              value={Enquiry.captcha}
              onChange={(e) =>
                setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="col-12 redrockbtn-changes text-start">
          <button
            className="btn py-2 px-4 fs-6 ringjoint-enquiry-btn"
            style={{ fontSize: "15px" }}
            type="button"
            onClick={() => {
              if (Enquiry.name != "") {
                if (
                  Enquiry.email == "" ||
                  Enquiry.EmailRegex.test(Enquiry.email)
                ) {
                  if (Enquiry.mobile != "") {
                    if (Enquiry.mobile.length == 10) {
                      if (
                        Enquiry.captcha.toString() == captcha_number.toString()
                      ) {
                        setLoader(true);
                        const htmlMail = KammprofileGasket_formate();
                        PostApiCall.postRequest(
                          {
                            mailtype: "from Flange Kammprofile Gasket",
                            mail: htmlMail,
                            clientid: clientid, 
                            name: Enquiry.name,
                            companyname:Enquiry.companyname,
                            mobile: Enquiry.mobile,
                            email: Enquiry.email,
                          },
                          "NewEnquiryMailerApi"
                        ).then((results2) =>
                          results2.json().then((obj2) => {
                            if (
                              results2.status == 200 ||
                              results2.status == 201
                            ) {
                              setLoader(false);
                              Notiflix.Notify.Success(
                                "Thank you, our team will contact you shortly!"
                              );
                              setTimeout(() => {
                                window.location.reload();
                              }, 3000);
                              // window.location.reload();
                            }
                          })
                        );
                      } else {
                        Notiflix.Notify.Failure("Please enter valid captcha");
                      }
                    } else {
                      Notiflix.Notify.Failure(
                        "Please enter valid mobile number"
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter mobile number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter valid email");
                }
              } else {
                Notiflix.Notify.Failure("Please enter name");
              }
            }}
          >
            {Loader == true ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
export default KammprofileGasket;
